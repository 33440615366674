import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import StudyModalForm from './StudyModalForm';
import { createStudyRequest } from '../../../actions/studies';
import Modal from '../Modal';
import studyModalValidator from '../validators/studyModalValidator';
import {
  fetchTherapeuticAreas,
  fetchSponsors,
  fetchCros
} from '../../../fetchers/studies';
import { DEFAULT_GROUPS } from '../../../constants';

class CreateStudyModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.state = {
      areas: [],
      sponsors: [],
      cros: []
    };
  }

  componentDidMount() {
    Promise.all([fetchTherapeuticAreas(), fetchSponsors(), fetchCros()]).then(
      results => {
        const [areas, sponsors, cros] = results;
        this.setState({
          areas,
          sponsors: sponsors.sponsors,
          cros: cros.cros
        });
      }
    );
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { areas, sponsors, cros } = this.state;
    const { className, createStudyRequestAction } = this.props;
    const initialValues = {
      studyName: '',
      type: '',
      protocolId: '',
      protocolName: '',
      protocolNameBlinded: '',
      pcn: '',
      therapeuticArea: '',
      indication: '',
      phase: 'N/A',
      status: 'Inactive',
      screenFailureRate: 0,
      dropOutRate: 0,
      customer: '',
      sponsor: '',
      cro: '',
      blinded: false,
      blindingGroups: [],
      newBlindingGroups: '',
      isGroupAssign: true,
      groupAssigns: [...DEFAULT_GROUPS],
      isDefault: false,
      newGroupType: 'SM'
    };

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Create New Study
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={studyModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitValues => {
            createStudyRequestAction(submitValues);
            this.onClose();
          }}
        >
          {({
            submitForm,
            isSubmitting,
            handleChange,
            setFieldValue,
            values
          }) => {
            return (
              <StudyModalForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                onClose={this.onClose}
                areas={areas}
                sponsors={sponsors}
                cros={cros}
                data={null}
                values={values}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

CreateStudyModal.defaultProps = {
  className: ''
};

CreateStudyModal.propTypes = {
  className: PropTypes.string,
  createStudyRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  createStudyRequestAction: payload => dispatch(createStudyRequest(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateStudyModal);
