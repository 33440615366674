import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import StandardTable from './gizmos/StandardTable';
import withCognitoUsers from '../containers/withCognitoUsers';
import CognitoAddUser from './CognitoAddUser';
import bouncerToken from '../fetchers/bouncerToken';

class UserPool extends Component {
  constructor(props) {
    super(props);
    const { bouncerEnabled } = bouncerToken.getBouncerProps();
    this.columnsCognitoUsers = [
      {
        Header: 'Last Name',
        accessor: 'family_name'
      },
      {
        Header: 'First Name',
        accessor: 'given_name'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Status',
        accessor: 'status',
        show: !bouncerEnabled
      },
      {
        Header: 'Email Verified',
        accessor: 'email_verified'
      },
      {
        Header: 'Cognito Groups',
        accessor: 'groups',
        Cell: ({ value }) => value && value.join(', '),
        show: !bouncerEnabled
      },
      {
        Header: 'Username',
        accessor: 'username'
      }
    ];

    this.secondH1Spacing = { paddingTop: '1.5rem' };
  }

  componentDidMount() {
    const { fetchCognitoUsersRequest } = this.props;
    fetchCognitoUsersRequest();
  }

  render() {
    const { cognitoUsers, postCognitoUserRequest } = this.props;
    return (
      <Fragment>
        <h1>Add User</h1>
        <CognitoAddUser postCognitoUserRequest={postCognitoUserRequest} />
        <h1 style={this.secondH1Spacing}>Users</h1>
        <StandardTable columns={this.columnsCognitoUsers} data={cognitoUsers} />
      </Fragment>
    );
  }
}

UserPool.defaultProps = {
  cognitoUsers: []
};

UserPool.propTypes = {
  cognitoUsers: PropTypes.array,
  fetchCognitoUsersRequest: PropTypes.func.isRequired,
  postCognitoUserRequest: PropTypes.func.isRequired
};

export default withCognitoUsers(UserPool);
