import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { createSsuRequest } from '../../../actions/ssus';
import Modal from '../Modal';
import SsuModalForm from './SsuModalForm';
import ssuModalValidator from '../validators/ssuModalValidator';

class CreateSsuModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { className, createSsuRequestAction, sites, studies } = this.props;
    const initialValues = {
      siteId: sites && sites[0] ? sites[0].siteId : '',
      studyId: studies && studies[0] ? studies[0].studyId : '',
      sponsorCtaDate: '',
      awardDate: '',
      siteWorkOrderDate: '',
      siteNumber: '',
      status: 'Startup'
    };
    const clearDateButton = {
      backgroundColor: 'transparent',
      position: 'absolute',
      right: '15px',
      border: 'none',
      height: '34px',
      paddingTop: '4px'
    };

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Create New Ssu
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={ssuModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitValues => {
            createSsuRequestAction(submitValues);
            this.onClose();
          }}
        >
          {({ values, submitForm, isSubmitting, setFieldValue }) => {
            return (
              <SsuModalForm
                values={values}
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                clearDateButton={clearDateButton}
                onClose={this.onClose}
                sites={sites}
                studies={studies}
                isCreate
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

CreateSsuModal.defaultProps = {
  className: ''
};

CreateSsuModal.propTypes = {
  className: PropTypes.string,
  createSsuRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  sites: PropTypes.array.isRequired,
  studies: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    sites: state.sites,
    studies: state.studies
  };
};

const mapDispatchToProps = dispatch => ({
  createSsuRequestAction: payload => dispatch(createSsuRequest(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSsuModal);
