import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import moment from 'moment';
import Card from './gizmos/Card';
import UserInfoForm from './User/UserInfoForm';
import UserRolesTable from './User/UserRolesTable';
import UserRoleForm from './User/UserRoleForm';
import ExpiredRolesTable from './User/ExpiredRolesTable';
import Diagnostics from './User/Diagnostics';
import ModeReport from './User/ModeReport';
import withUser from '../containers/withUser';
import { titleOptions } from '../constants';
import bouncerToken from '../fetchers/bouncerToken';

class User extends Component {
  constructor(props) {
    super(props);

    this.centerStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center'
    };

    this.buttonStyle = {
      margin: '0.5rem'
    };
  }

  componentDidMount() {
    const {
      fetchCognitoUserRequest,
      fetchUserListRequest,
      fetchUserRoleTxnsRequest,
      fetchSsusRequest,
      fetchStudiesRequest,
      fetchSitesRequest,
      fetchUserRoleTxnsRolledUpRequest,
      match
    } = this.props;
    fetchCognitoUserRequest(match.params.uuid);
    fetchUserListRequest();
    fetchUserRoleTxnsRequest(match.params.uuid);
    fetchSsusRequest();
    fetchStudiesRequest();
    fetchSitesRequest();
    fetchUserRoleTxnsRolledUpRequest(match.params.uuid);
  }

  render() {
    const {
      userInfo,
      putUserRequest,
      putUserEmailRequest,
      putUserEmailFromCognitoRequest,
      postExpireRoleRequest,
      postExpireAllRolesRequest,
      postAddRoleRequest,
      userRoles,
      ssus,
      ssuList,
      allStudiesDataSource,
      allSitesDataSource,
      allPcnsDataSource,
      pcnStudyMap,
      siteStudyMap,
      studies,
      sites,
      previousRoles,
      roleTxns,
      roleTxnsRolledUp,
      cognitoUser,
      postResetPasswordRequest,
      postResendInvitationRequest,
      postCognitoUserAddGroupRequest,
      postCognitoUserRemoveGroupRequest,
      match: {
        params: { uuid: userId }
      }
    } = this.props;

    const { bouncerEnabled } = bouncerToken.getBouncerProps();

    let invitationDisabled = false;
    let resetPasswordDisabled = false;

    const allRoles = roleTxnsRolledUp || {};
    const rolesValues = Object.values(allRoles) || [];

    const globalRoles = [];
    const ssuRoles = [];
    rolesValues.forEach(role => {
      if (Object.values(role).every(isObject)) {
        ssuRoles.push(...Object.values(role));
      } else {
        globalRoles.push(role);
      }
    });

    const rolesArray = [...globalRoles, ...ssuRoles];
    const now = moment();
    const activeAndFutureRoles = rolesArray.filter(role => {
      const momentExpireDate = moment(role.expireDate);
      return !momentExpireDate.isValid() || momentExpireDate.isAfter(now);
    });

    if (bouncerEnabled) {
      invitationDisabled =
        (cognitoUser.email_verified === 'true' &&
          cognitoUser.last_password_reset) ||
        (!userInfo.studyMarketplaceRole &&
          isEmpty(userInfo.role) &&
          isEmpty(activeAndFutureRoles));
    } else {
      invitationDisabled =
        cognitoUser.status === 'CONFIRMED' ||
        cognitoUser.status === undefined ||
        cognitoUser.groups === [] ||
        (!userInfo.studyMarketplaceRole && isEmpty(userInfo.role));
    }

    if (bouncerEnabled) {
      // TODO: to clarify logic
      resetPasswordDisabled =
        cognitoUser.email_verified === 'false' ||
        cognitoUser.email_verified === null;
    } else {
      resetPasswordDisabled =
        cognitoUser.status === 'FORCE_CHANGE_PASSWORD' ||
        cognitoUser.status === undefined;
    }

    return (
      <section>
        {userInfo.email}
        <div style={this.centerStyle}>
          <Card titleName="User Info">
            {userInfo.userId && (
              <Fragment>
                <UserInfoForm
                  putUserRequest={putUserRequest}
                  putUserEmailRequest={putUserEmailRequest}
                  identityProviderUser={cognitoUser}
                  {...userInfo}
                />
                <div style={{ marginTop: '1rem', ...this.centerStyle }}>
                  {!bouncerEnabled && (
                    <button
                      type="button"
                      onClick={() =>
                        putUserEmailFromCognitoRequest(userInfo.userId)
                      }
                      style={this.buttonStyle}
                    >
                      Update User Email From Cognito
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => postResetPasswordRequest(userInfo.userId)}
                    style={this.buttonStyle}
                    disabled={resetPasswordDisabled}
                  >
                    Reset Password
                  </button>
                  <button
                    type="button"
                    onClick={() => postResendInvitationRequest(userInfo.userId)}
                    style={this.buttonStyle}
                    disabled={invitationDisabled}
                  >
                    Resend Invitation
                  </button>
                </div>
              </Fragment>
            )}
          </Card>

          <Card titleName="Current Roles" initialHide={false}>
            <UserRolesTable
              postExpireRoleRequest={postExpireRoleRequest}
              postExpireAllRolesRequest={postExpireAllRolesRequest}
              postCognitoUserRemoveGroupRequest={
                postCognitoUserRemoveGroupRequest
              }
              roles={userRoles}
              userId={userInfo.userId}
            />
          </Card>

          <Card titleName="Add Role">
            <UserRoleForm
              postAddRoleRequest={postAddRoleRequest}
              userId={userInfo.userId}
              ssus={ssus}
              ssuList={ssuList}
              allStudiesDataSource={allStudiesDataSource}
              allSitesDataSource={allSitesDataSource}
              allPcnsDataSource={allPcnsDataSource}
              pcnStudyMap={pcnStudyMap}
              siteStudyMap={siteStudyMap}
              studies={studies}
              sites={sites}
              roles={userRoles}
              postCognitoUserAddGroupRequest={postCognitoUserAddGroupRequest}
            />
          </Card>

          <Card titleName="Past Roles">
            <ExpiredRolesTable
              postAddRoleRequest={postAddRoleRequest}
              userId={userInfo.userId}
              previousRoles={previousRoles}
              postCognitoUserAddGroupRequest={postCognitoUserAddGroupRequest}
            />
          </Card>
          <Card titleName="Login History">
            <ModeReport userId={userInfo.userId} />
          </Card>
          <Card titleName="Diagnostics">
            <Diagnostics
              role={userInfo.role}
              transactions={roleTxns}
              rolledUpData={roleTxnsRolledUp}
              userId={userId}
            />
          </Card>
        </div>
      </section>
    );
  }
}

User.defaultProps = {
  userInfo: {
    userId: null,
    lastName: '',
    firstName: '',
    email: '',
    title: '',
    department: '',
    startDate: '',
    role: {},
    cognitoGroups: '',
    jobTitle: '',
    credentials: '',
    notes: ''
  },
  userRoles: [],
  ssus: {},
  studies: {},
  sites: {},
  ssuList: [],
  allStudiesDataSource: [],
  allSitesDataSource: [],
  allPcnsDataSource: [],
  pcnStudyMap: {},
  siteStudyMap: {},
  previousRoles: [],
  roleTxns: [],
  roleTxnsRolledUp: {},
  cognitoUser: {}
};

User.propTypes = {
  userInfo: PropTypes.shape({
    userId: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    email: PropTypes.string,
    title: PropTypes.oneOf(titleOptions),
    department: PropTypes.string,
    startDate: PropTypes.string,
    role: PropTypes.object,
    cognitoGroups: PropTypes.string,
    jobTitle: PropTypes.string,
    credentials: PropTypes.string,
    notes: PropTypes.string,
    studyMarketplaceRole: PropTypes.bool
  }),
  userRoles: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        roleName: PropTypes.string,
        momentStartDate: PropTypes.object,
        momentExpireDate: PropTypes.object,
        startDate: PropTypes.string,
        expireDate: PropTypes.string,
        timeZoneObj: PropTypes.object
      }),
      PropTypes.shape({
        roleName: PropTypes.string,
        momentStartDate: PropTypes.object,
        momentExpireDate: PropTypes.object,
        startDate: PropTypes.string,
        expireDate: PropTypes.string,
        studyName: PropTypes.string,
        siteName: PropTypes.string,
        ssuId: PropTypes.string,
        timeZoneObj: PropTypes.object
      })
    ])
  ),
  ssus: PropTypes.object,
  studies: PropTypes.object,
  sites: PropTypes.object,
  ssuList: PropTypes.array,
  allStudiesDataSource: PropTypes.array,
  allSitesDataSource: PropTypes.array,
  allPcnsDataSource: PropTypes.array,
  pcnStudyMap: PropTypes.object,
  siteStudyMap: PropTypes.object,
  previousRoles: PropTypes.array,
  roleTxns: PropTypes.array,
  roleTxnsRolledUp: PropTypes.object,
  cognitoUser: PropTypes.object,
  putUserRequest: PropTypes.func.isRequired,
  putUserEmailRequest: PropTypes.func.isRequired,
  putUserEmailFromCognitoRequest: PropTypes.func.isRequired,
  postExpireRoleRequest: PropTypes.func.isRequired,
  postExpireAllRolesRequest: PropTypes.func.isRequired,
  postAddRoleRequest: PropTypes.func.isRequired,
  fetchCognitoUserRequest: PropTypes.func.isRequired,
  fetchUserListRequest: PropTypes.func.isRequired,
  fetchUserRoleTxnsRequest: PropTypes.func.isRequired,
  fetchSsusRequest: PropTypes.func.isRequired,
  fetchStudiesRequest: PropTypes.func.isRequired,
  fetchSitesRequest: PropTypes.func.isRequired,
  fetchUserRoleTxnsRolledUpRequest: PropTypes.func.isRequired,
  postResetPasswordRequest: PropTypes.func.isRequired,
  postResendInvitationRequest: PropTypes.func.isRequired,
  postCognitoUserAddGroupRequest: PropTypes.func.isRequired,
  postCognitoUserRemoveGroupRequest: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: {
      uuid: PropTypes.string.isRequired
    }
  }).isRequired
};

export default withUser(User);
