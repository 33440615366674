import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import bouncerToken from '../../fetchers/bouncerToken';
import { leftColumnStyle, readOnlyStyle } from '../commonStyles';

const value = {
  ...readOnlyStyle,
  color: 'black',
  borderColor: 'rgb(118, 118, 118)'
};

export default function Auth0Info({ auth0User }) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  const {
    identities,
    multifactor,
    username,
    authenticationMethods
  } = auth0User;

  let identitiesString = '';
  let mfaString = '';
  if (identities && identities.length) {
    identitiesString = identities.map(e => e.connection).join(', ');
  }
  if (
    multifactor &&
    multifactor.length &&
    authenticationMethods &&
    authenticationMethods.length
  ) {
    const transformed = authenticationMethods
      .filter(e => e.confirmed)
      .filter(e => e.last_auth_at)
      .sort((a, b) => moment(a.last_auth_at).isAfter(moment(b.last_auth_at)))
      .map(authenticationMethod => {
        if (authenticationMethod.type === 'sms') {
          return `SMS (${authenticationMethod.name})`;
        }
        if (authenticationMethod.type === 'email') {
          return `Email (${authenticationMethod.name})`;
        }
        if (authenticationMethod.type === 'totp') {
          return 'OTP';
        }
        return authenticationMethod.type;
      });
    if (transformed && transformed.length) {
      // eslint-disable-next-line prefer-destructuring
      mfaString = transformed[0];
    }
  }

  return (
    <>
      {bouncerEnabled && (
        <>
          <label htmlFor="providerId" style={leftColumnStyle}>
            Provider ID
          </label>
          <input name="providerId" style={value} disabled value={username} />
          <label htmlFor="identitiesString" style={leftColumnStyle}>
            Connection
          </label>
          <input
            name="identitiesString"
            style={value}
            disabled
            value={identitiesString}
          />
          <label htmlFor="mfaString" style={leftColumnStyle}>
            Multi-Factor Authentication
          </label>
          <input name="mfaString" style={value} disabled value={mfaString} />
        </>
      )}
    </>
  );
}

Auth0Info.defaultProps = {
  auth0User: {}
};

Auth0Info.propTypes = {
  auth0User: PropTypes.object
};
