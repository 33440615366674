/* eslint-disable func-names */
const bouncerToken = (function() {
  /* eslint-enable func-names */
  let getAuth0AccessToken = async () => '';
  let bouncerProps = {};
  return {
    // public interface
    setGetAuth0AccessToken(method) {
      getAuth0AccessToken = method;
    },
    async getToken() {
      const token = await getAuth0AccessToken();
      return token;
    },
    setBouncerProps(newProps) {
      bouncerProps = newProps;
    },
    getBouncerProps() {
      return bouncerProps;
    }
  };
})();

export default bouncerToken;
