import { call } from 'redux-saga/effects';

function* throwErrorIfFailure(response) {
  if (!response.ok) {
    const responseText = yield call([response, response.text]);
    throw responseText;
  }
}

export default throwErrorIfFailure;
