import { fetchStudies, createStudy, updateStudy } from './types/studies';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchStudiesRequest = () => ({
  type: fetchStudies[REQUEST]
});

export const fetchStudiesSuccess = payload => ({
  type: fetchStudies[SUCCESS],
  payload
});

export const fetchStudiesFailure = err => ({
  type: fetchStudies[FAILURE],
  msg: err
});

export const createStudyRequest = payload => ({
  type: createStudy[REQUEST],
  payload
});

export const createStudySuccess = payload => ({
  type: createStudy[SUCCESS],
  payload
});

export const createStudyFailure = err => ({
  type: createStudy[FAILURE],
  msg: err
});

export const updateStudyRequest = payload => ({
  type: updateStudy[REQUEST],
  payload
});

export const updateStudySuccess = payload => ({
  type: updateStudy[SUCCESS],
  payload
});

export const updateStudyFailure = err => ({
  type: updateStudy[FAILURE],
  msg: err
});
