import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { updateStudyRequest } from '../../../actions/studies';
import studyModalValidator from '../validators/studyModalValidator';
import Modal from '../Modal';
import StudyModalForm from './StudyModalForm';
import {
  fetchCros,
  fetchSponsors,
  fetchTherapeuticAreas
} from '../../../fetchers/studies';

class EditStudyModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    const { data } = this.props;
    this.state = {
      areas: [],
      sponsors: [],
      cros: [],
      data
    };
  }

  componentDidMount() {
    Promise.all([fetchTherapeuticAreas(), fetchSponsors(), fetchCros()]).then(
      results => {
        const [areas, sponsors, cros] = results;
        this.setState({
          areas,
          sponsors: sponsors.sponsors,
          cros: cros.cros
        });
      }
    );
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { className, updateStudyRequestAction } = this.props;
    const { data } = this.state;
    const initialValues = { ...data, newGroupType: 'SM' };
    const { areas, sponsors, cros } = this.state;

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Edit Study
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={studyModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitValues => {
            updateStudyRequestAction(submitValues);
            this.onClose();
          }}
        >
          {({
            submitForm,
            isSubmitting,
            handleChange,
            setFieldValue,
            values
          }) => {
            return (
              <StudyModalForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                onClose={this.onClose}
                areas={areas}
                sponsors={sponsors}
                cros={cros}
                data={data}
                values={values}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EditStudyModal.defaultProps = {
  data: {
    studyName: '',
    type: '',
    protocolId: '',
    protocolName: '',
    protocolNameBlinded: '',
    pcn: '',
    therapeuticArea: '',
    indication: '',
    phase: 'N/A',
    status: 'Inactive',
    screenFailureRate: 0,
    dropOutRate: 0,
    customer: '',
    sponsor: '',
    cro: '',
    blinded: false
  },
  className: ''
};

EditStudyModal.propTypes = {
  data: PropTypes.shape({
    studyName: PropTypes.string,
    type: PropTypes.string,
    protocolId: PropTypes.string,
    protocolName: PropTypes.string,
    protocolNameBlinded: PropTypes.string,
    pcn: PropTypes.string,
    therapeuticArea: PropTypes.string,
    indication: PropTypes.string,
    phase: PropTypes.string,
    status: PropTypes.string,
    screenFailureRate: PropTypes.number,
    dropOutRate: PropTypes.number,
    customer: PropTypes.string,
    sponsor: PropTypes.string,
    cro: PropTypes.string,
    blinded: PropTypes.string,
    blindingGroups: PropTypes.array
  }),
  className: PropTypes.string,
  updateStudyRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  updateStudyRequestAction: payload => dispatch(updateStudyRequest(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(EditStudyModal);
