import createSagaMiddleware from 'redux-saga';
import { createStore, combineReducers, applyMiddleware } from 'redux';

// Reducers
import { composeWithDevTools } from 'redux-devtools-extension';
import users from '../reducers/users';
import roleTxns from '../reducers/roleTxns';
import ssus from '../reducers/ssus';
import roleTxnsRolledUp from '../reducers/roleTxnsRolledUp';
import previousRoles from '../reducers/previousRoles';
import apiKeys from '../reducers/apiKeys';
import orphans from '../reducers/orphans';
import addables from '../reducers/addables';
import cognitoUsers from '../reducers/cognitoUsers';
import sites from '../reducers/sites';
import modals from '../reducers/modals';
import studies from '../reducers/studies';
import paginatedUsers from '../reducers/paginatedUsers';

import rootSaga from '../sagas';

const configureStore = () => {
  // Get middleware for redux-saga
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    combineReducers({
      users,
      roleTxns,
      ssus,
      roleTxnsRolledUp,
      previousRoles,
      apiKeys,
      orphans,
      addables,
      cognitoUsers,
      sites,
      modals,
      studies,
      paginatedUsers
    }),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  // Initialize and run saga middleware
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
