import { takeEvery, spawn, fork, call } from 'redux-saga/effects';
import apiMap from './resourceMaps/apiMap';

function* apiRoutine(action) {
  yield call(apiMap[action.type], action);
}

export function* fetchRequestWatcher() {
  try {
    yield takeEvery(Object.keys(apiMap), apiRoutine);
  } catch (err) {
    console.error('Major problem: restapi saga failed.', { err });
  } finally {
    console.log('Restapi has ended.');
  }
}

// watch for network api requests
export function* apiWatcher() {
  yield fork(fetchRequestWatcher);
}

export function* mainWatchers() {
  yield spawn(apiWatcher);
}

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield spawn(mainWatchers);
}
