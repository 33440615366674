/*
  By Admin we mean Admin page Admin page selector, container, component, etc...
  This is an HOC.
*/
import React from 'react';
import { connect } from 'react-redux';

import makeAdminSelector from '../selectors/admin';
import { fetchUserListRequest } from '../actions/users';
import {
  fetchApiKeysRequest,
  deleteApiKeyRequest,
  postApiKeyRequest
} from '../actions/apiKeys';

const withAdmin = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchUserListRequest: () => dispatch(fetchUserListRequest()),
    fetchApiKeysRequest: () => dispatch(fetchApiKeysRequest()),
    deleteApiKeyRequest: key => dispatch(deleteApiKeyRequest(key)),
    postApiKeyRequest: payload => dispatch(postApiKeyRequest(payload))
  });

  return connect(
    makeAdminSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withAdmin;
