// import React from 'react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { fetchUserHistory } from '../../fetchers/users';

// This component takes a userId and gets a report on all the login activity for that user
// for the last thirty days.
const ModeReport = ({ userId }) => {
  const [signedUrl, setSignedUrl] = useState('');
  useEffect(() => {
    if (userId) {
      fetchUserHistory(userId).then(res => {
        res.text().then(text => setSignedUrl(text));
      });
    }
  }, [userId]);
  return (
    // iframe component is set to the width of the card and height that the mode report is on app.mode.com
    <iframe title="Login History" src={signedUrl} width="100%" height="450" />
  );
};

ModeReport.defaultProps = {
  userId: undefined
};
ModeReport.propTypes = {
  userId: PropTypes.string
};

export default ModeReport;
