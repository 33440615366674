import bouncerToken from './bouncerToken';

const getAuthTokenHelper = async () => {
  let token = '';
  token = await bouncerToken.getToken();

  return token;
};

export default getAuthTokenHelper;
