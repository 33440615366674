import * as yup from 'yup';

const dateRegEx = /^(([0-2][0-9])|([3][0-1]))\/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\/\d{4}$/;
const ssuModalValidator = yup.object().shape({
  sponsorCtaDate: yup
    .string()
    .matches(dateRegEx, 'Date must match DD-MMM-YYYY format'),
  awardDate: yup
    .string()
    .matches(dateRegEx, 'Date must match DD-MMM-YYYY format'),
  siteWorkOrderDate: yup
    .string()
    .matches(dateRegEx, 'Date must match DD-MMM-YYYY format'),
  siteNumber: yup.string().required('You must enter a Site Number'),
  status: yup.string().required('You must select a valid status'),
  studyId: yup.string().required('You must select a Study'),
  siteId: yup.string().required('You must select a Site')
});

export default ssuModalValidator;
