/*
  Cognito Users (`/users/pool`) container HOC
*/
import React from 'react';
import { connect } from 'react-redux';

import makeCognitoUsersSelector from '../selectors/cognitoUsers';
import {
  fetchCognitoUsersRequest,
  postCognitoUserRequest
} from '../actions/cognitoUsers';

const withCognitoUsers = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchCognitoUsersRequest: () => dispatch(fetchCognitoUsersRequest()),
    postCognitoUserRequest: payload => dispatch(postCognitoUserRequest(payload))
  });

  return connect(
    makeCognitoUsersSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withCognitoUsers;
