/*
 ManageSsus (`/manage-ssus`) container HOC
 */
import React from 'react';
import { connect } from 'react-redux';

import { showModal } from '../actions/modals';
import { fetchSsusRequest } from '../actions/ssus';
import { fetchSitesRequest } from '../actions/sites';
import { fetchStudiesRequest } from '../actions/studies';

import makeManageSsusSelector from '../selectors/manageSsus';

const withManageSsus = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    showModal: (modalType, data) => dispatch(showModal(modalType, data)),
    fetchSsusRequest: () => dispatch(fetchSsusRequest()),
    fetchSitesRequest: () => dispatch(fetchSitesRequest()),
    fetchStudiesRequest: () => dispatch(fetchStudiesRequest())
  });

  return connect(
    makeManageSsusSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withManageSsus;
