/* eslint-disable func-names */
const featureFlags = (function() {
  let features = {};
  return {
    setFeatureFlags(newProps) {
      features = newProps;
    },
    getFeatureFlags() {
      return features;
    }
  };
})();

export default featureFlags;
