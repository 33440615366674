/*
  ManageSites (`/manage-sites`) container HOC
*/
import React from 'react';
import { connect } from 'react-redux';

import { showModal } from '../actions/modals';
import { fetchSitesRequest } from '../actions/sites';

import makeManageSitesSelector from '../selectors/manageSites';

const withManageSites = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    showModal: (modalType, data) => dispatch(showModal(modalType, data)),
    fetchSitesRequest: () => dispatch(fetchSitesRequest())
  });

  return connect(
    makeManageSitesSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withManageSites;
