import { fetchSites, createSite, updateSite } from '../actions/types/sites';
import { SUCCESS, FAILURE } from '../actions/types/utils';

const sites = (state = [], action) => {
  switch (action.type) {
    case fetchSites[SUCCESS]:
      return action.payload;
    case fetchSites[FAILURE]:
      return state;
    case createSite[SUCCESS]:
      return [...state, action.payload];
    case createSite[FAILURE]:
      return state;
    case updateSite[SUCCESS]:
      return state.map(item => {
        if (item.siteId !== action.payload.siteId) {
          return item;
        }

        return {
          ...item,
          ...action.payload
        };
      });
    case updateSite[FAILURE]:
      return state;
    default:
      return state;
  }
};

export default sites;
