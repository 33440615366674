function filterStudiesByPcn(pcnStudyMap, selectedPcn) {
  const studyData = pcnStudyMap[selectedPcn];

  return studyData;
}

function filterSitesByStudyId(ssuList, studyId, ssus) {
  const filteredSites = ssuList
    .filter(ssuId => studyId === ssus[ssuId].studyId)
    .map(ssuId => ({
      name: ssus[ssuId].siteName,
      id: ssus[ssuId].siteId,
      size: 'Medium'
    }));
  return filteredSites;
}
function filterStudiesBySiteId(ssuList, siteId, ssus) {
  const filteredStudies = ssuList
    .filter(ssuId => siteId === ssus[ssuId].siteId)
    .map(ssuId => ({
      name: ssus[ssuId].studyName,
      id: ssus[ssuId].studyId,
      size: 'Medium'
    }));
  return filteredStudies;
}

function filterStudiesByStudyId(ssuList, studyId, ssus) {
  const id = ssuList.find(ssuId => studyId === ssus[ssuId].studyId);
  const filteredStudies = {
    name: ssus[id].studyName,
    id: ssus[id].studyId,
    size: 'Medium'
  };
  return [filteredStudies];
}

function filterSitesBySiteId(ssuList, siteId, ssus) {
  const id = ssuList.find(ssuId => siteId === ssus[ssuId].siteId);
  const filteredSites = {
    name: ssus[id].siteName,
    id: ssus[id].siteId,
    size: 'Medium'
  };
  return [filteredSites];
}

function filterPcnsBySiteId(ssuList, siteId, ssus, studies) {
  const filteredPcns = ssuList
    .filter(ssuId => siteId === ssus[ssuId].siteId)
    .map(ssuId => ({
      name:
        studies &&
        studies.find(study => study.studyId === ssus[ssuId].studyId).pcn,
      id: ssus[ssuId].studyId,
      size: 'Medium'
    }));
  return filteredPcns;
}

function filterPcnsByStudyId(ssuList, studyId, ssus, studies) {
  const s = studies && studies.find(study => study.studyId === studyId);
  const id = ssuList.find(ssuId => studyId === ssus[ssuId].studyId);
  const filteredPcns = {
    name: s.pcn,
    id: ssus[id].studyId,
    size: 'Medium'
  };
  return [filteredPcns];
}

function filterStudiesByBoth(siteStudyMap, filteredByPcn, siteId) {
  const filteredStudies = filteredByPcn.filter(study =>
    siteStudyMap[siteId].includes(study.id)
  );

  return filteredStudies;
}

function filterSitesByPcn(pcnStudyMap, ssuList, ssus, selectedPcn) {
  const studyData = pcnStudyMap[selectedPcn];

  const studyIds = studyData.map(i => i.id);

  const filteredSites = [];
  for (let i = 0; i < studyIds.length; i += 1) {
    if (studyIds[i]) {
      const id = ssuList.find(ssuId => studyIds[i] === ssus[ssuId].studyId);
      const siteFromStudy = {
        name: ssus[id].siteName,
        id: ssus[id].siteId,
        size: 'Medium'
      };
      filteredSites.push(siteFromStudy);
    }
  }

  return filteredSites;
}

function removeDuplicatesAndSort(dataSource) {
  return [...new Map(dataSource.map(item => [item.name, item])).values()].sort(
    (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  );
}

export function getStudiesDataSource(
  selectedSiteId,
  selectedStudyId,
  selectedPcn,
  ssus,
  ssuList,
  allStudiesDataSource,
  pcnStudyMap,
  siteStudyMap
) {
  return removeDuplicatesAndSort(
    (selectedStudyId &&
      filterStudiesByStudyId(ssuList, selectedStudyId, ssus)) ||
      (selectedPcn &&
        selectedSiteId &&
        filterStudiesByBoth(
          siteStudyMap,
          filterStudiesByPcn(pcnStudyMap, selectedPcn),
          selectedSiteId
        )) ||
      (selectedPcn && filterStudiesByPcn(pcnStudyMap, selectedPcn)) ||
      (selectedSiteId &&
        filterStudiesBySiteId(ssuList, selectedSiteId, ssus)) ||
      allStudiesDataSource
  );
}
export function getSitesDataSource(
  selectedSiteId,
  selectedStudyId,
  selectedPcn,
  ssus,
  ssuList,
  allSitesDataSource,
  pcnStudyMap
) {
  return removeDuplicatesAndSort(
    (selectedSiteId && filterSitesBySiteId(ssuList, selectedSiteId, ssus)) ||
      (selectedStudyId &&
        filterSitesByStudyId(ssuList, selectedStudyId, ssus)) ||
      (selectedPcn &&
        filterSitesByPcn(pcnStudyMap, ssuList, ssus, selectedPcn)) ||
      allSitesDataSource
  );
}

export function getPcnsDataSource(
  selectedSiteId,
  selectedStudyId,
  ssus,
  ssuList,
  allPcnsDataSource,
  studies
) {
  return removeDuplicatesAndSort(
    (selectedSiteId &&
      filterPcnsBySiteId(ssuList, selectedSiteId, ssus, studies)) ||
      (selectedStudyId &&
        filterPcnsByStudyId(ssuList, selectedStudyId, ssus, studies)) ||
      allPcnsDataSource
  );
}
