/*
  Selector for users page `/users`
*/
import { createSelector } from 'reselect';

const paginatedUsersSelector = state => state.paginatedUsers;

const allPaginatedUsersSelector = paginatedUsers => {
  const users = paginatedUsers.map(paginatedUser => {
    const {
      created,
      lastName,
      firstName,
      email,
      emailVerified,
      userId,
      title,
      notes,
      department,
      startDate,
      role,
      user_id: providerId
    } = paginatedUser;

    return {
      created,
      lastName,
      firstName,
      email,
      emailVerified,
      userId,
      title,
      notes,
      department,
      startDate,
      role,
      providerId
    };
  });
  return { users };
};

const makePaginatedUsersSelector = () => {
  return createSelector(
    paginatedUsersSelector,
    allPaginatedUsersSelector
  );
};

export default makePaginatedUsersSelector;
