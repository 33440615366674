import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import App from './containers/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import cognitoConfig from './cognitoConstants';
import WithBouncerOrAmplify from './containers/WithBouncerOrAmplify';

const store = configureStore();

cognitoConfig().then(() => {
  render(
    <Provider store={store}>
      <WithBouncerOrAmplify component={App} />
    </Provider>,
    document.getElementById('root')
  );
});
