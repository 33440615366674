import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form } from 'formik';
import { fetchTherapeuticIndicationsForArea } from '../../../fetchers/studies';
import './StudyModalForm.scss';
import { phases, statuses } from '../../../constants';
import GroupSection from './GroupSection';

const MAX_STUDY_NAME_LENGTH = 1000;

const validateStudyName = value => {
  let errorMessage;

  if (value && value.length > MAX_STUDY_NAME_LENGTH) {
    errorMessage = `Study name should be shorter than ${MAX_STUDY_NAME_LENGTH} characters`;
  }

  return errorMessage;
};

class StudyModalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indications: [],
      selectedArea: ''
    };
  }

  async componentDidMount() {
    const { data } = this.props;
    if (data && data.therapeuticArea) {
      const indications = await fetchTherapeuticIndicationsForArea(
        data.therapeuticArea
      );
      this.setState({
        selectedArea: data.therapeuticArea ? data.therapeuticArea : '',
        indications: indications ? indications.indications : ''
      });
    }
  }

  render() {
    const {
      submitForm,
      isSubmitting,
      handleChange,
      setFieldValue,
      onClose,
      areas,
      sponsors,
      cros,
      values
    } = this.props;
    const { indications, selectedArea } = this.state;
    return (
      <Fragment>
        <div className="modal-body text-left">
          <Form className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="studyName">Study Name</label>
                <div className="col-md-12 row">
                  <Field
                    id="studyName"
                    className="form-control req-field"
                    name="studyName"
                    type="text"
                    placeholder="Study Name"
                    validate={validateStudyName}
                  />
                  <ErrorMessage name="studyName" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="type">Type</label>
                <div className="col-md-12 row">
                  <Field
                    id="type"
                    className="form-control"
                    name="type"
                    type="text"
                    placeholder="Type"
                    disabled
                  />
                  <ErrorMessage name="type" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="protocolId">Protocol ID</label>
                <div className="col-md-12 row">
                  <Field
                    id="protocolId"
                    className="form-control"
                    name="protocolId"
                    type="text"
                    placeholder="Protocol ID"
                  />
                  <ErrorMessage name="protocolId" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="protocolName">Protocol Name</label>
                <div className="col-md-12 row">
                  <Field
                    id="protocolName"
                    className="form-control"
                    name="protocolName"
                    type="text"
                    placeholder="Protocol Name"
                  />
                  <ErrorMessage name="protocolName" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="protocolNameBlinded">
                  Protocol Name Blinded
                </label>
                <div className="col-md-12 row">
                  <Field
                    id="protocolNameBlinded"
                    className="form-control"
                    name="protocolNameBlinded"
                    type="text"
                    placeholder="Protocol Name Blinded"
                  />
                  <ErrorMessage name="protocolNameBlinded" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="pcn">PCN</label>
                <div className="col-md-12 row">
                  <Field
                    id="pcn"
                    className="form-control req-field"
                    name="pcn"
                    type="text"
                    placeholder="PCN"
                  />
                  <ErrorMessage name="pcn" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="therapeuticArea">Therapeutic Area</label>
                <div className="col-md-12 row">
                  <Field
                    id="therapeuticArea"
                    className="form-control"
                    name="therapeuticArea"
                    component="select"
                    placeholder="Therapeutic Area"
                    disabled={areas.length < 1}
                    onChange={async e => {
                      handleChange(e);
                      const selected = e.target.value;
                      if (selected) {
                        const indic = await fetchTherapeuticIndicationsForArea(
                          selected
                        );
                        this.setState({
                          indications: indic.indications,
                          selectedArea: selected
                        });
                      } else {
                        this.setState({
                          indications: '',
                          selectedArea: selected
                        });
                      }
                      setFieldValue('indication', '');
                    }}
                    value={selectedArea}
                  >
                    <option value="" />
                    {areas &&
                      areas.map(area => (
                        <option value={area.area}>{area.area}</option>
                      ))}
                  </Field>
                  <ErrorMessage name="therapeuticArea" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="indication">Indication</label>
                <div className="col-md-12 row">
                  <Field
                    id="indication"
                    className="form-control"
                    name="indication"
                    component="select"
                    placeholder="Indication"
                    disabled={indications.length < 1}
                  >
                    <option value="" />
                    {indications.length > 0 &&
                      indications.map(indication => (
                        <option value={indication}>{indication}</option>
                      ))}
                  </Field>
                  <ErrorMessage name="indication" />
                </div>
              </div>
              {values.blindingGroups && values.blindingGroups.length > 0 && (
                <div className="form-group d-flex flex-column">
                  <h5>Existing blinding groups</h5>
                  <span>{values.blindingGroups.join(', ')}</span>
                </div>
              )}
              {values.blinded && (
                <div className="form-group">
                  <h5>Add Blinding Groups</h5>
                  <div className="col-md-12 row">
                    <Field
                      id="newBlindingGroups"
                      className="form-control"
                      name="newBlindingGroups"
                      component="textarea"
                    />
                  </div>
                  <ErrorMessage name="newBlindingGroups" />
                </div>
              )}
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="phase">Phase</label>
                <div className="col-md-12 row">
                  <Field
                    id="phase"
                    className="form-control"
                    name="phase"
                    component="select"
                    placeholder="Phase"
                  >
                    {phases.map(phase => (
                      <option value={phase}>{phase}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="phase" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <div className="col-md-12 row">
                  <Field
                    id="status"
                    className="form-control req-field"
                    name="status"
                    component="select"
                    placeholder="Status"
                  >
                    {statuses.map(status => (
                      <option value={status}>{status}</option>
                    ))}
                  </Field>
                  <ErrorMessage name="status" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="screenFailureRate">Screen Fail Rate</label>
                <div className="col-md-12 row">
                  <Field
                    id="screenFailureRate"
                    className="form-control req-field"
                    name="screenFailureRate"
                    type="number"
                    placeholder="Screen Fail Rate"
                  />
                  <ErrorMessage name="screenFailureRate" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="dropOutRate">Drop Rate</label>
                <div className="col-md-12 row">
                  <Field
                    id="dropOutRate"
                    className="form-control req-field"
                    name="dropOutRate"
                    type="number"
                    placeholder="Drop Rate"
                  />
                  <ErrorMessage name="dropOutRate" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="customer">Customer</label>
                <div className="col-md-12 row">
                  <Field
                    id="customer"
                    className="form-control"
                    name="customer"
                    type="text"
                    placeholder="Customer"
                  />
                  <ErrorMessage name="customer" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="sponsor">Sponsor</label>
                <div className="col-md-12 row">
                  <Field
                    id="sponsor"
                    className="form-control"
                    name="sponsor"
                    component="select"
                    placeholder="Sponsor"
                    disabled={sponsors.length < 1}
                  >
                    <option value="" />
                    {sponsors.length > 0 &&
                      sponsors.map(sponsor => (
                        <option value={sponsor}>{sponsor}</option>
                      ))}
                  </Field>
                  <ErrorMessage name="sponsor" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="cro">CRO</label>
                <div className="col-md-12 row">
                  <Field
                    id="cro"
                    className="form-control"
                    name="cro"
                    component="select"
                    placeholder="CRO"
                    disabled={cros.length < 1}
                  >
                    <option value="" />
                    {cros.length > 0 &&
                      cros.map(cro => <option value={cro}>{cro}</option>)}
                  </Field>
                  <ErrorMessage name="cro" />
                </div>
              </div>
              <div className="form-group d-flex">
                <label htmlFor="nonTraditional">Non-traditional</label>
                <span style={{ marginLeft: '20px' }}>
                  <Field
                    id="nonTraditional"
                    className="form-control custom-checkbox"
                    name="nonTraditional"
                    type="checkbox"
                    checked={values.nonTraditional}
                  />
                  <ErrorMessage name="nonTraditional" />
                </span>
              </div>
            </div>
          </Form>
          {values.isGroupAssign && <GroupSection />}
        </div>
        <div className="modal-footer text-left text-white ">
          <div className="row col-12 p-0 justify-content-between">
            <div className="col-12 pull-right" style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="btn text-white align-bottom bg-p mr-2"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Save
              </button>
              <button
                type="button"
                className="btn text-white align-bottom bg-p"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

StudyModalForm.defaultProps = {
  data: null
};

StudyModalForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  sponsors: PropTypes.array.isRequired,
  cros: PropTypes.array.isRequired,
  data: PropTypes.object,
  values: PropTypes.object.isRequired
};

export default StudyModalForm;
