import { generateFetchTypes } from './utils';

export const fetchCognitoUsers = generateFetchTypes('FETCH_COGNITO_USERS');
export const fetchCognitoUser = generateFetchTypes('FETCH_COGNITO_USER');
export const postCognitoUserAddGroup = generateFetchTypes(
  'POST_COGNITO_USER_ADD_GROUP'
);
export const postCognitoUserRemoveGroup = generateFetchTypes(
  'POST_COGNITO_USER_REMOVE_GROUP'
);
export const postCognitoUser = generateFetchTypes('POST_COGNITO_USER');
export const postResetPassword = generateFetchTypes('POST_RESET_PASSWORD');
export const postResendInvitation = generateFetchTypes(
  'POST_RESEND_INVITATION'
);
