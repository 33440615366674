import {
  fetchCognitoUsers,
  fetchCognitoUser,
  postCognitoUserAddGroup,
  postCognitoUserRemoveGroup,
  postCognitoUser,
  postResetPassword,
  postResendInvitation
} from './types/cognitoUsers';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchCognitoUsersRequest = () => ({
  type: fetchCognitoUsers[REQUEST]
});

export const fetchCognitoUsersFailure = err => ({
  type: fetchCognitoUsers[FAILURE],
  msg: err
});

export const fetchCognitoUsersSuccess = payload => ({
  type: fetchCognitoUsers[SUCCESS],
  payload
});

export const fetchCognitoUserRequest = userId => ({
  type: fetchCognitoUser[REQUEST],
  userId
});

export const fetchCognitoUserFailure = err => ({
  type: fetchCognitoUser[FAILURE],
  msg: err
});

export const fetchCognitoUserSuccess = payload => ({
  type: fetchCognitoUser[SUCCESS],
  payload
});

export const postCognitoUserAddGroupRequest = (userId, payload) => ({
  type: postCognitoUserAddGroup[REQUEST],
  userId,
  payload
});

export const postCognitoUserAddGroupFailure = err => ({
  type: postCognitoUserAddGroup[FAILURE],
  msg: err
});

export const postCognitoUserAddGroupSuccess = (userId, payload) => ({
  type: postCognitoUserAddGroup[SUCCESS],
  userId,
  payload
});

export const postCognitoUserRemoveGroupRequest = (userId, payload) => ({
  type: postCognitoUserRemoveGroup[REQUEST],
  userId,
  payload
});

export const postCognitoUserRemoveGroupFailure = err => ({
  type: postCognitoUserRemoveGroup[FAILURE],
  msg: err
});

export const postCognitoUserRemoveGroupSuccess = (userId, payload) => ({
  type: postCognitoUserRemoveGroup[SUCCESS],
  userId,
  payload
});

export const postCognitoUserRequest = payload => ({
  type: postCognitoUser[REQUEST],
  payload
});

export const postCognitoUserFailure = err => ({
  type: postCognitoUser[FAILURE],
  msg: err
});

export const postCognitoUserSuccess = payload => ({
  type: postCognitoUser[SUCCESS],
  payload
});

export const postResetPasswordRequest = cognitoId => ({
  type: postResetPassword[REQUEST],
  cognitoId
});

export const postResetPasswordSuccess = payload => ({
  type: postResetPassword[SUCCESS],
  payload
});

export const postResetPasswordFailure = err => ({
  type: postResetPassword[FAILURE],
  msg: err
});

export const postResendInvitationRequest = cognitoId => ({
  type: postResendInvitation[REQUEST],
  cognitoId
});

export const postResendInvitationSuccess = payload => ({
  type: postResendInvitation[SUCCESS],
  payload
});

export const postResendInvitationFailure = err => ({
  type: postResendInvitation[FAILURE],
  msg: err
});
