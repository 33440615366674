import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const fetchStudies = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/list-studies`, init);
  return fetch(request);
};

export const fetchStudy = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/study/:studyid`, init);
  return fetch(request);
};

export const createStudy = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/create-study`, init);
  return fetch(request);
};

export const updateStudy = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/update-study`, init);
  return fetch(request);
};

export const fetchTherapeuticAreas = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/get-therapeutic-areas`,
    init
  );
  return fetch(request).then(res => res.json());
};

export const fetchTherapeuticIndicationsForArea = async area => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const param = encodeURIComponent(area);

  const request = new Request(
    `${apiPrefix}/admin/api/get-therapeutic-indications?area=${param}`,
    init
  );
  return fetch(request).then(res => res.json());
};

export const fetchSponsors = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/get-sponsors`, init);
  return fetch(request).then(res => res.json());
};

export const fetchCros = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/get-cros`, init);
  return fetch(request).then(res => res.json());
};
