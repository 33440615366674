import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form } from 'formik';
import { IMaskInput } from 'react-imask';
import { states, statuses, countries, province } from '../../../constants';
import bouncerToken from '../../../fetchers/bouncerToken';

const phoneMask = ({ field, form, ...props }) => (
  <IMaskInput {...field} {...props} mask="(000) 000-0000" />
);

phoneMask.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

function isCanada(values) {
  return values.country === 'Canada';
}

function getStateValues(values) {
  if (isCanada(values)) {
    return province.map(state => <option value={state}>{state}</option>);
  }
  return states.map(state => <option value={state}>{state}</option>);
}

function getPostalLabel(values) {
  return isCanada(values) ? 'Postal Code' : 'Zip';
}

function getStateLabel(values) {
  return isCanada(values) ? 'Province' : 'State';
}

function handlePostalCodeChange(values, setFieldValue) {
  return e => {
    let postalCodeValue;
    if (isCanada(values)) {
      const currentValue = e.target.value
        .toUpperCase()
        .replace(/[^\dA-Z]/g, '');
      postalCodeValue =
        currentValue.length < 3
          ? currentValue
          : `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}`.trim();
    } else {
      const currentValue = e.target.value.replace(/[^\d]/g, '');
      postalCodeValue =
        currentValue.length < 6
          ? currentValue
          : `${currentValue.slice(0, 5)}-${currentValue.slice(5, 9)}`;
    }
    setFieldValue('postalCode', postalCodeValue);
  };
}

const SiteModalForm = ({
  submitForm,
  isSubmitting,
  onClose,
  values,
  setFieldValue
}) => {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  return (
    <Fragment>
      <div className="modal-body text-left">
        <Form className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="SiteName">Site Name</label>
              <div className="col-md-12 row">
                <Field
                  id="siteName"
                  className="form-control req-field"
                  name="siteName"
                  type="text"
                  placeholder="Site Name"
                />
                <ErrorMessage name="siteName" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <div className="col-md-12 row">
                <Field
                  id="status"
                  className="form-control req-field"
                  name="status"
                  component="select"
                  placeholder="Status"
                >
                  {statuses.map(status => (
                    <option value={status}>{status}</option>
                  ))}
                </Field>
                <ErrorMessage name="status" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="addressLine1">Address Line 1</label>
              <div className="col-md-12 row">
                <Field
                  id="addressLine1"
                  className="form-control req-field"
                  name="addressLine1"
                  type="text"
                  placeholder="Address Line 1"
                />
                <ErrorMessage name="addressLine1" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="addressLine2">Address Line 2</label>
              <div className="col-md-12 row">
                <Field
                  id="addressLine2"
                  className="form-control"
                  name="addressLine2"
                  type="text"
                  placeholder="Address Line 2"
                />
                <ErrorMessage name="addressLine2" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <div className="col-md-12 row">
                <Field
                  id="city"
                  className="form-control req-field"
                  name="city"
                  type="text"
                  placeholder="City"
                />
                <ErrorMessage name="city" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="state">{getStateLabel(values)}</label>
              <div className="col-md-12 row">
                <Field
                  id="state"
                  className="form-control req-field"
                  name="state"
                  component="select"
                >
                  <option disabled selected />
                  {getStateValues(values)}
                </Field>
                <ErrorMessage name="state" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">{getPostalLabel(values)}</label>
              <div className="col-md-12 row">
                <Field
                  id="postalCode"
                  className="form-control req-field"
                  name="postalCode"
                  type="text"
                  placeholder={getPostalLabel(values)}
                  onChange={handlePostalCodeChange(values, setFieldValue)}
                />
                <ErrorMessage name="postalCode" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <div className="col-md-12 row">
                <Field
                  id="country"
                  className="form-control"
                  name="country"
                  component="select"
                  placeholder="Country"
                  onChange={e => {
                    setFieldValue('postalCode', '');
                    setFieldValue('state', '');
                    setFieldValue('country', e.target.value);
                  }}
                >
                  {countries.map(country => (
                    <option value={country}>{country}</option>
                  ))}
                </Field>
                <ErrorMessage name="country" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <div className="col-md-12 row">
                <Field
                  id="phone"
                  className="form-control"
                  name="phone"
                  type="tel"
                  component={phoneMask}
                  placeholder="Phone Number"
                />
                <ErrorMessage name="phone" />
              </div>
            </div>
            {!bouncerEnabled && (
              <div className="form-group">
                <label htmlFor="elligoPhone">Elligo Phone Number</label>
                <div className="col-md-12 row">
                  <Field
                    id="elligoPhone"
                    className="form-control"
                    name="elligoPhone"
                    type="tel"
                    component={phoneMask}
                    placeholder="Elligo Phone Number"
                  />
                  <ErrorMessage name="elligoPhone" />
                </div>
              </div>
            )}
          </div>
        </Form>
      </div>
      <div className="modal-footer text-left text-white ">
        <div className="row col-12 p-0 justify-content-between">
          <div className="col-12 pull-right" style={{ textAlign: 'right' }}>
            <button
              type="button"
              className="btn text-white align-bottom bg-p mr-2"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Save
            </button>
            <button
              type="button"
              className="btn text-white align-bottom bg-p"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SiteModalForm.propTypes = {
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  values: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default SiteModalForm;
