export const generateFetchTypes = action => {
  const fetchTypes = ['REQUEST', 'SUCCESS', 'FAILURE'];
  return fetchTypes.map(type => {
    return `${action}_${type}`;
  });
};

export const REQUEST = 0;
export const SUCCESS = 1;
export const FAILURE = 2;
