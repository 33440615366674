/*
  UserAdd page HOC container for '/users/add/:uuid'
*/
import React from 'react';
import { connect } from 'react-redux';

import makeUserAddSelector from '../selectors/userAdd';
import { fetchAddableUserRequest } from '../actions/addables';
import { fetchSsusRequest } from '../actions/ssus';
import { fetchStudiesRequest } from '../actions/studies';
import { fetchSitesRequest } from '../actions/sites';
import { postUserRequest } from '../actions/users';

const withUserAdd = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchAddableUserRequest: uuid => dispatch(fetchAddableUserRequest(uuid)),
    fetchSsusRequest: () => dispatch(fetchSsusRequest()),
    fetchStudiesRequest: () => dispatch(fetchStudiesRequest()),
    fetchSitesRequest: () => dispatch(fetchSitesRequest()),
    postUserRequest: (userId, payload, history) =>
      dispatch(postUserRequest(userId, payload, history))
  });

  return connect(
    makeUserAddSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withUserAdd;
