import { fetchUserRoleTxns } from '../actions/types/users';
import { SUCCESS } from '../actions/types/utils';

const roleTxns = (state = {}, action) => {
  if (action.type === fetchUserRoleTxns[SUCCESS]) {
    return { ...state, [action.uuid]: action.payload };
  }
  return state;
};

export default roleTxns;
