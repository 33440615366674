import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const fetchSsus = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/ssus`, init);
  return fetch(request);
};

export const createSsu = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/create-ssu`, init);
  return fetch(request);
};

export const updateSsu = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/update-ssu`, init);
  return fetch(request);
};
