import React from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';

const classes = '-striped -highlight';
const StandardTable = ({ columns, data, showPagination, ...rest }) => (
  <ReactTable
    className={classes}
    data={data}
    columns={columns}
    showPagination={showPagination}
    NoDataComponent={() => null}
    minRows={1}
    {...rest}
  />
);

StandardTable.defaultProps = {
  columns: [],
  data: [],
  showPagination: true
};

StandardTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  showPagination: PropTypes.bool
};

export default StandardTable;
