import { createSelector } from 'reselect';

const makeManageSsusSelector = () => {
  return createSelector(
    state => state.ssus || {},
    ssus => {
      return {
        ssus
      };
    }
  );
};

export default makeManageSsusSelector;
