import { ssuFilter } from '../components/User/UserRoleFormService';

export function getSsuList(ssus) {
  return ssuFilter(ssus);
}

// param ssus: dictionary for all SSUs indexed by ssuId.
// param ssuList: array of SSU IDs, with invalid ones ('canceled') filtered out.
// return {'pcn1':[{StudyReactDropdownObj1}, {StudyReactDropdownObj2}]}
export function getPcnStudyMap(ssus, ssuList, studies) {
  if (studies) {
    const filterStudies = studies.filter(study =>
      ssuList.some(ssuId => study.studyId === ssus[ssuId].studyId)
    );

    return filterStudies.reduce((accumulator, study) => {
      if (accumulator[study.pcn]) {
        accumulator[study.pcn].push({
          id: study.studyId,
          name: study.studyName,
          size: 'Medium'
        });
      } else {
        accumulator[study.pcn] = [
          {
            id: study.studyId,
            name: study.studyName,
            size: 'Medium'
          }
        ];
      }
      return accumulator;
    }, {});
  }
  return {};
}

export function getSiteStudyMap(ssuList, ssus) {
  return ssuList.reduce((acc, ssuId) => {
    const currSiteId = ssus[ssuId].siteId;
    const { studyId } = ssus[ssuId];
    if (acc[currSiteId]) {
      acc[currSiteId].push(studyId);
    } else {
      acc[currSiteId] = [studyId];
    }
    return acc;
  }, {});
}

export function getAllSites(ssuList, ssus) {
  return ssuList.reduce((acc, item) => {
    if (!acc.some(el => el.id === ssus[item].siteId)) {
      acc.push({
        name: ssus[item].siteName,
        id: ssus[item].siteId,
        size: 'Medium'
      });
    }
    return acc;
  }, []);
}

export function getAllStudies(ssuList, ssus) {
  return ssuList.reduce((acc, item) => {
    if (!acc.some(el => el.id === ssus[item].studyId)) {
      acc.push({
        name: ssus[item].studyName,
        id: ssus[item].studyId,
        size: 'Medium'
      });
    }
    return acc;
  }, []);
}

export function getAllPcns(ssuList, ssus, studies) {
  return ssuList.reduce((acc, item) => {
    if (!acc.some(el => el.id === ssus[item].studyId)) {
      const s =
        studies && studies.find(study => study.studyId === ssus[item].studyId);
      const pcn = s && s.pcn;
      if (pcn) {
        acc.push({
          name: pcn,
          id: ssus[item].studyId,
          size: 'Medium'
        });
      }
    }
    return acc;
  }, []);
}
