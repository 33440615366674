/*
 ManageStudies page `/manage-studies`
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withManageStudies from '../containers/withManageStudies';
import { CREATE_STUDY_MODAL, EDIT_STUDY_MODAL, mainDark } from '../constants';
import StandardTable from './gizmos/StandardTable';

class ManageStudies extends Component {
  constructor(props) {
    super(props);

    this.columnsManageStudies = [
      {
        Header: 'Study Name',
        accessor: 'studyName',
        Cell: row => (
          <button
            type="button"
            className="btn btn-sm btn-link"
            style={{ color: '#6395d6' }}
            onClick={() => this.openEditStudyModal(row.original)}
          >
            {row.original.studyName}
          </button>
        )
      },
      {
        Header: 'Therapeutic Area',
        accessor: 'therapeuticArea'
      },
      {
        Header: 'Protocol ID',
        accessor: 'protocolId'
      },
      {
        Header: 'Sponsor',
        accessor: 'sponsor'
      },
      {
        Header: 'CRO',
        accessor: 'cro'
      },
      {
        Header: 'Non-traditional',
        accessor: 'nonTraditional',
        Cell: ({ value }) => (
          <input
            className="form-control"
            type="checkbox"
            disabled
            checked={value}
          />
        )
      }
    ];

    this.textStyle = { fontSize: '2.5rem', fontWeight: 500, color: mainDark };

    this.headerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    };
  }

  componentDidMount() {
    const { fetchStudiesRequest } = this.props;
    fetchStudiesRequest();
  }

  openCreateStudyModal = () => {
    const { showModal } = this.props;
    showModal(CREATE_STUDY_MODAL);
  };

  openEditStudyModal = data => {
    const { showModal } = this.props;
    showModal(EDIT_STUDY_MODAL, data);
  };

  render() {
    const { studies } = this.props;
    return (
      <Fragment>
        <div style={this.headerStyle}>
          <h2 style={this.textStyle}>Studies</h2>
          <button
            type="button"
            className="btn"
            style={this.headerStyle}
            onClick={this.openCreateStudyModal}
          >
            <span
              className="oi oi-plus pr-2"
              style={{ height: '1rem', color: '#00b1dc' }}
            />
            <span className="c-w">Create Study</span>
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <StandardTable
              data={studies}
              columns={this.columnsManageStudies}
              pageSize={studies.length}
              showPagination={false}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ManageStudies.defaultProps = {
  studies: []
};

ManageStudies.propTypes = {
  fetchStudiesRequest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  studies: PropTypes.array
};

export default withManageStudies(ManageStudies);
