const SUFFIX_USER_POOL = '/pool';
const SUFFIX_USER_ADD = '/add/:uuid';
const SUFFIX_USER = '/:uuid';

const HOME = '/';
const ADMIN = '/admin';
const USERS_PAGINATED = '/users-paginated';
const USER_POOL = `/users${SUFFIX_USER_POOL}`; // '/users/pool'
const USER_ADD = `/users${SUFFIX_USER_ADD}`; // '/users/add/:uuid'
const USER = `/users${SUFFIX_USER}`; // '/users/:uuid'
const API_KEYS = '/api-keys';
const SITES = '/manage-sites';
const STUDIES = '/manage-studies';
const SSUS = '/manage-ssus';

const routes = {
  [HOME]: {
    title: 'Home'
  },
  [ADMIN]: {
    title: 'Admin'
  },
  [USERS_PAGINATED]: {
    title: 'Users Paginated'
  },
  [API_KEYS]: {
    title: 'API Keys'
  },
  [SITES]: {
    title: 'Manage Sites'
  },
  [STUDIES]: {
    title: 'Manage Studies'
  },
  [SSUS]: {
    title: 'Manage Ssus'
  }
};
export default routes;

export {
  SUFFIX_USER_POOL,
  SUFFIX_USER_ADD,
  SUFFIX_USER,
  HOME,
  ADMIN,
  USERS_PAGINATED,
  USER_POOL,
  USER_ADD,
  USER,
  API_KEYS,
  SITES,
  STUDIES,
  SSUS
};
