import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { SITES, STUDIES, SSUS } from '../../routes';
import './Dropdown.css';

class SetupDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { showMenu } = this.state;
    const { inactiveLinkStyle, activeLinkProps } = this.props;
    return (
      <div
        style={{
          display: 'inline-block',
          position: 'relative'
        }}
      >
        <button
          type="button"
          className="btn dropdown-toggle"
          onClick={this.showMenu}
          style={{ fontSize: '0.875rem', ...inactiveLinkStyle }}
        >
          Setup
        </button>

        {showMenu ? (
          <div
            className="dropdown-menu show"
            ref={element => {
              this.dropdownMenu = element;
            }}
            style={{
              margin: '0',
              backgroundColor: '#691e44',
              border: 0,
              borderRadius: 0,
              zIndex: '15'
            }}
          >
            <NavLink
              className="custom-dropdown-item"
              exact
              to={SITES}
              style={inactiveLinkStyle}
              activeStyle={activeLinkProps}
            >
              Site
            </NavLink>
            <NavLink
              className="custom-dropdown-item"
              exact
              to={STUDIES}
              style={inactiveLinkStyle}
              activeStyle={activeLinkProps}
            >
              Study
            </NavLink>
            <NavLink
              className="custom-dropdown-item"
              exact
              to={SSUS}
              style={inactiveLinkStyle}
              activeStyle={activeLinkProps}
            >
              Ssus
            </NavLink>
          </div>
        ) : null}
      </div>
    );
  }
}

SetupDropdown.defaultProps = {
  inactiveLinkStyle: {
    color: 'white',
    textDecoration: 'none',
    marginRight: '1.875rem',
    zIndex: '15',
    position: 'relative'
  },
  activeLinkProps: {
    color: 'pink',
    textDecoration: 'none',
    marginRight: '1.875rem'
  }
};

SetupDropdown.propTypes = {
  inactiveLinkStyle: PropTypes.object,
  activeLinkProps: PropTypes.object
};

export default SetupDropdown;
