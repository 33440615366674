import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

const fetchAddableUser = async userId => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/addables/${userId}`,
    init
  );
  return fetch(request);
};

export default fetchAddableUser;
