/*
  User page (`/user`) container HOC
*/
import React from 'react';
import { connect } from 'react-redux';
import makeUserSelector from '../selectors/user';
import {
  fetchUserListRequest,
  fetchUserRoleTxnsRequest,
  fetchUserRoleTxnsRolledUpRequest,
  putUserRequest,
  putUserEmailRequest,
  putUserEmailFromCognitoRequest
} from '../actions/users';
import { fetchSsusRequest } from '../actions/ssus';
import {
  postAddRoleRequest,
  postExpireAllRolesRequest,
  postExpireRoleRequest
} from '../actions/roles';
import {
  fetchCognitoUserRequest,
  postCognitoUserAddGroupRequest,
  postCognitoUserRemoveGroupRequest,
  postResetPasswordRequest,
  postResendInvitationRequest
} from '../actions/cognitoUsers';
import { fetchStudiesRequest } from '../actions/studies';
import { fetchSitesRequest } from '../actions/sites';

const withUser = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchCognitoUserRequest: id => dispatch(fetchCognitoUserRequest(id)),
    fetchUserListRequest: () => dispatch(fetchUserListRequest()),
    fetchUserRoleTxnsRequest: uuid => dispatch(fetchUserRoleTxnsRequest(uuid)),
    fetchSsusRequest: () => dispatch(fetchSsusRequest()),
    fetchStudiesRequest: () => dispatch(fetchStudiesRequest()),
    fetchSitesRequest: () => dispatch(fetchSitesRequest()),
    fetchUserRoleTxnsRolledUpRequest: uuid =>
      dispatch(fetchUserRoleTxnsRolledUpRequest(uuid)),
    putUserRequest: (id, payload) => dispatch(putUserRequest(id, payload)),
    putUserEmailRequest: (id, payload) =>
      dispatch(putUserEmailRequest(id, payload)),
    putUserEmailFromCognitoRequest: uuid =>
      dispatch(putUserEmailFromCognitoRequest(uuid)),
    postExpireRoleRequest: (id, payload) =>
      dispatch(postExpireRoleRequest(id, payload)),
    postExpireAllRolesRequest: (id, payload) =>
      dispatch(postExpireAllRolesRequest(id, payload)),
    postAddRoleRequest: (id, payload) =>
      dispatch(postAddRoleRequest(id, payload)),
    postResetPasswordRequest: id => dispatch(postResetPasswordRequest(id)),
    postResendInvitationRequest: id =>
      dispatch(postResendInvitationRequest(id)),
    postCognitoUserAddGroupRequest: (id, payload) =>
      dispatch(postCognitoUserAddGroupRequest(id, payload)),
    postCognitoUserRemoveGroupRequest: (id, payload) =>
      dispatch(postCognitoUserRemoveGroupRequest(id, payload))
  });

  return connect(
    makeUserSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withUser;
