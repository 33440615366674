import { fetchPaginatedUserList } from '../actions/types/users';
import { SUCCESS } from '../actions/types/utils';

const paginatedUsers = (state = [], action) => {
  switch (action.type) {
    case fetchPaginatedUserList[SUCCESS]:
      return (action.payload && action.payload.users) || [];
    default:
      return state;
  }
};

export default paginatedUsers;
