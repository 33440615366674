import { SHOW_MODAL, HIDE_MODAL } from '../actions/types/modals';

const initialState = {
  modalType: null,
  data: {}
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modalType: action.modalType,
        data: action.data
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modals;
