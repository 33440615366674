import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { createSiteRequest } from '../../../actions/sites';
import Modal from '../Modal';
import SiteModalForm from './SiteModalForm';
import siteModalValidator from '../validators/siteModalValidator';

class CreateSiteModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { className, createSiteRequestAction } = this.props;
    const initialValues = {
      siteName: '',
      status: 'Inactive',
      elligoPhone: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      state: 'AK',
      city: '',
      postalCode: '',
      country: 'United States'
    };

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Create New Site
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={siteModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async submitValues => {
            try {
              createSiteRequestAction(submitValues);
              this.onClose();
            } catch (err) {
              console.error(err);
              this.onClose();
            }
          }}
        >
          {({ submitForm, isSubmitting, values, setFieldValue }) => {
            return (
              <SiteModalForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                onClose={this.onClose}
                values={values}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

CreateSiteModal.defaultProps = {
  className: ''
};

CreateSiteModal.propTypes = {
  className: PropTypes.string,
  createSiteRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  createSiteRequestAction: payload => dispatch(createSiteRequest(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(CreateSiteModal);
