import { generateFetchTypes } from './utils';

export const fetchUsersList = generateFetchTypes('FETCH_USERS_LIST');
export const fetchUser = generateFetchTypes('FETCH_USER');
export const fetchUserRoleTxns = generateFetchTypes('FETCH_USER_ROLE_TXNS');
export const fetchUserSsus = generateFetchTypes('FETCH_USER_SSUS');
export const fetchUserRoleTxnsRolledUp = generateFetchTypes(
  'FETCH_USER_ROLE_TXNS_ROLLED_UP'
);
export const fetchUserPreviousRoles = generateFetchTypes(
  'FETCH_USER_PREVIOUS_ROLES'
);
export const fetchOrphanedUsers = generateFetchTypes('FETCH_ORPHANED_USERS');
export const fetchAddableUsers = generateFetchTypes('FETCH_ADDABLE_USERS');

export const putUser = generateFetchTypes('PATCH_USER');
export const postUser = generateFetchTypes('POST_USER');
export const putUserEmail = generateFetchTypes('PUT_USER_EMAIL');
export const putUserEmailFromCognito = generateFetchTypes(
  'PUT_USER_EMAIL_FROM_COGNITO'
);

export const fetchPaginatedUserList = generateFetchTypes(
  'FETCH_PAGINATED_USER_LIST'
);
