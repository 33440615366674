/*
  This selector file is for the admin page `/admin`
*/
import { createSelector } from 'reselect';

const usersSelector = state => state.users || {};

const apiKeysSelector = state => state.apiKeys || [];

const adminSelector = (users = {}, apiKeys = []) => {
  return {
    users: (users && Object.values(users)) || [],
    apiKeys
  };
};

const makeAdminSelector = () => {
  return createSelector(
    usersSelector,
    apiKeysSelector,
    adminSelector
  );
};

export default makeAdminSelector;
