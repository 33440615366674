import {
  fetchStudies,
  createStudy,
  updateStudy
} from '../actions/types/studies';
import { SUCCESS, FAILURE } from '../actions/types/utils';

const studies = (state = [], action) => {
  switch (action.type) {
    case fetchStudies[SUCCESS]:
      return action.payload;
    case fetchStudies[FAILURE]:
      return state;
    case createStudy[SUCCESS]:
      return [...state, action.payload];
    case createStudy[FAILURE]:
      return state;
    case updateStudy[SUCCESS]:
      return state.map(item => {
        if (item.studyId !== action.payload.studyId) {
          return item;
        }

        return {
          ...item,
          ...action.payload
        };
      });
    case updateStudy[FAILURE]:
      return state;
    default:
      return state;
  }
};

export default studies;
