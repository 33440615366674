import React from 'react';
import { Link } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const AddEditCell = ({ original }) => {
  const { providerId, userId } = original;
  let link = `/users/add/${providerId}`;
  let Icon = Add;
  if (userId) {
    link = `/users/${userId}`;
    Icon = Edit;
  }
  return (
    <Link to={link}>
      <Icon style={{ color: 'rgba(99, 149, 214, 1)' }} />
    </Link>
  );
};

AddEditCell.propTypes = { original: PropTypes.object };
AddEditCell.defaultProps = { original: {} };

const CreatedCell = ({ value }) => (
  <pre>
    {moment.tz(value, moment.tz.guess()).format('YYYY-MM-DD hh:mm:ss A zz')}
  </pre>
);
CreatedCell.propTypes = { value: PropTypes.string };
CreatedCell.defaultProps = { value: '' };

const RoleCell = ({ value }) => {
  let roles = [];
  if (value) {
    roles = Object.keys(value);
  }
  return (
    <div>
      <pre style={{ marginBottom: '0rem' }}>
        {JSON.stringify(roles, null, 0).replace(/"/g, '')}
      </pre>
    </div>
  );
};

RoleCell.propTypes = { value: PropTypes.object };
RoleCell.defaultProps = { value: {} };

const IDCell = ({ value }) => {
  if (value) {
    return <Link to={`/users/${value}`}>{`${value}`}</Link>;
  }
  return null;
};

IDCell.propTypes = { value: PropTypes.string };
IDCell.defaultProps = { value: '' };

const IDPUsersColumns = Object.freeze([
  {
    Header: '',
    sortable: false,
    Cell: AddEditCell,
    width: 42
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created',
    sortable: true,
    Cell: CreatedCell,
    style: { whitespace: 'unset' },
    width: 220
  },
  {
    id: 'family_name',
    Header: 'Last Name',
    accessor: 'lastName',
    sortable: true
  },
  {
    id: 'given_name',
    Header: 'First Name',
    accessor: 'firstName',
    sortable: true
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortable: true
  },
  {
    Header: 'Email Verified',
    accessor: 'emailVerified',
    width: 120,
    sortable: false
  },
  {
    Header: 'Provider Id',
    accessor: 'providerId',
    sortable: false
  },
  {
    accessor: 'title',
    Header: 'Title',
    sortable: false,
    minWidth: 42
  },
  {
    accessor: 'notes',
    Header: 'Notes',
    sortable: false,
    minWidth: 42
  },
  {
    Header: 'Department',
    accessor: 'department',
    sortable: false,
    minWidth: 56
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    sortable: false,
    width: 110
  },
  {
    id: 'role',
    Header: 'Role',
    accessor: 'role',
    sortable: false,
    Cell: RoleCell
  },
  {
    Header: 'ID',
    accessor: 'userId',
    sortable: false,
    Cell: IDCell
  }
]);

export default IDPUsersColumns;
