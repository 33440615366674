import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { roleNames } from '../../constants';
import { generateDateStr } from '../../selectors/utils';

const commonHeaders = [
  {
    Header: 'Site',
    accessor: 'siteName',
    minWidth: 120
  },
  {
    Header: 'Start Date',
    accessor: 'momentStartDate',
    Cell: ({ value }) =>
      (value === undefined && 'Undefined') ||
      (value === null && 'Null') ||
      generateDateStr(value),
    width: 100
  },
  {
    Header: 'Expire Date',
    accessor: 'momentExpireDate',
    Cell: ({ value }) =>
      (value === undefined && 'Undefined') ||
      (value === null && 'Null') ||
      generateDateStr(value),
    width: 100
  }
];

const Created = props => {
  const { original } = props;
  return (
    <Fragment>
      <div>{generateDateStr(original.momentCreatedOn)}</div>
      <Link to={`/users/${original.createdBy}`}>{original.createdByEmail}</Link>
    </Fragment>
  );
};

const getFinanceDetailsAccess = props => {
  const { original } = props;
  if (original.role === 'stuman') {
    return original.financeDetailsAccess === 'false' ? 'No' : 'Yes';
  }
  return '';
};

Created.propTypes = {
  original: PropTypes.object.isRequired
};

export const roleHistoryColumns = [
  {
    Header: '#',
    Cell: ({ index }) => index,
    width: 30
  },
  {
    Header: 'App',
    accessor: 'app'
  },
  {
    Header: 'Role',
    accessor: 'role',
    Cell: ({ value }) => value && roleNames[value],
    minWidth: 70
  },
  {
    Header: 'Study',
    accessor: 'studyName',
    minWidth: 120
  },
  ...commonHeaders,
  {
    Header: 'Blinding Group',
    accessor: 'blindingGroup',
    width: 150
  },
  {
    Header: 'Finance Details Access',
    accessor: 'financeDetailsAccess',
    Cell: getFinanceDetailsAccess,
    width: 150
  },
  {
    Header: 'Created',
    accessor: 'createdByEmail',
    Cell: Created
  }
];

export default { roleHistoryColumns };
