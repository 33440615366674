import { fetchApiKeys, postApiKey, deleteApiKey } from './types/apiKeys';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchApiKeysRequest = () => ({
  type: fetchApiKeys[REQUEST]
});

export const fetchApiKeysFailure = err => ({
  type: fetchApiKeys[FAILURE],
  msg: err
});

export const fetchApiKeysSuccess = payload => ({
  type: fetchApiKeys[SUCCESS],
  payload
});

export const postApiKeyRequest = payload => ({
  type: postApiKey[REQUEST],
  payload
});

export const postApiKeyFailure = err => ({
  type: postApiKey[FAILURE],
  msg: err
});

export const postApiKeySuccess = () => ({
  type: postApiKey[SUCCESS]
});

export const deleteApiKeyRequest = apiKeyId => ({
  type: deleteApiKey[REQUEST],
  apiKeyId
});

export const deleteApiKeyFailure = err => ({
  type: deleteApiKey[FAILURE],
  msg: err
});

export const deleteApiKeySuccess = apiKeyId => ({
  type: deleteApiKey[SUCCESS],
  apiKeyId
});
