import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const fetchCognitoUsers = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/user-pool`, init);
  return fetch(request);
};

export const fetchCognitoUser = async userId => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user-pool/${userId}`,
    init
  );
  return fetch(request);
};

export const postCognitoUser = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/create_user`, init);
  return fetch(request);
};

export const postResetPassword = async cognitoId => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user/forgetpassword/${cognitoId}`,
    init
  );
  return fetch(request);
};

export const postResendInvitation = async cognitoId => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user/resendinvitation/${cognitoId}`,
    init
  );
  return fetch(request);
};

export const postAddGroup = async (cognitoId, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user-pool/${cognitoId}/add-group`,
    init
  );
  return fetch(request);
};

export const postRemoveGroup = async (cognitoId, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user-pool/${cognitoId}/remove-group`,
    init
  );
  return fetch(request);
};
