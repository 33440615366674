import { SHOW_MODAL, HIDE_MODAL } from './types/modals';

export const showModal = (modalType, data = {}) => ({
  type: SHOW_MODAL,
  modalType,
  data
});

export const hideModal = () => ({
  type: HIDE_MODAL
});
