import fetchAddableUser from './types/addables';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchAddableUserRequest = userId => ({
  type: fetchAddableUser[REQUEST],
  userId
});

export const fetchAddableUserFailure = err => ({
  type: fetchAddableUser[FAILURE],
  msg: err
});

export const fetchAddableUserSuccess = payload => ({
  type: fetchAddableUser[SUCCESS],
  payload
});
