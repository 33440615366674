import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './modals.css';

class Modal extends Component {
  componentDidMount() {
    const { onClose } = this.props;
    if (onClose) {
      window.addEventListener('keydown', this.listenKeyboard.bind(this), true);
    }
  }

  componentWillUnmount() {
    const { onClose } = this.props;
    if (onClose) {
      window.removeEventListener(
        'keydown',
        this.listenKeyboard.bind(this),
        true
      );
    }
  }

  onOverlayClick() {
    const { onClose } = this.props;
    onClose();
  }

  listenKeyboard(event) {
    const { onClose } = this.props;
    if (event.key === 'Escape' || event.keyCode === 27) {
      onClose();
    }
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={className}>
        <div className="content">
          <div className="overlay" onClick={this.onOverlayClick.bind(this)} />
          <div className="dialog">{children}</div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  className: '',
  children: null
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Modal;
