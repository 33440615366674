import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StandardTable from '../gizmos/StandardTable';
import { roleHistoryColumns } from './diagnosticsUtil';

const Diagnostics = ({ role, transactions, rolledUpData }) => {
  return (
    <Fragment>
      <h2>Current Role Raw Data</h2>
      <pre>{JSON.stringify(role, null, 2)}</pre>

      <h2>Role History</h2>
      <StandardTable
        columns={roleHistoryColumns}
        data={transactions}
        showPagination={false}
        pageSize={transactions.length}
      />
      <br />

      <h2>Rolled Up Raw Data</h2>
      <pre>{JSON.stringify(rolledUpData, null, 2)}</pre>
    </Fragment>
  );
};

Diagnostics.propTypes = {
  role: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
  rolledUpData: PropTypes.object.isRequired
};

export default Diagnostics;
