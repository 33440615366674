import * as yup from 'yup';

const siteModalValidator = yup.object().shape({
  siteName: yup.string().required('You must enter a site name'),
  status: yup.string().required('You must enter a site status'),
  phone: yup.string().length(14, 'Please enter a valid US phone number'),
  elligoPhone: yup.string().length(14, 'Please enter a valid US phone number'),
  addressLine1: yup.string().required('You must enter an address'),
  addressLine2: yup.string(),
  country: yup.string(),
  state: yup.string().when('country', {
    is: 'Canada',
    then: yup.string().required('You must enter a Province'),
    otherwise: yup.string().required('You must enter a State')
  }),
  city: yup.string().required('You must enter a City'),
  postalCode: yup.string().when('country', {
    is: 'Canada',
    then: yup
      .string()
      .length(7, 'Postal code must be 6 characters')
      .required('You must enter a valid Postal code'),
    otherwise: yup
      .string()
      .test(
        'Verify Zip length',
        'Zip must be 5 or 9 characters',
        value => value && (value.length === 5 || value.length === 10)
      )
      .required('You must enter a valid Zip code')
  })
});

export default siteModalValidator;
