import { fetchApiKeys, deleteApiKey } from '../actions/types/apiKeys';
import { SUCCESS } from '../actions/types/utils';

const apiKeys = (state = [], action) => {
  switch (action.type) {
    case fetchApiKeys[SUCCESS]:
      return action.payload;
    case deleteApiKey[SUCCESS]:
      return state.filter(apiKey => apiKey._id !== action.apiKeyId);
    default:
      return state;
  }
};

export default apiKeys;
