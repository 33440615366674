import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const postExpireRole = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user/${uuid}/expire`,
    init
  );
  return fetch(request);
};

export const postExpireAllRoles = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user/${uuid}/expireall`,
    init
  );
  return fetch(request);
};

export const postAddRole = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/user/${uuid}/role/add`,
    init
  );
  return fetch(request);
};
