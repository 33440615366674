import React, { Fragment, useEffect } from 'react';
import 'react-super-select/lib/react-super-select.css';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash/collection';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import SingleSelect from '../gizmos/SingleSelect';
import {
  ssuRoles,
  otherRoles,
  roleNames,
  timeZones,
  marketplaceRoles,
  disabledRoles
} from '../../constants';
import './UserRoleFormTemplate.scss';
import { findSsu } from './UserRoleFormService';
import bouncerToken from '../../fetchers/bouncerToken';
import featureFlags from '../../fetchers/featureFlags';

export default function UserRoleFormTemplate({
  values,
  ssus,
  sitesDataSource,
  studiesDataSource,
  pcnsDataSource,
  sites,
  studies
}) {
  const formikProps = useFormikContext();
  const studyFound = studies.find(
    study => study.studyId === values.selectedStudyId
  );
  const zoneNameFromSite =
    values.selectedSiteId &&
    sites.find(site => site.siteId === values.selectedSiteId).timezone;
  const zoneAbbrFromSite =
    values.selectedSiteId &&
    moment
      .tz(
        Date.now(),
        sites.find(site => site.siteId === values.selectedSiteId).timezone
      )
      .zoneAbbr();
  const timeZoneObj =
    (values.roleTimeZone &&
      values.roleTimeZoneAbbr &&
      timeZones.find(
        zone =>
          zone.abbr === values.roleTimeZoneAbbr &&
          zone.id === values.roleTimeZone
      )) ||
    (values.selectedSiteId &&
      timeZones.find(
        zone =>
          zone.abbr === zoneAbbrFromSite && zone.googleName === zoneNameFromSite
      ));

  function getGroupTypeByRole(role) {
    if (!ssuRoles.includes(role)) {
      return '';
    }
    if (role === 'stuman') {
      return 'SM';
    }
    if (role === 'pi' || role === 'subi') {
      return 'Investigator';
    }
    return 'Other';
  }

  useEffect(() => {
    if (values.role && values.selectedStudyId) {
      const groups = studies
        .find(study => study.studyId === values.selectedStudyId)
        .groupAssigns.filter(
          group =>
            group.type === getGroupTypeByRole(values.role) ||
            group.type === 'Other'
        )
        .map(group => ({
          id: group.id,
          type: group.type,
          name: `${group.name} : ${group.type}`,
          size: 'Medium'
        }));
      formikProps.setFieldValue(
        'groups',
        sortBy(
          groups,
          ({ type }) => ({ SM: 1, Investigator: 2, Other: 3 }[type])
        )
      );

      const groupByRole = groups.filter(
        group => group.type === getGroupTypeByRole(values.role)
      );
      const groupAssignId = groupByRole.length === 1 ? groupByRole[0].id : '';
      formikProps.setFieldValue('groupAssignId', groupAssignId);
    }
  }, [values.role, values.selectedStudyId]);

  const { bouncerEnabled } = bouncerToken.getBouncerProps();

  const { studyMarketplaceRolesManagement } = featureFlags.getFeatureFlags();

  return (
    <Fragment>
      <div className="user-role-form-template">
        <div className="divider" />
        <div className="user-role-form-template-row">
          <div className="role-section">
            <h5>Role</h5>
            {otherRoles
              .filter(role => !disabledRoles.includes(role))
              .map(role => (
                <div>
                  <Field
                    id={`role-${role}`}
                    name="role"
                    type="radio"
                    value={role}
                  />
                  <label htmlFor={`role-${role}`}>{roleNames[role]}</label>
                </div>
              ))}
            <div className="divider" />
          </div>
        </div>
        <div className="user-role-form-template-row">
          <div className="role-section">
            {ssuRoles.map(role => (
              <div>
                <Field
                  id={`role-${role}`}
                  name="role"
                  type="radio"
                  value={role}
                />
                <label htmlFor={`role-${role}`}>{roleNames[role]}</label>
              </div>
            ))}
            {!!studyMarketplaceRolesManagement && <div className="divider" />}
            {!bouncerEnabled &&
              !!studyMarketplaceRolesManagement &&
              marketplaceRoles.map(role => (
                <div>
                  <Field
                    id={`role-${role}`}
                    name="role"
                    type="radio"
                    value={role}
                  />
                  <label htmlFor={`role-${role}`}>
                    Study Marketplace {roleNames[role]}
                  </label>
                </div>
              ))}
          </div>
          <div className="role-section-right">
            <div>
              <span className="study-section">
                <Field
                  type="text"
                  render={({ form }) => (
                    <SingleSelect
                      useNewStyle
                      label="Study"
                      placeholder="Select a Study"
                      initialValue={
                        (studiesDataSource.length === 1 &&
                          studiesDataSource[0]) ||
                        null
                      }
                      disabled={!ssuRoles.includes(values.role)}
                      dataSource={sortBy(studiesDataSource, elem =>
                        elem.name.toUpperCase()
                      )}
                      onChange={i =>
                        form.setFieldValue('selectedStudyId', (i && i.id) || '')
                      }
                      searchable
                      clearSearchOnSelection
                    />
                  )}
                />
              </span>
              <span className="pcn-section">
                <Field
                  type="text"
                  render={({ form }) => (
                    <SingleSelect
                      useNewStyle
                      label="PCN"
                      placeholder="Select a PCN"
                      disabled={!ssuRoles.includes(values.role)}
                      initialValue={
                        (values.selectedStudyId &&
                          studies &&
                          studyFound && {
                            id: studyFound.studyId,
                            name: studyFound.pcn,
                            size: 'Medium'
                          }) ||
                        null
                      }
                      dataSource={sortBy(pcnsDataSource, elem =>
                        elem.name.toUpperCase()
                      )}
                      onChange={i =>
                        form.setFieldValue('selectedPcn', (i && i.name) || '')
                      }
                      searchable
                      clearSearchOnSelection
                    />
                  )}
                />
              </span>
              <span className="site-section">
                <Field
                  type="text"
                  render={({ form }) => (
                    <SingleSelect
                      useNewStyle
                      label="Site"
                      placeholder="Select a Site"
                      disabled={!ssuRoles.includes(values.role)}
                      dataSource={sortBy(sitesDataSource, elem =>
                        elem.name.toUpperCase()
                      )}
                      onChange={i =>
                        form.setFieldValue('selectedSiteId', (i && i.id) || '')
                      }
                      searchable
                      clearSearchOnSelection
                    />
                  )}
                />
              </span>

              {ssuRoles.includes(values.role) &&
                values.selectedSiteId &&
                values.selectedStudyId &&
                ssus[
                  findSsu(ssus, values.selectedSiteId, values.selectedStudyId)
                ].isGroupAssign && (
                  <span className="blinding-groups-section">
                    <Field
                      id="groupAssignId"
                      name="groupAssignId"
                      type="text"
                      render={({ form }) => {
                        return (
                          <SingleSelect
                            useNewStyle
                            initialValue={values.groups.find(
                              group => group.id === values.groupAssignId
                            )}
                            label="Group"
                            placeholder="Select a Group"
                            dataSource={values.groups}
                            onChange={group => {
                              form.setFieldValue(
                                'groupAssignId',
                                (group && group.id) || ''
                              );
                            }}
                            searchable
                            clearSearchOnSelection
                          />
                        );
                      }}
                    />
                  </span>
                )}

              {ssuRoles.includes(values.role) &&
                values.selectedSiteId &&
                values.selectedStudyId &&
                ssus[
                  findSsu(ssus, values.selectedSiteId, values.selectedStudyId)
                ].studyBlinded &&
                ssus[
                  findSsu(ssus, values.selectedSiteId, values.selectedStudyId)
                ].blindingGroups.length > 0 && (
                  <span className="blinding-groups-section">
                    <Field
                      type="text"
                      render={({ form }) => (
                        <SingleSelect
                          useNewStyle
                          label="Blinding Group"
                          placeholder="Select a Blinding Group"
                          dataSource={ssus[
                            findSsu(
                              ssus,
                              values.selectedSiteId,
                              values.selectedStudyId
                            )
                          ].blindingGroups.map(blindingGroup => ({
                            id: `group-${blindingGroup}`,
                            name: blindingGroup,
                            size: 'Medium'
                          }))}
                          onChange={i =>
                            form.setFieldValue(
                              'blindingGroup',
                              (i && i.name) || ''
                            )
                          }
                          searchable
                          clearSearchOnSelection
                        />
                      )}
                    />
                  </span>
                )}

              {values.role === 'stuman' &&
                values.selectedSiteId &&
                values.selectedStudyId && (
                  <span className="finance-details-access-section">
                    <Field
                      name="financeDetailsAccess"
                      type="checkbox"
                      checked={values.financeDetailsAccess}
                    />
                    <label htmlFor="financeDetailsAccess">
                      Finance Details Access
                    </label>
                  </span>
                )}
            </div>
            <div style={{ marginLeft: '20px' }}>
              <span
                style={{
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                  width: '250px'
                }}
              >
                <div>
                  <Field
                    type="text"
                    render={({ form }) => (
                      <SingleSelect
                        useNewStyle
                        clearSearchOnSelection
                        label="Time Zone"
                        optionValueKey="unique"
                        initialValue={
                          (timeZoneObj && {
                            ...timeZoneObj,
                            size: 'Medium'
                          }) ||
                          null
                        }
                        disabled={
                          !values.roleStartDateEnable &&
                          !values.roleExpireDateEnable
                        }
                        placeholder="Select a Time Zone"
                        dataSource={timeZones.map(zone => ({
                          ...zone,
                          size: 'Medium'
                        }))}
                        onChange={i => {
                          form.setFieldValue(
                            'roleTimeZoneAbbr',
                            (i && i.abbr) || ''
                          );
                          form.setFieldValue('roleTimeZone', (i && i.id) || '');
                          form.setFieldValue(
                            'googleTimeZoneName',
                            (i && i.googleName) || ''
                          );
                        }}
                        searchable
                      />
                    )}
                  />
                </div>
              </span>
              <span style={{ display: 'inline-block', marginLeft: '4rem' }}>
                <div>
                  <Field
                    name="roleStartDateEnable"
                    type="checkbox"
                    checked={values.roleStartDateEnable}
                  />
                  <label htmlFor="enableStartDate">Start Date</label>
                </div>
                <div>
                  <Field
                    name="roleStartDate"
                    type="date"
                    disabled={!values.roleStartDateEnable}
                  />
                  <ErrorMessage name="startDate" component="div" />
                </div>
                <div>
                  <Field
                    name="roleStartTime"
                    type="time"
                    disabled={
                      !values.roleStartDateEnable || !values.roleStartDate
                    }
                  />
                </div>
              </span>

              <span style={{ display: 'inline-block', marginLeft: '8rem' }}>
                <div>
                  <Field
                    name="roleExpireDateEnable"
                    type="checkbox"
                    checked={values.roleExpireDateEnable}
                  />
                  <label htmlFor="enableExpireDate">Expire Date</label>
                </div>
                <div>
                  <Field
                    name="roleExpireDate"
                    type="date"
                    disabled={!values.roleExpireDateEnable}
                  />
                  <ErrorMessage name="expireDate" component="div" />
                </div>
                <div>
                  <Field
                    name="roleExpireTime"
                    type="time"
                    disabled={
                      !values.roleExpireDateEnable || !values.roleExpireDate
                    }
                  />
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
UserRoleFormTemplate.defaultProps = {
  ssus: {},
  sites: {},
  studies: {},
  studiesDataSource: [],
  pcnsDataSource: {},
  sitesDataSource: []
};

UserRoleFormTemplate.propTypes = {
  values: PropTypes.object.isRequired,
  ssus: PropTypes.object,
  sites: PropTypes.object,
  studies: PropTypes.object,
  pcnsDataSource: PropTypes.object,
  studiesDataSource: PropTypes.array,
  sitesDataSource: PropTypes.array
};
