import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

export default function ChangeSsuRoleModal(props) {
  const { hideModal } = props;
  return (
    <Modal onClose={hideModal}>
      <div className="modal-header mx-3 p-0 py-3" style={{ border: 'none' }}>
        <h5 className="modal-title " id="exampleModalLabel">
          User Role Already Defined for SSU
        </h5>
        <button
          type="button"
          className="close c-p"
          data-dismiss="modal"
          aria-label="Close"
          onClick={hideModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body text-left" style={{ padding: '45px' }}>
        <h4>Update User Role for SSU?</h4>
        <p>
          This user is either presently assigned or has previously been assigned
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          this Role for this SSU. If you continue, you will update this user's
          Role definition for this SSU which includes the Blinding Group. Do you
          want to continue?
        </p>
      </div>
      <div className="modal-footer text-left text-white ">
        <div className="row col-12 p-0 justify-content-between">
          <div className="col-12 pull-right" style={{ textAlign: 'right' }}>
            <button
              type="button"
              className="btn align-bottom border mr-2"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn text-white align-bottom bg-p"
              onClick={() => {
                props.data.onSubmitDialog();
                hideModal();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ChangeSsuRoleModal.propTypes = {
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSubmitDialog: PropTypes.func.isRequired
};
