import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { updateSiteRequest } from '../../../actions/sites';
import siteModalValidator from '../validators/siteModalValidator';
import Modal from '../Modal';
import SiteModalForm from './SiteModalForm';

class EditSiteModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { data, className, updateSiteRequestAction } = this.props;

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Edit Site
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={data}
          validationSchema={siteModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async ({ lat, lng, timezone, ...submitValues }) => {
            try {
              updateSiteRequestAction(submitValues);
              this.onClose();
            } catch (err) {
              console.error(err);
              this.onClose();
            }
          }}
        >
          {({ submitForm, isSubmitting, values, setFieldValue }) => {
            return (
              <SiteModalForm
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                onClose={this.onClose}
                values={values}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EditSiteModal.defaultProps = {
  data: {
    siteName: '',
    status: '',
    country: '',
    elligoPhone: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    city: '',
    postalCode: ''
  },
  className: ''
};

EditSiteModal.propTypes = {
  data: PropTypes.shape({
    siteName: PropTypes.string,
    status: PropTypes.string,
    country: PropTypes.string,
    elligoPhone: PropTypes.string,
    phone: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string
  }),
  className: PropTypes.string,
  updateSiteRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  updateSiteRequestAction: payload => dispatch(updateSiteRequest(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(EditSiteModal);
