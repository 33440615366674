import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form } from 'formik';
import DateTime from 'react-datetime';
import moment from 'moment';
import _ from 'lodash';
import { ssuStatuses } from '../../../constants';
import 'react-datetime/css/react-datetime.css';

const SsuModalForm = ({
  values,
  submitForm,
  isSubmitting,
  setFieldValue,
  clearDateButton,
  onClose,
  sites,
  studies,
  isCreate
}) => {
  let siteDropDown;
  let studyDropDown;
  if (!isCreate) {
    siteDropDown = <option value={values.siteId}>{values.siteName}</option>;
    studyDropDown = <option value={values.studyId}>{values.studyName}</option>;
  } else {
    siteDropDown =
      sites &&
      _.chain(sites)
        .sortBy('siteName')
        .map(site => <option value={site.siteId}>{site.siteName}</option>)
        .value();
    studyDropDown =
      studies &&
      _.chain(studies)
        .sortBy('studyName')
        .map(study => <option value={study.studyId}>{study.studyName}</option>)
        .value();
  }
  return (
    <Fragment>
      <div className="modal-body text-left">
        <Form className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="siteId">Site</label>
              <div className="col-md-12 row">
                <Field
                  id="siteId"
                  className="form-control req-field"
                  name="siteId"
                  component="select"
                  placeholder="Site"
                  value={values.siteId}
                  disabled={!isCreate}
                >
                  {siteDropDown}
                </Field>
                <ErrorMessage name="siteId" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="studyId">Study</label>
              <div className="col-md-12 row">
                <Field
                  id="studyId"
                  className="form-control req-field"
                  name="studyId"
                  component="select"
                  placeholder="Study"
                  value={values.studyId}
                  disabled={!isCreate}
                >
                  {studyDropDown}
                </Field>
                <ErrorMessage name="studyId" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="SiteName">Site Number</label>
              <div className="col-md-12 row">
                <Field
                  id="siteNumber"
                  className="form-control req-field"
                  name="siteNumber"
                  type="text"
                  placeholder="Site Number"
                />
                <ErrorMessage name="siteNumber" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <div className="col-md-12 row">
                <Field
                  id="status"
                  className="form-control req-field"
                  name="status"
                  component="select"
                  placeholder="Status"
                >
                  {ssuStatuses.map(status => (
                    <option value={status}>{status}</option>
                  ))}
                </Field>
                <ErrorMessage name="status" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="awardDate">Award Date</label>
              <div className="col-md-12 row">
                <DateTime
                  id="awardDate"
                  className="w-100"
                  name="awardDate"
                  dateFormat="DD/MMM/YYYY"
                  timeFormat={false}
                  onChange={e =>
                    setFieldValue('awardDate', moment(e).format('DD/MMM/YYYY'))
                  }
                  value={values.awardDate}
                  closeOnSelect
                  inputProps={{ readOnly: true }}
                />
                <button
                  type="button"
                  style={clearDateButton}
                  onClick={() => {
                    setFieldValue('awardDate', '');
                  }}
                >
                  <span className="oi oi-delete" />
                </button>
                <ErrorMessage name="awardDate" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="sponsorCtaDate">Sponsor CTA Date</label>
              <div className="col-md-12 row">
                <DateTime
                  id="sponsorCtaDate"
                  className="w-100"
                  name="sponsorCtaDate"
                  dateFormat="DD/MMM/YYYY"
                  timeFormat={false}
                  onChange={e =>
                    setFieldValue(
                      'sponsorCtaDate',
                      moment(e).format('DD/MMM/YYYY')
                    )
                  }
                  value={values.sponsorCtaDate}
                  closeOnSelect
                  inputProps={{ readOnly: true }}
                />
                <button
                  type="button"
                  style={clearDateButton}
                  onClick={() => {
                    setFieldValue('sponsorCtaDate', '');
                  }}
                >
                  <span className="oi oi-delete" />
                </button>
                <ErrorMessage name="sponsorCtaDate" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="siteWorkOrderDate">Site Work Order Date</label>
              <div className="col-md-12 row">
                <DateTime
                  id="siteWorkOrderDate"
                  className="w-100"
                  name="siteWorkOrderDate"
                  dateFormat="DD/MMM/YYYY"
                  timeFormat={false}
                  onChange={e =>
                    setFieldValue(
                      'siteWorkOrderDate',
                      moment(e).format('DD/MMM/YYYY')
                    )
                  }
                  value={values.siteWorkOrderDate}
                  closeOnSelect
                  inputProps={{ readOnly: true }}
                />
                <button
                  type="button"
                  style={clearDateButton}
                  onClick={() => {
                    setFieldValue('siteWorkOrderDate', '');
                  }}
                >
                  <span className="oi oi-delete" />
                </button>
                <ErrorMessage name="siteWorkOrderDate" />
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div className="modal-footer text-left text-white ">
        <div className="row col-12 p-0 justify-content-between">
          <div className="col-12 pull-right" style={{ textAlign: 'right' }}>
            <button
              type="button"
              className="btn text-white align-bottom bg-p mr-2"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Save
            </button>
            <button
              type="button"
              className="btn text-white align-bottom bg-p"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SsuModalForm.defaultProps = {
  sites: null,
  studies: null
};

SsuModalForm.propTypes = {
  values: PropTypes.array.isRequired,
  submitForm: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  clearDateButton: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  sites: PropTypes.array,
  studies: PropTypes.array,
  isCreate: PropTypes.bool.isRequired
};

export default SsuModalForm;
