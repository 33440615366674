/*
 ManageSsus page `/manage-ssus`
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withManageSsus from '../containers/withManageSsus';
import { CREATE_SSU_MODAL, EDIT_SSU_MODAL, mainDark } from '../constants';
import StandardTable from './gizmos/StandardTable';

class ManageSsus extends Component {
  constructor(props) {
    super(props);

    this.columnsManageSsus = [
      {
        Header: 'Study Name',
        accessor: 'studyName'
      },
      {
        Header: 'Site Name',
        accessor: 'siteName'
      },
      {
        Header: 'Site Number',
        accessor: 'siteNumber',
        Cell: row => (
          <button
            type="button"
            className="btn btn-sm btn-link"
            style={{ color: '#6395d6' }}
            onClick={() => this.openEditSsuModal(row.original)}
          >
            {row.original.siteNumber}
          </button>
        )
      },
      {
        Header: 'Sponsor CTA Date',
        accessor: 'sponsorCtaDate'
      }
    ];

    this.textStyle = { fontSize: '2.5rem', fontWeight: 500, color: mainDark };

    this.headerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    };
  }

  componentDidMount() {
    const {
      fetchSsusRequest,
      fetchSitesRequest,
      fetchStudiesRequest
    } = this.props;
    fetchSsusRequest();
    fetchSitesRequest();
    fetchStudiesRequest();
  }

  openCreateSsuModal = () => {
    const { showModal } = this.props;
    showModal(CREATE_SSU_MODAL);
  };

  openEditSsuModal = data => {
    const { showModal } = this.props;
    showModal(EDIT_SSU_MODAL, data);
  };

  render() {
    const { ssus } = this.props;
    const ssuArray = Object.values(ssus);
    return (
      <Fragment>
        <div style={this.headerStyle}>
          <h2 style={this.textStyle}>Ssus</h2>
          <button
            type="button"
            className="btn"
            style={this.headerStyle}
            onClick={this.openCreateSsuModal}
          >
            <span
              className="oi oi-plus pr-2"
              style={{ height: '1rem', color: '#00b1dc' }}
            />
            <span className="c-w">Create Ssu</span>
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <StandardTable
              data={ssuArray}
              columns={this.columnsManageSsus}
              pageSize={ssuArray.length}
              showPagination={false}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ManageSsus.defaultProps = {
  ssus: {}
};

ManageSsus.propTypes = {
  fetchSsusRequest: PropTypes.func.isRequired,
  fetchSitesRequest: PropTypes.func.isRequired,
  fetchStudiesRequest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  ssus: PropTypes.object
};

export default withManageSsus(ManageSsus);
