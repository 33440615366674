import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const ErrorMessageRender = msg => <div style={{ color: 'red' }}>{msg}</div>;

const CognitoAddUser = ({ postCognitoUserRequest }) => {
  const cognitoAddUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
  });

  const formStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem auto',
    gridTemplateRows: '2.7rem 2.7rem 2.7rem 2.7rem',
    gridRowGap: '1rem'
  };

  const leftColumnStyle = {
    gridColumn: '1 / 2',
    justifySelf: 'start',
    alignSelf: 'start'
  };

  const rightColumnStyle = {
    gridColumn: '2 / 3',
    alignSelf: 'start'
  };

  const inputErrorStyle = (touched, error) =>
    (touched && error && { border: '.0625rem solid red' }) || {};

  const fieldStyle = (touched, error) => ({
    ...rightColumnStyle,
    ...inputErrorStyle(touched, error)
  });

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: ''
      }}
      validationSchema={cognitoAddUserSchema}
      onSubmit={(values, { resetForm }) => {
        postCognitoUserRequest(values);
        resetForm();
      }}
    >
      {({ handleSubmit, errors, touched }) => (
        <Form style={formStyle} onSubmit={handleSubmit}>
          <label style={{ leftColumnStyle }} htmlFor="firstName">
            First Name
            <ErrorMessage name="firstName" render={ErrorMessageRender} />
          </label>
          <Field
            style={fieldStyle(touched.firstName, errors.firstName)}
            name="firstName"
            type="text"
          />
          <label style={leftColumnStyle} htmlFor="lastName">
            Last Name
            <ErrorMessage name="lastName" render={ErrorMessageRender} />
          </label>
          <Field
            style={fieldStyle(touched.lastName, errors.lastName)}
            name="lastName"
            type="text"
          />
          <label style={leftColumnStyle} htmlFor="email">
            Email
            <ErrorMessage name="email" render={ErrorMessageRender} />
          </label>
          <Field
            style={fieldStyle(touched.email, errors.email)}
            name="email"
            type="email"
          />
          <button style={{ ...rightColumnStyle, width: '5rem' }} type="submit">
            Create
          </button>
        </Form>
      )}
    </Formik>
  );
};

CognitoAddUser.defaultProps = {};

CognitoAddUser.propTypes = {
  postCognitoUserRequest: PropTypes.func.isRequired
};

export default CognitoAddUser;
