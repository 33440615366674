import { createSelector } from 'reselect';

const makeManageSitesSelector = () => {
  return createSelector(
    state => state.sites || [],
    sites => {
      return {
        sites
      };
    }
  );
};

export default makeManageSitesSelector;
