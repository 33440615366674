import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { isEqual } from 'lodash';
import { sortBy } from 'lodash/collection';
import { GROUP_TYPES } from '../../../constants';
import StandardTable from '../../gizmos/StandardTable';
import './StudyModalForm.scss';

export default function GroupSection() {
  const formikContext = useFormikContext();
  const { values, setFieldValue } = formikContext;
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: row => (
        <input
          maxLength={255}
          defaultValue={row.original.name}
          className="form-control"
          placeholder="Group Name"
          id="groupName"
          name="groupName"
          type="text"
          onChange={event => {
            values.groupAssigns.find(group =>
              isEqual(group, row.original)
            ).name = event.target.value;
          }}
        />
      )
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 180
    }
  ];

  const groupAssigns = sortBy(
    values.groupAssigns,
    ({ type }) => ({ SM: 1, Investigator: 2, Other: 3 }[type])
  );

  return (
    <>
      <div className="form-group">
        <div className="form-row add-group-section">
          <div className="col-5">
            <label htmlFor="newGroupName">Group Name</label>
            <Field
              className="form-control"
              id="newGroupName"
              name="newGroupName"
              type="text"
            />
          </div>
          <div className="col-4">
            <label htmlFor="newGroupType">Group Type</label>
            <Field
              className="form-control"
              placeholder="Select a group type"
              id="newGroupType"
              name="newGroupType"
              component="select"
            >
              {GROUP_TYPES.map(group => (
                <option value={group}>{group}</option>
              ))}
            </Field>
          </div>
          <div className="col-3">
            <button
              onClick={() => {
                setFieldValue('groupAssigns', [
                  ...values.groupAssigns,
                  {
                    name: values.newGroupName,
                    type: values.newGroupType,
                    isDefault: false
                  }
                ]);
              }}
              disabled={
                values.groupAssigns.some(
                  group =>
                    group.name === formikContext.values.newGroupName &&
                    group.type === formikContext.values.newGroupType
                ) ||
                !(
                  formikContext.values.newGroupName &&
                  formikContext.values.newGroupType
                ) ||
                formikContext.values.newGroupName.length > 30
              }
              className="btn text-white align-bottom bg-p"
              type="button"
            >
              Add Group
            </button>
          </div>
        </div>
      </div>
      <div className="form-group">
        <StandardTable
          data={groupAssigns}
          columns={columns}
          showPagination={false}
          sortable={false}
        />
        <ErrorMessage name="groupAssigns">
          {msg => (
            <div
              style={{
                'line-height': '16px',
                'margin-bottom': '-16px',
                'font-size': '12px',
                color: 'red'
              }}
            >
              {msg}
            </div>
          )}
        </ErrorMessage>
      </div>
    </>
  );
}
