import moment from 'moment-timezone';

export const generateMomentDate = (date, googleZoneName) =>
  (date && moment(date).tz(googleZoneName || 'UTC')) || null;

export const generateDateStr = momentDate =>
  (momentDate &&
    momentDate.isValid() &&
    momentDate.format('YYYY-MM-DD hh:mm A z')) ||
  null;
