import { fetchUserSsus } from '../actions/types/users';
import { fetchSsus, createSsu, updateSsu } from '../actions/types/ssus';
import { SUCCESS } from '../actions/types/utils';

const ssus = (state = {}, action) => {
  switch (action.type) {
    case fetchUserSsus[SUCCESS]:
      return {
        ...state,
        ...action.payload.reduce((ssuMap, ssu) => {
          Object.assign(ssuMap, { [ssu.ssuId]: ssu });
          return ssuMap;
        }, {})
      };
    case fetchSsus[SUCCESS]:
      return action.payload.reduce((ssuMap, ssu) => {
        Object.assign(ssuMap, { [ssu.ssuId]: ssu });
        return ssuMap;
      }, {});
    case createSsu[SUCCESS]:
      return {
        ...state,
        ...action.payload.reduce((ssuMap, ssu) => {
          console.log(ssu);
          Object.assign(ssuMap, { [ssu.ssuId]: ssu });
          console.log(ssuMap);
          return ssuMap;
        }, {})
      };
    case updateSsu[SUCCESS]:
      return {
        ...state,
        ...action.payload.reduce((ssuMap, ssu) => {
          Object.assign(ssuMap, { [ssu.ssuId]: ssu });
          return ssuMap;
        }, {})
      };
    default:
      return state;
  }
};

export default ssus;
