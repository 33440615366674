import { fetchUserRoleTxnsRolledUp } from '../actions/types/users';
import { SUCCESS } from '../actions/types/utils';

const previousRoles = (state = {}, action) => {
  if (action.type === fetchUserRoleTxnsRolledUp[SUCCESS]) {
    return { ...state, [action.uuid]: action.payload.previousRoles };
  }
  return state;
};

export default previousRoles;
