import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const errorStyle = {
  color: 'red'
};
const inputStyle = {
  borderRadius: '.5rem', // Adjust the radius as needed
  padding: '.5rem', // Optional: Add padding for better aesthetics
  border: '.0625rem solid #ccc', // Optional: Add a border for visual separation
  outline: 'none' // Optional: Remove default outline on focus
};
const buttonStyle = {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer'
};
const SearchBar = ({ onSubmit, placeholder, showError, error }) => {
  const [query, setQuery] = useState('');
  const [submitted, setSubmitted] = useState(true);
  const onChange = e => {
    const {
      target: { value }
    } = e;
    setQuery(value);
    setSubmitted(false);
  };
  const onKeyDown = e => {
    if (e.key === 'Enter') {
      onSubmit(query);
      setSubmitted(true);
    }
  };
  const onSearch = () => {
    onSubmit(query);
    setSubmitted(true);
  };
  const onClear = () => {
    setQuery('');
    onSubmit('');
    setSubmitted(true);
  };

  return (
    <Fragment>
      {showError && <span style={errorStyle}>{error}</span>}
      {(query || !submitted) && (
        <button type="button" onClick={onClear} style={buttonStyle}>
          <Clear />
        </button>
      )}
      <input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
        style={inputStyle}
      />
      <button type="button" onClick={onSearch} style={buttonStyle}>
        <SearchIcon />
      </button>
    </Fragment>
  );
};

SearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showError: PropTypes.bool,
  error: PropTypes.string
};

SearchBar.defaultProps = {
  placeholder: 'Search',
  showError: false,
  error: 'Error'
};

export default SearchBar;
