import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import bouncerToken from '../../fetchers/bouncerToken';
import { mainDark, mainBright } from '../../constants';
import { USERS_PAGINATED, ADMIN, USER_POOL, API_KEYS } from '../../routes';
import SetupDropdown from './SetupDropdown';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.navBarStyle = {
      display: 'table',
      height: '2.3125rem',
      width: '100%',
      backgroundColor: mainDark,
      borderTop: `0.4375rem solid ${mainBright}`
    };

    this.linkRowStyle = {
      display: 'table-cell',
      verticalAlign: 'middle',
      fontSize: '0.875rem',
      fontWeight: 400
    };

    this.inactiveLinkStyle = {
      color: 'white',
      textDecoration: 'none',
      marginRight: '1.875rem',
      zIndex: '15',
      position: 'relative'
    };
    this.activeLinkProps = { ...this.inactiveLinkStyle, color: 'pink' };
  }

  render() {
    const { bouncerEnabled } = bouncerToken.getBouncerProps();
    return (
      <section id="nav-bar" style={this.navBarStyle}>
        <div id="nav-links" style={this.linkRowStyle}>
          <span style={{ marginLeft: '1rem' }} />
          <NavLink
            exact
            to={ADMIN}
            style={this.inactiveLinkStyle}
            activeStyle={this.activeLinkProps}
          >
            Admins
          </NavLink>
          {bouncerEnabled && (
            <NavLink
              exact
              to={USERS_PAGINATED}
              style={this.inactiveLinkStyle}
              activeStyle={this.activeLinkProps}
            >
              Users
            </NavLink>
          )}
          <NavLink
            exact
            to={USER_POOL}
            style={this.inactiveLinkStyle}
            activeStyle={this.activeLinkProps}
          >
            User Pool
          </NavLink>
          <NavLink
            exact
            to={API_KEYS}
            style={this.inactiveLinkStyle}
            activeStyle={this.activeLinkProps}
          >
            API Keys
          </NavLink>
          <SetupDropdown
            style={this.inactiveLinkStyle}
            activeStyle={this.activeLinkProps}
          />
        </div>
      </section>
    );
  }
}

export default NavBar;
