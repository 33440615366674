import {
  fetchUsersList,
  fetchUser,
  fetchUserRoleTxns,
  fetchUserSsus,
  fetchUserRoleTxnsRolledUp,
  putUser,
  postUser,
  fetchUserPreviousRoles,
  fetchOrphanedUsers,
  fetchAddableUsers,
  putUserEmail,
  putUserEmailFromCognito,
  fetchPaginatedUserList
} from './types/users';

import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchUserListRequest = () => ({
  type: fetchUsersList[REQUEST]
});

export const fetchUsersListSuccess = payload => ({
  type: fetchUsersList[SUCCESS],
  payload
});

export const fetchUsersListFailure = err => ({
  type: fetchUsersList[FAILURE],
  msg: err
});

export const fetchUserRequest = uuid => ({
  type: fetchUser[REQUEST],
  uuid
});

export const fetchUserSuccess = payload => ({
  type: fetchUser[SUCCESS],
  payload
});

export const fetchUserFailure = err => ({
  type: fetchUser[FAILURE],
  msg: err
});

export const fetchUserRoleTxnsRequest = uuid => ({
  type: fetchUserRoleTxns[REQUEST],
  uuid
});

export const fetchUserRoleTxnsFailure = err => ({
  type: fetchUserRoleTxns[FAILURE],
  msg: err
});

export const fetchUserRoleTxnsSuccess = (payload, uuid) => ({
  type: fetchUserRoleTxns[SUCCESS],
  payload,
  uuid
});

export const fetchUserSsusRequest = uuid => ({
  type: fetchUserSsus[REQUEST],
  uuid
});

export const fetchUserSsusSuccess = payload => ({
  type: fetchUserSsus[SUCCESS],
  payload
});

export const fetchUserRoleTxnsRolledUpRequest = uuid => ({
  type: fetchUserRoleTxnsRolledUp[REQUEST],
  uuid
});

export const fetchUserRoleTxnsRolledUpFailure = err => ({
  type: fetchUserRoleTxnsRolledUp[FAILURE],
  msg: err
});

export const fetchUserRoleTxnsRolledUpSuccess = (payload, uuid) => ({
  type: fetchUserRoleTxnsRolledUp[SUCCESS],
  payload,
  uuid
});

export const fetchUserPreviousRolesRequest = uuid => ({
  type: fetchUserPreviousRoles[REQUEST],
  uuid
});

export const fetchUserPreviousRolesFailure = err => ({
  type: fetchUserPreviousRoles[FAILURE],
  msg: err
});

export const fetchUserPreviousRolesSuccess = (uuid, payload) => ({
  type: fetchUserPreviousRoles[SUCCESS],
  uuid,
  payload
});

export const putUserRequest = (uuid, payload) => ({
  type: putUser[REQUEST],
  payload,
  uuid
});

export const putUserFailure = err => ({
  type: putUser[FAILURE],
  msg: err
});

export const putUserSuccess = (uuid, payload) => ({
  type: putUser[SUCCESS],
  uuid,
  payload
});

export const postUserRequest = (uuid, payload, history) => ({
  type: postUser[REQUEST],
  uuid,
  payload,
  history
});

export const postUserFailure = err => ({
  type: postUser[FAILURE],
  msg: err
});

export const postUserSuccess = () => ({
  type: postUser[SUCCESS]
});

export const fetchOrphanedUsersRequest = () => ({
  type: fetchOrphanedUsers[REQUEST]
});

export const fetchOrphanedUsersFailure = err => ({
  type: fetchOrphanedUsers[FAILURE],
  msg: err
});

export const fetchOrphanedUsersSuccess = payload => ({
  type: fetchOrphanedUsers[SUCCESS],
  payload
});

export const fetchAddableUsersRequest = () => ({
  type: fetchAddableUsers[REQUEST]
});

export const fetchAddableUsersFailure = err => ({
  type: fetchAddableUsers[FAILURE],
  msg: err
});

export const fetchAddableUsersSuccess = payload => ({
  type: fetchAddableUsers[SUCCESS],
  payload
});

export const putUserEmailRequest = (uuid, payload) => ({
  type: putUserEmail[REQUEST],
  uuid,
  payload
});

export const putUserEmailFailure = err => ({
  type: putUserEmail[FAILURE],
  msg: err
});

export const putUserEmailSuccess = (uuid, payload) => ({
  type: putUserEmail[SUCCESS],
  uuid,
  payload
});

export const putUserEmailFromCognitoRequest = uuid => ({
  type: putUserEmailFromCognito[REQUEST],
  uuid
});

export const putUserEmailFromCognitoFailure = err => ({
  type: putUserEmailFromCognito[FAILURE],
  msg: err
});

export const putUserEmailFromCognitoSuccess = uuid => ({
  type: putUserEmailFromCognito[SUCCESS],
  uuid
});

export const fetchPaginatedUserListRequest = (
  page,
  limit,
  sort,
  descAsc,
  search
) => ({
  type: fetchPaginatedUserList[REQUEST],
  page,
  limit,
  sort,
  descAsc,
  search
});

export const fetchPaginatedUserListFailure = err => ({
  type: fetchPaginatedUserList[FAILURE],
  msg: err
});

export const fetchPaginatedUserListSuccess = payload => ({
  type: fetchPaginatedUserList[SUCCESS],
  payload
});
