import {
  fetchCognitoUser,
  fetchCognitoUsers,
  postCognitoUserAddGroup,
  postCognitoUserRemoveGroup
} from '../actions/types/cognitoUsers';
import { SUCCESS } from '../actions/types/utils';

const cognitoUsers = (state = {}, action) => {
  switch (action.type) {
    case fetchCognitoUsers[SUCCESS]:
      return action.payload.reduce((accum, curr) => {
        Object.assign(accum, { [curr.username]: curr });
        return accum;
      }, {});
    case fetchCognitoUser[SUCCESS]:
      return { ...state, [action.payload.username]: action.payload };
    case postCognitoUserAddGroup[SUCCESS]:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          groups: [...state[action.userId].groups, action.payload.groupName]
        }
      };
    case postCognitoUserRemoveGroup[SUCCESS]:
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          groups: state[action.userId].groups.filter(
            g => g !== action.payload.groupName
          )
        }
      };
    default:
      return state;
  }
};

export default cognitoUsers;
