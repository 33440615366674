/*
  ApiKeys page `/api-keys`
*/
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import withApiKeys from '../containers/withApiKeys';
import { mainDark } from '../constants';
import StandardTable from './gizmos/StandardTable';

// This might be overkill
const Delete = ({ deleteApiKeyRequest, apiKeyId }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{ apiKeyId }}
      onSubmit={values => {
        deleteApiKeyRequest(values.apiKeyId);
      }}
    >
      {() => (
        <Form>
          <button type="submit">Delete</button>
        </Form>
      )}
    </Formik>
  );
};

Delete.propTypes = {
  deleteApiKeyRequest: PropTypes.func.isRequired,
  apiKeyId: PropTypes.string.isRequired
};

const Add = ({ postApiKeyRequest }) => {
  return (
    <Formik
      initialValues={{ desc: '' }}
      onSubmit={(values, actions) => {
        postApiKeyRequest({ desc: values.desc });
        actions.resetForm();
      }}
    >
      {() => (
        <Form>
          <Field name="desc" type="text" placeholder="Enter key description" />
          <button type="submit">Submit</button>
        </Form>
      )}
    </Formik>
  );
};

Add.propTypes = {
  postApiKeyRequest: PropTypes.func.isRequired
};

class ApiKeys extends Component {
  constructor(props) {
    super(props);
    this.columnsApiKeys = [
      {
        Header: 'Key',
        accessor: 'key'
      },
      {
        Header: 'Description',
        accessor: 'desc'
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => {
          if (!value) {
            return '';
          }
          const date = value && new Date(value);
          return date && date.toLocaleString();
        }
      },
      {
        Header: 'Delete',
        accessor: '_id',
        Cell: ({ value }) => (
          <Delete
            deleteApiKeyRequest={props.deleteApiKeyRequest}
            apiKeyId={value}
          />
        )
      }
    ];
    this.textStyle = { fontSize: '2.5rem', fontWeight: 500, color: mainDark };
  }

  componentDidMount() {
    const { fetchApiKeysRequest } = this.props;
    fetchApiKeysRequest();
  }

  render() {
    const { apiKeys, postApiKeyRequest } = this.props;
    return (
      <Fragment>
        <h1 style={this.textStyle}>API Keys</h1>
        <StandardTable
          data={apiKeys}
          columns={this.columnsApiKeys}
          pageSize={apiKeys.length}
          showPagination={false}
        />

        <h2>Add API Key</h2>
        <Add postApiKeyRequest={postApiKeyRequest} />
      </Fragment>
    );
  }
}

ApiKeys.defaultProps = {
  apiKeys: []
};

ApiKeys.propTypes = {
  apiKeys: PropTypes.array,
  fetchApiKeysRequest: PropTypes.func.isRequired,
  deleteApiKeyRequest: PropTypes.func.isRequired,
  postApiKeyRequest: PropTypes.func.isRequired
};

export default withApiKeys(ApiKeys);
