/*
 ManageSites page `/manage-sites`
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withManageSites from '../containers/withManageSites';
import { CREATE_SITE_MODAL, EDIT_SITE_MODAL, mainDark } from '../constants';
import StandardTable from './gizmos/StandardTable';

class ManageSites extends Component {
  constructor(props) {
    super(props);

    this.columnsManageSites = [
      {
        Header: 'Site Name',
        accessor: 'siteName',
        Cell: row => (
          <button
            type="button"
            className="btn btn-sm btn-link"
            style={{ color: '#6395d6' }}
            onClick={() => this.openEditSiteModal(row.original)}
          >
            {row.original.siteName}
          </button>
        )
      },
      {
        Header: 'Address 1',
        accessor: 'addressLine1'
      },
      {
        Header: 'City',
        accessor: 'city'
      },
      {
        Header: 'State',
        accessor: 'state'
      },
      {
        Header: 'Country',
        accessor: 'country'
      }
    ];

    this.textStyle = { fontSize: '2.5rem', fontWeight: 500, color: mainDark };

    this.headerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    };
  }

  componentDidMount() {
    const { fetchSitesRequest } = this.props;
    fetchSitesRequest();
  }

  openCreateSiteModal = () => {
    const { showModal } = this.props;
    showModal(CREATE_SITE_MODAL);
  };

  openEditSiteModal = data => {
    const { showModal } = this.props;
    showModal(EDIT_SITE_MODAL, data);
  };

  render() {
    const { sites } = this.props;
    return (
      <Fragment>
        <div style={this.headerStyle}>
          <h2 style={this.textStyle}>Sites</h2>
          <button
            type="button"
            className="btn"
            style={this.headerStyle}
            onClick={this.openCreateSiteModal}
          >
            <span
              className="oi oi-plus pr-2"
              style={{ height: '1rem', color: '#00b1dc' }}
            />
            <span className="c-w">Create Site</span>
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <StandardTable
              data={sites}
              columns={this.columnsManageSites}
              pageSize={sites.length}
              showPagination={false}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

ManageSites.defaultProps = {
  sites: []
};

ManageSites.propTypes = {
  fetchSitesRequest: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  sites: PropTypes.array
};

export default withManageSites(ManageSites);
