import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Card extends Component {
  constructor(props) {
    super(props);

    this.sectionStyle = {
      width: '100%',
      maxWidth: '84.5rem'
    };

    this.titleStyle = {
      height: '3.875rem',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '0.5rem 0.5rem 0 0',
      backgroundColor: 'rgba(0,0,0,.03)',
      border: '.0625rem solid lightgray',
      padding: '0 1.25rem'
    };
    this.buttonStyle = {
      height: '2.375rem',
      width: '7.5rem',
      backgroundColor: 'rgba(90, 98, 104, 1)',
      color: 'rgba(255,255,255,1)',
      border: '.0625rem solid lightgray',
      borderRadius: '0%',
      cursor: 'pointer',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      textAlign: 'left'
    };
    this.contentStyle = {
      borderRadius: '0 0 0.5rem 0.5rem',
      border: '.0625rem solid lightgray',
      padding: '1.25rem'
    };

    this.state = {
      hideContent: props.initialHide
    };
    this.toggleShowContent = this.toggleShowContent.bind(this);
  }

  toggleShowContent() {
    this.setState(prevState => ({ hideContent: !prevState.hideContent }));
  }

  render() {
    const { hideContent } = this.state;
    const { children, titleName } = this.props;
    const hidden = (hideContent && { display: 'none' }) || {};
    const showHideContentStyle = { ...this.contentStyle, ...hidden };
    const borderRadius =
      (hideContent && {
        borderRadius: '0.5rem 0.5rem 0.5rem 0.5rem'
      }) ||
      {};
    return (
      <section style={this.sectionStyle}>
        <div style={{ ...this.titleStyle, ...borderRadius }}>
          <button
            type="button"
            style={this.buttonStyle}
            onClick={this.toggleShowContent}
          >
            {titleName}
          </button>
        </div>
        <div style={showHideContentStyle}>{children}</div>
      </section>
    );
  }
}

Card.defaultProps = {
  titleName: '',
  initialHide: true,
  children: null
};

Card.propTypes = {
  titleName: PropTypes.string,
  initialHide: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Card;
