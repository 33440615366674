import {
  fetchUsersList,
  fetchUser,
  putUser,
  putUserEmail
} from '../actions/types/users';
import { SUCCESS } from '../actions/types/utils';

const users = (state = {}, action) => {
  switch (action.type) {
    case fetchUsersList[SUCCESS]:
      return action.payload.reduce((usrMap, usr) => {
        Object.assign(usrMap, { [usr.userId]: usr });
        return usrMap;
      }, {});
    case fetchUser[SUCCESS]:
      return { ...state, [action.payload.userId]: action.payload };
    case putUser[SUCCESS]:
      return {
        ...state,
        [action.uuid]: { ...state[action.uuid], ...action.payload }
      };
    case putUserEmail[SUCCESS]:
      return {
        ...state,
        [action.uuid]: { ...state[action.uuid], ...action.payload }
      };
    default:
      return state;
  }
};

export default users;
