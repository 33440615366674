import { fetchSsus, createSsu, updateSsu } from './types/ssus';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchSsusRequest = () => ({
  type: fetchSsus[REQUEST]
});

export const fetchSsusSuccess = payload => ({
  type: fetchSsus[SUCCESS],
  payload
});

export const fetchSsusFailure = err => ({
  type: fetchSsus[FAILURE],
  msg: err
});

export const createSsuRequest = payload => ({
  type: createSsu[REQUEST],
  payload
});

export const createSsuSuccess = payload => ({
  type: createSsu[SUCCESS],
  payload
});

export const createSsuFailure = err => ({
  type: createSsu[FAILURE],
  msg: err
});

export const updateSsuRequest = payload => ({
  type: updateSsu[REQUEST],
  payload
});

export const updateSsuSuccess = payload => ({
  type: updateSsu[SUCCESS],
  payload
});

export const updateSsuFailure = err => ({
  type: updateSsu[FAILURE],
  msg: err
});
