import { postExpireRole, postExpireAllRoles, postAddRole } from './types/roles';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const postExpireRoleRequest = (uuid, payload) => ({
  type: postExpireRole[REQUEST],
  payload,
  uuid
});

export const postExpireRoleFailure = err => ({
  type: postExpireRole[FAILURE],
  msg: err
});

export const postExpireRoleSuccess = uuid => ({
  type: postExpireRole[SUCCESS],
  uuid
});

export const postExpireAllRolesRequest = (uuid, payload) => ({
  type: postExpireAllRoles[REQUEST],
  payload,
  uuid
});

export const postExpireAllRolesFailure = err => ({
  type: postExpireAllRoles[FAILURE],
  msg: err
});

export const postExpireAllRolesSuccess = uuid => ({
  type: postExpireAllRoles[SUCCESS],
  uuid
});

export const postAddRoleRequest = (uuid, payload) => ({
  type: postAddRole[REQUEST],
  uuid,
  payload
});

export const postAddRoleFailure = err => ({
  type: postAddRole[FAILURE],
  msg: err
});

export const postAddRoleSuccess = uuid => ({
  type: postAddRole[SUCCESS],
  uuid
});
