import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { updateSsuRequest } from '../../../actions/ssus';
import ssuModalValidator from '../validators/ssuModalValidator';
import Modal from '../Modal';
import SsuModalForm from './SsuModalForm';

class EditSsuModal extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { hideModal } = this.props;
    if (hideModal) {
      hideModal();
    }
  }

  render() {
    const { data, className, updateSsuRequestAction } = this.props;
    const clearDateButton = {
      backgroundColor: 'transparent',
      position: 'absolute',
      right: '15px',
      border: 'none',
      height: '34px',
      paddingTop: '4px'
    };

    return (
      <Modal onClose={this.onClose} className={className}>
        <div className="modal-header border-bottom-p mx-3 p-0 py-3">
          <h5 className="modal-title c-p" id="exampleModalLabel">
            Edit Ssu
          </h5>
          <button
            type="button"
            className="close c-p"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          initialValues={data}
          validationSchema={ssuModalValidator}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitValues => {
            updateSsuRequestAction({
              ...submitValues,
              ssuId: data.ssuId, // eslint-disable-line react/prop-types
              siteId: data.siteId,
              studyId: data.studyId
            });
            this.onClose();
          }}
        >
          {({ values, submitForm, isSubmitting, setFieldValue }) => {
            return (
              <SsuModalForm
                values={values}
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                clearDateButton={clearDateButton}
                onClose={this.onClose}
                sites={data.sites} // eslint-disable-line react/prop-types
                studies={data.studies} // eslint-disable-line react/prop-types
                isCreate={false}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EditSsuModal.defaultProps = {
  className: ''
};

EditSsuModal.propTypes = {
  data: PropTypes.shape({
    sponsorCtaDate: PropTypes.string,
    awardDate: PropTypes.string,
    siteWorkOrderDate: PropTypes.string,
    siteNumber: PropTypes.string,
    status: PropTypes.string.isRequired,
    siteId: PropTypes.string.isRequired,
    studyId: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
  updateSsuRequestAction: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  updateSsuRequestAction: payload => dispatch(updateSsuRequest(payload))
});

export default connect(
  null,
  mapDispatchToProps
)(EditSsuModal);
