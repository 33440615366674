/*
  HOC container for users page `/users`
*/
import React from 'react';
import { connect } from 'react-redux';
import makePaginatedUsersSelector from '../selectors/paginatedUsers';
import { fetchPaginatedUserListRequest } from '../actions/users';

const withIDPUsers = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchPaginatedUserListRequest: (page, limit, sort, descAsc, search) =>
      dispatch(
        fetchPaginatedUserListRequest(page, limit, sort, descAsc, search)
      )
  });

  return connect(
    makePaginatedUsersSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withIDPUsers;
