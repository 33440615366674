/*
  ApiKeys (`/apikeys`) container HOC
*/
import React from 'react';
import { connect } from 'react-redux';

import makeApiKeysSelector from '../selectors/apiKeys';
import {
  deleteApiKeyRequest,
  fetchApiKeysRequest,
  postApiKeyRequest
} from '../actions/apiKeys';

const withApiKeys = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    fetchApiKeysRequest: () => dispatch(fetchApiKeysRequest()),
    deleteApiKeyRequest: key => dispatch(deleteApiKeyRequest(key)),
    postApiKeyRequest: payload => dispatch(postApiKeyRequest(payload))
  });

  return connect(
    makeApiKeysSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withApiKeys;
