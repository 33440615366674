import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { titleOptions } from '../../constants';
import bouncerToken from '../../fetchers/bouncerToken';
import Auth0Info from './Auth0Info';
import {
  leftColumnStyle,
  readOnlyStyle,
  rightColumnStyle
} from '../commonStyles';

const UserInfoForm = ({
  putUserRequest,
  putUserEmailRequest,
  userId,
  lastName,
  firstName,
  email,
  cognitoGroups,
  title,
  department,
  startDate,
  jobTitle,
  credentials,
  notes,
  studyMarketplaceRole,
  identityProviderUser
}) => {
  const formStyle = {
    display: 'grid',
    gridTemplateColumns: '10rem auto',
    gridRowGap: '1.25rem'
  };

  const emailFieldStyle = {
    height: '1.6875rem',
    alignSelf: 'start'
  };

  const updateEmailButtonStyle = {
    marginRight: '0.25rem',
    width: '4rem',
    alignSelf: 'flex-start'
  };

  const { bouncerEnabled } = bouncerToken.getBouncerProps();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        userId,
        lastName,
        firstName,
        email,
        identityProviderUser,
        cognitoGroups,
        title,
        department,
        startDate,
        jobTitle,
        credentials,
        notes,
        editEmail: false
      }}
      onSubmit={(values, { resetForm }) => {
        const {
          userId: fUserId,
          lastName: fLastName,
          firstName: fFirstName,
          email: fEmail,
          title: fTitle,
          department: fDepartment,
          startDate: fStartDate,
          editEmail: fEditEmail,
          jobTitle: fJobTitle,
          credentials: fCredentials,
          notes: fNotes
        } = values;

        const nameChanged = firstName !== fFirstName || lastName !== fLastName;

        if (fEditEmail) {
          putUserEmailRequest(fUserId, { email: fEmail });
          resetForm({ values: { email } });
        } else if (
          !fLastName ||
          !fLastName.trim() ||
          !fFirstName ||
          !fFirstName.trim()
        ) {
          // Trying to update a user without a string in lastName or firstName
          toast.error('Missing required fields');
        } else {
          putUserRequest(fUserId, {
            lastName: fLastName,
            firstName: fFirstName,
            title: fTitle,
            department: fDepartment,
            startDate: fStartDate,
            jobTitle: fJobTitle,
            credentials: fCredentials,
            notes: fNotes,
            nameChanged
          });
          resetForm();
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form style={formStyle} onSubmit={handleSubmit}>
          <label htmlFor="userId" style={leftColumnStyle}>
            User ID
          </label>
          <Field name="userId" type="text" style={readOnlyStyle} readOnly />
          <Auth0Info auth0User={identityProviderUser} />
          <ErrorMessage name="identitiesString" component="div" />

          <label htmlFor="lastName" style={leftColumnStyle}>
            Last Name
          </label>
          <Field
            name="lastName"
            type="text"
            style={rightColumnStyle}
            className="req-field"
          />
          <ErrorMessage name="lastName" component="div" />

          <label htmlFor="firstName" style={leftColumnStyle}>
            First Name
          </label>
          <Field
            name="firstName"
            type="text"
            style={rightColumnStyle}
            className="req-field"
          />
          <ErrorMessage name="firstName" component="div" />

          <label htmlFor="email" style={leftColumnStyle}>
            Email
            <div>
              {(!values.editEmail && (
                <button
                  type="button"
                  style={updateEmailButtonStyle}
                  onClick={() => setFieldValue('editEmail', true)}
                >
                  Edit
                </button>
              )) || (
                <Fragment>
                  <button
                    type="button"
                    style={updateEmailButtonStyle}
                    onClick={() => {
                      setFieldValue('editEmail', false);
                      setFieldValue('email', email);
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" style={updateEmailButtonStyle}>
                    Update
                  </button>
                </Fragment>
              )}
            </div>
          </label>
          <Field
            name="email"
            type="email"
            style={(!values.editEmail && readOnlyStyle) || emailFieldStyle}
            readOnly={!values.editEmail}
          />
          <ErrorMessage name="email" component="div" />

          {!bouncerEnabled && (
            <label htmlFor="cognitoGroups" type="text" style={leftColumnStyle}>
              Cognito Groups
            </label>
          )}
          {!bouncerEnabled && (
            <Field
              name="cognitoGroups"
              type="text"
              style={readOnlyStyle}
              readOnly
            />
          )}
          <label
            htmlFor="studyMarketplaceRole"
            type="text"
            style={leftColumnStyle}
          >
            Study Marketplace
          </label>
          <span>{studyMarketplaceRole ? 'Yes' : 'No'}</span>
          <label htmlFor="title" style={leftColumnStyle}>
            Title
          </label>
          <Field
            name="title"
            component="select"
            style={{ ...rightColumnStyle, height: '28px' }}
          >
            <option />
            {titleOptions.map(option => (
              <option value={option}>{option}</option>
            ))}
          </Field>
          <ErrorMessage name="title" component="div" />

          <label htmlFor="department" style={leftColumnStyle}>
            Department
          </label>
          <Field name="department" type="text" style={rightColumnStyle} />
          <ErrorMessage name="department" component="div" />

          <label htmlFor="startDate" style={leftColumnStyle}>
            Start Date
          </label>
          <Field name="startDate" type="date" value={values.startDate} />
          <ErrorMessage name="startDate" component="div" />

          <label htmlFor="jobTitle" style={leftColumnStyle}>
            Job title
          </label>
          <Field name="jobTitle" type="text" value={values.jobTitle} />
          <ErrorMessage name="jobTitle" component="div" />

          <label htmlFor="credentials" style={leftColumnStyle}>
            Credentials
          </label>
          <Field name="credentials" type="text" value={values.credentials} />
          <ErrorMessage name="credentials" component="div" />

          <label htmlFor="notes" style={leftColumnStyle}>
            Notes
          </label>
          <Field name="notes" type="text" value={values.notes} />
          <ErrorMessage name="notes" component="div" />

          <button type="submit" disabled={values.editEmail}>
            Submit
          </button>
          <button type="reset">Reset Form</button>
        </Form>
      )}
    </Formik>
  );
};

UserInfoForm.defaultProps = {
  jobTitle: '',
  credentials: '',
  notes: '',
  cognitoGroups: [],
  studyMarketplaceRole: false,
  identityProviderUser: {}
};

UserInfoForm.propTypes = {
  putUserRequest: PropTypes.func.isRequired,
  putUserEmailRequest: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  identityProviderUser: PropTypes.object,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cognitoGroups: PropTypes.string,
  title: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  credentials: PropTypes.string,
  notes: PropTypes.string,
  studyMarketplaceRole: PropTypes.bool
};

export default UserInfoForm;
