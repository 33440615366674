import React from 'react';
import { withBouncer } from 'bouncer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bouncerToken from '../fetchers/bouncerToken';

const WithBouncerOrAmplify = ({ component, ...rest }) => {
  const bouncerProps = bouncerToken.getBouncerProps();

  let additionalProps = null;
  if (bouncerProps.domain) {
    additionalProps = { domain: bouncerProps.domain };
  }

  const LoginComponent = withBouncer(
    connect(null)(component),
    bouncerProps.clientId,
    bouncerProps.audience,
    bouncerProps.connection,
    window.location.origin,
    false,
    async getAccessToken => {
      bouncerToken.setGetAuth0AccessToken(getAccessToken);
    },
    null,
    additionalProps
  );
  return <LoginComponent {...rest} />;
};

WithBouncerOrAmplify.propTypes = {
  component: PropTypes.elementType.isRequired
};

WithBouncerOrAmplify.defaultProps = {};

export default WithBouncerOrAmplify;
