import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const fetchUsers = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/users/`, init);
  return fetch(request);
};

// takes in a user Id and hits the endpoint responsible for getting the embedded report from mode
export const fetchUserHistory = async uuid => {
  const headers = new Headers();
  headers.append('Accept', 'application/text');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/loginHistoryReport`,
    init
  );
  return fetch(request);
};

export const fetchUser = async uuid => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/users/${uuid}`, init);
  return fetch(request);
};

export const fetchUserRoleTxns = async uuid => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/roletxns`,
    init
  );
  return fetch(request);
};

export const fetchUserSsus = async uuid => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/ssus`,
    init
  );
  return fetch(request);
};

export const fetchUserRoleTxnsRolledUp = async uuid => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/roletxnsrolledup`,
    init
  );
  return fetch(request);
};

export const putUser = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/users/${uuid}`, init);
  return fetch(request);
};

export const postUser = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/users/add/${uuid}`, init);
  return fetch(request);
};

export const fetchOrphanedUsers = async () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/users/orphans`, init);
  return fetch(request);
};

export const fetchAddableUsers = async () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/users/addables`, init);
  return fetch(request);
};

export const putUserEmail = async (uuid, payload) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'PUT',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/email`,
    init
  );
  return fetch(request);
};

export const putUserEmailFromCognito = async uuid => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'PUT',
    headers
  };

  const request = new Request(
    `${apiPrefix}/admin/api/users/${uuid}/syncemail`,
    init
  );
  return fetch(request);
};

/*
  fetchUserList:
  fetch a paginated list of users with consildated data
*/
export const fetchPaginatedUserList = async action => {
  const { page = 0, limit = 50, sort, descAsc, search = '' } = action;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  let queryString = `?page=${page}&limit=${limit}`;
  if (sort) {
    queryString = `?page=${page}&limit=${limit}&sort=${sort}&descAsc=${descAsc}`;
  }

  if (search) {
    queryString = `${queryString}&search=${search}`;
  }

  const request = new Request(
    `${apiPrefix}/admin/api/userlist/${queryString}`,
    init
  );
  return fetch(request);
};
