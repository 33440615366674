import { fetchSites, createSite, updateSite } from './types/sites';
import { REQUEST, SUCCESS, FAILURE } from './types/utils';

export const fetchSitesRequest = () => ({
  type: fetchSites[REQUEST]
});

export const fetchSitesSuccess = payload => ({
  type: fetchSites[SUCCESS],
  payload
});

export const fetchSitesFailure = err => ({
  type: fetchSites[FAILURE],
  msg: err
});

export const createSiteRequest = payload => ({
  type: createSite[REQUEST],
  payload
});

export const createSiteSuccess = payload => ({
  type: createSite[SUCCESS],
  payload
});

export const createSiteFailure = err => ({
  type: createSite[FAILURE],
  msg: err
});

export const updateSiteRequest = payload => ({
  type: updateSite[REQUEST],
  payload
});

export const updateSiteSuccess = payload => ({
  type: updateSite[SUCCESS],
  payload
});

export const updateSiteFailure = err => ({
  type: updateSite[FAILURE],
  msg: err
});
