import { put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { REQUEST } from '../../actions/types/utils';

// Action Types
import {
  fetchUsersList,
  fetchUser as fetchUserType,
  fetchUserRoleTxns as fetchUserRoleTxnsType,
  fetchUserSsus as fetchUserSsusType,
  fetchUserRoleTxnsRolledUp as fetchUserRoleTxnsRolledUpType,
  fetchOrphanedUsers as fetchOrphanedUsersType,
  fetchAddableUsers as fetchAddableUsersType,
  putUser as putUserType,
  postUser as postUserType,
  putUserEmail as putUserEmailType,
  putUserEmailFromCognito as putUserEmailFromCognitoType
} from '../../actions/types/users';

import {
  fetchSsus as fetchSsusType,
  createSsu as createSsuType,
  updateSsu as updateSsuType
} from '../../actions/types/ssus';

import {
  postExpireRole as postExpireRoleType,
  postExpireAllRoles as postExpireAllRolesType,
  postAddRole as postAddRoleType
} from '../../actions/types/roles';

import {
  fetchApiKeys as fetchApiKeysType,
  postApiKey as postApiKeyType,
  deleteApiKey as deleteApiKeyType
} from '../../actions/types/apiKeys';

import {
  fetchCognitoUsers as fetchCognitoUsersType,
  fetchCognitoUser as fetchCognitoUserType,
  postCognitoUserAddGroup as postCognitoUserAddGroupType,
  postCognitoUserRemoveGroup as postCognitoUserRemoveGroupType,
  postCognitoUser as postCognitoUserType,
  postResetPassword as postResetPasswordType,
  postResendInvitation as postResendInvitationType
} from '../../actions/types/cognitoUsers';

import fetchAddableUserType from '../../actions/types/addables';

import {
  fetchSites as fetchSitesType,
  createSite as createSiteType,
  updateSite as updateSiteType
} from '../../actions/types/sites';

import {
  fetchStudies as fetchStudiesType,
  createStudy as createStudyType,
  updateStudy as updateStudyType
} from '../../actions/types/studies';

// Actions
import {
  fetchUsersListSuccess,
  fetchUserSuccess,
  fetchUserRoleTxnsSuccess,
  fetchUserSsusSuccess,
  fetchUserRoleTxnsRolledUpSuccess,
  fetchOrphanedUsersSuccess,
  fetchAddableUsersSuccess,
  putUserSuccess,
  fetchUserRequest,
  fetchUserRoleTxnsRolledUpRequest,
  fetchUserRoleTxnsRequest,
  postUserSuccess,
  putUserEmailSuccess,
  putUserEmailFailure,
  putUserEmailFromCognitoSuccess,
  putUserEmailFromCognitoFailure
} from '../../actions/users';

import {
  fetchSsusSuccess,
  fetchSsusFailure,
  createSsuSuccess,
  createSsuFailure,
  updateSsuSuccess,
  updateSsuFailure
} from '../../actions/ssus';

import {
  postExpireRoleSuccess,
  postExpireAllRolesSuccess,
  postAddRoleSuccess
} from '../../actions/roles';

import {
  fetchApiKeysSuccess,
  fetchApiKeysRequest,
  postApiKeySuccess,
  deleteApiKeySuccess
} from '../../actions/apiKeys';

import {
  fetchCognitoUsersSuccess,
  fetchCognitoUserSuccess,
  postCognitoUserAddGroupSuccess,
  postCognitoUserRemoveGroupSuccess,
  postCognitoUserSuccess,
  postCognitoUserFailure,
  postResetPasswordSuccess,
  postResetPasswordFailure,
  postResendInvitationSuccess,
  postResendInvitationFailure
} from '../../actions/cognitoUsers';

import { fetchAddableUserSuccess } from '../../actions/addables';

import {
  fetchSitesSuccess,
  fetchSitesFailure,
  createSiteSuccess,
  createSiteFailure,
  updateSiteSuccess,
  updateSiteFailure
} from '../../actions/sites';

import {
  fetchStudiesSuccess,
  fetchStudiesFailure,
  createStudySuccess,
  createStudyFailure,
  updateStudySuccess,
  updateStudyFailure
} from '../../actions/studies';

// Api Fetchers
import {
  fetchUsers,
  fetchUser,
  fetchUserRoleTxns,
  fetchUserSsus,
  fetchUserRoleTxnsRolledUp,
  fetchOrphanedUsers,
  fetchAddableUsers,
  putUser,
  postUser,
  putUserEmail,
  putUserEmailFromCognito
} from '../../fetchers/users';

import { fetchSsus, createSsu, updateSsu } from '../../fetchers/ssus';

import {
  postExpireRole,
  postExpireAllRoles,
  postAddRole
} from '../../fetchers/roles';

import { fetchApiKeys, postApiKey, deleteApiKey } from '../../fetchers/apiKeys';

import {
  fetchCognitoUsers,
  fetchCognitoUser,
  postAddGroup,
  postRemoveGroup,
  postCognitoUser,
  postResetPassword,
  postResendInvitation
} from '../../fetchers/cognitoUsers';

import fetchAddableUser from '../../fetchers/addables';

import { fetchSites, createSite, updateSite } from '../../fetchers/sites';

import { fetchStudies, createStudy, updateStudy } from '../../fetchers/studies';

import usersApiMap from './usersApiMap';

import throwErrorIfFailure from './util';
import bouncerToken from '../../fetchers/bouncerToken';

function* fetchUsersRoutine() {
  try {
    const response = yield call(fetchUsers);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchUsersListSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchUserRoutine(action) {
  try {
    const response = yield call(fetchUser, action.uuid);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchUserSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchUserRoleTxnsRoutine(action) {
  try {
    const response = yield call(fetchUserRoleTxns, action.uuid);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchUserRoleTxnsSuccess(responseJson, action.uuid));
  } catch (err) {
    console.error(err);
  }
}

function* fetchUserSsusRoutine(action) {
  try {
    const response = yield call(fetchUserSsus, action.uuid);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchUserSsusSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchSsusRoutine() {
  try {
    const response = yield call(fetchSsus);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchSsusSuccess(responseJson));
  } catch (err) {
    yield put(fetchSsusFailure(err));
  }
}

function* createSsuRoutine(action) {
  try {
    const response = yield call(createSsu, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(createSsuSuccess(responseJson));
  } catch (err) {
    yield put(createSsuFailure(err));
  }
}

function* updateSsuRoutine(action) {
  try {
    const response = yield call(updateSsu, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(updateSsuSuccess(responseJson));
  } catch (err) {
    yield put(updateSsuFailure(err));
  }
}

function* fetchUserRoleTxnsRolledUpRoutine(action) {
  try {
    const response = yield call(fetchUserRoleTxnsRolledUp, action.uuid);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchUserRoleTxnsRolledUpSuccess(responseJson, action.uuid));
  } catch (err) {
    console.error(err);
  }
}

function* putUserRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  try {
    const response = yield call(putUser, action.uuid, action.payload);
    if (response.status === 204) {
      yield put(putUserSuccess(action.uuid, action.payload));
      toast.success(
        `Successfully updated ${bouncerEnabled ? 'Auth0 User' : 'Cognito User'}`
      );
    }
  } catch (err) {
    console.error(err);
    toast.error(
      `${bouncerEnabled ? 'Auth0 User' : 'Cognito User'} could not be updated`
    );
  }
}

function* postExpireRoleRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  try {
    const response = yield call(postExpireRole, action.uuid, action.payload);
    if (response.status === 204) {
      yield put(postExpireRoleSuccess(action.uuid));
      yield put(fetchUserRequest(action.uuid));
      yield put(fetchUserRoleTxnsRolledUpRequest(action.uuid));
      yield put(fetchUserRoleTxnsRequest(action.uuid));
      toast.success(
        `Successfully updated ${bouncerEnabled ? 'Auth0 User' : 'Cognito User'}`
      );
    } else {
      const responseJson = yield call([response, response.json]);
      const message = (responseJson && responseJson.message) || '';
      toast.error(`User role could not be expired: ${message}`);
      console.error(message);
    }
  } catch (err) {
    toast.error('User role could not be expired');
    console.error(err);
  }
}

function* postExpireAllRolesRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  try {
    const response = yield call(
      postExpireAllRoles,
      action.uuid,
      action.payload
    );
    if (response.status === 204) {
      yield put(postExpireAllRolesSuccess(action.uuid));
      yield put(fetchUserRequest(action.uuid));
      yield put(fetchUserRoleTxnsRolledUpRequest(action.uuid));
      yield put(fetchUserRoleTxnsRequest(action.uuid));
      toast.success(
        `Successfully updated ${bouncerEnabled ? 'Auth0 User' : 'Cognito User'}`
      );
    }
  } catch (err) {
    console.error(err);
    toast.error(
      `${bouncerEnabled ? 'Auth0 User' : 'Cognito User'} could not be updated`
    );
  }
}

function* postAddRoleRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  try {
    const response = yield call(postAddRole, action.uuid, action.payload);
    if (response.status === 204) {
      yield put(postAddRoleSuccess(action.uuid));
      yield put(fetchUserRequest(action.uuid));
      yield put(fetchUserRoleTxnsRolledUpRequest(action.uuid));
      yield put(fetchUserRoleTxnsRequest(action.uuid));
      toast.success(
        `Successfully updated ${bouncerEnabled ? 'Auth0 User' : 'Cognito User'}`
      );
    }
  } catch (err) {
    toast.error(
      `${bouncerEnabled ? 'Auth0 User' : 'Cognito User'} could not be updated`
    );
    console.error(err);
  }
}

function* fetchApiKeysRoutine() {
  try {
    const response = yield call(fetchApiKeys);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchApiKeysSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchOrphanedUsersRoutine() {
  try {
    const response = yield call(fetchOrphanedUsers);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchOrphanedUsersSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchAddableUsersTypeRoutine() {
  try {
    const response = yield call(fetchAddableUsers);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchAddableUsersSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchCognitoUsersRoutine() {
  try {
    const response = yield call(fetchCognitoUsers);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchCognitoUsersSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* fetchCognitoUserRoutine(action) {
  try {
    const response = yield call(fetchCognitoUser, action.userId);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchCognitoUserSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* postCognitoUserAddGroupRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let toastId;
  try {
    toastId = toast.info(
      `Adding ${
        bouncerEnabled ? 'Auth0' : 'Cognito'
      } Group/Study Marketplace role in progress`
    );
    const response = yield call(postAddGroup, action.userId, action.payload);
    yield call(throwErrorIfFailure, response);
    if (response.status === 204) {
      yield put(
        postCognitoUserAddGroupSuccess(action.userId, {
          groupName: action.payload.role
        })
      );
      toast.done(toastId);
      toast.success(
        `Successfully added ${
          bouncerEnabled ? 'Auth0' : 'Cognito'
        } Group/Study Marketplace role to user`
      );
    }
  } catch (err) {
    toast.dismiss(toastId);
    toast.error(`Could not add Study Marketplace Group to user: ${err}`);
    console.error(err);
  }
}

function* postCognitoUserRemoveGroupRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let toastId;
  try {
    toastId = toast.info(
      `Removing ${
        bouncerEnabled ? 'Auth0' : 'Cognito'
      } Group/Study Marketplace role in progress`
    );
    const response = yield call(postRemoveGroup, action.userId, action.payload);
    yield call(throwErrorIfFailure, response);
    if (response.status === 204) {
      yield put(
        postCognitoUserRemoveGroupSuccess(action.userId, {
          groupName: action.payload.role
        })
      );
      toast.done(toastId);
      toast.success(
        `Successfully removed ${
          bouncerEnabled ? 'Auth0' : 'Cognito'
        } Group/Study Marketplace role from user`
      );
    }
  } catch (err) {
    toast.dismiss(toastId);
    toast.error(
      `Could not remove ${
        bouncerEnabled ? 'Auth0' : 'Cognito'
      } Group/Study Marketplace role from user: ${err}`
    );
    console.error(err);
  }
}

function* postApiKeyRoutine(action) {
  try {
    const response = yield call(postApiKey, action.payload);
    if (response.status === 201) {
      yield put(postApiKeySuccess());
      yield put(fetchApiKeysRequest());
    }
  } catch (err) {
    console.error(err);
  }
}

function* deleteApiKeyRoutine(action) {
  try {
    const response = yield call(deleteApiKey, action.apiKeyId);
    if (response.status === 204) {
      yield put(deleteApiKeySuccess(action.apiKeyId));
    }
  } catch (err) {
    console.error(err);
  }
}

function* fetchAddableUserRoutine(action) {
  try {
    const response = yield call(fetchAddableUser, action.userId);
    const responseJson = yield call([response, response.json]);
    yield put(fetchAddableUserSuccess(responseJson));
  } catch (err) {
    console.error(err);
  }
}

function* postUserRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  try {
    const response = yield call(postUser, action.uuid, action.payload);
    if (response.status === 201) {
      yield put(postUserSuccess());
      toast.dismiss();
      toast.success('Successfully created Central User');
      const responseJson = yield call([response, response.json]);
      action.history.push(`users/${responseJson._id}`);
    } else {
      toast.dismiss();
      toast.error(
        `${bouncerEnabled ? 'Auth0' : 'Cognito'} User could not be updated`
      );
    }
  } catch (err) {
    console.error(err);
    toast.error(
      `${bouncerEnabled ? 'Auth0' : 'Cognito'} User could not be updated`
    );
  }
}

function* fetchSitesRoutine() {
  try {
    const response = yield call(fetchSites);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchSitesSuccess(responseJson));
  } catch (err) {
    yield put(fetchSitesFailure(err));
  }
}

function* createSiteRoutine(action) {
  try {
    const response = yield call(createSite, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(createSiteSuccess(responseJson));
  } catch (err) {
    yield put(createSiteFailure(err));
  }
}

function* updateSiteRoutine(action) {
  try {
    const response = yield call(updateSite, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(updateSiteSuccess(responseJson));
  } catch (err) {
    yield put(updateSiteFailure(err));
  }
}

function* fetchStudiesRoutine() {
  try {
    const response = yield call(fetchStudies);
    const responseJson = yield call([response, response.json]);
    yield put(fetchStudiesSuccess(responseJson));
  } catch (err) {
    yield put(fetchStudiesFailure(err));
  }
}

function* createStudyRoutine(action) {
  try {
    const response = yield call(createStudy, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(createStudySuccess(responseJson));
  } catch (err) {
    yield put(createStudyFailure(err));
  }
}

function* updateStudyRoutine(action) {
  try {
    const response = yield call(updateStudy, action.payload);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(updateStudySuccess(responseJson));
  } catch (err) {
    yield put(updateStudyFailure(err));
  }
}

function* postCognitoUserRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let toastId;
  try {
    // Notify user we are going to attempt api call
    // to create Cognito user.
    toastId = toast.info(
      `Attempting to create ${bouncerEnabled ? 'Auth0 User' : 'Cognito User'} `
    );
    const response = yield call(postCognitoUser, action.payload);

    yield call(throwErrorIfFailure, response);

    const responseJson = yield call([response, response.json]);
    yield put(postCognitoUserSuccess(responseJson));

    // Clear previous notification and let user know
    // creating Cognito User was successfull
    toast.dismiss(toastId);
    toast.success(responseJson.message);

    yield call(fetchCognitoUsersRoutine);
  } catch (err) {
    if (toastId) {
      // Clear previous notification
      toast.dismiss(toastId);
    }

    // Attempt to grab error message Obj from json
    let errObj;
    try {
      errObj = JSON.parse(err);
    } catch (jsonErr) {
      console.log('Error parsing err string', jsonErr, err);
    }

    yield put(postCognitoUserFailure(errObj));

    // Notify user of error attempting
    // to add Cognito User
    const errorNotif = `Error adding ${
      bouncerEnabled ? 'Auth0' : 'Cognito'
    } user`;
    if (errObj && errObj.message) {
      toast.error(`${errorNotif}: ${errObj.message}`);
    } else {
      toast.error(errorNotif);
    }
  }
}

function* postResetPasswordRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let toastId;
  try {
    toastId = toast.info(
      `Attempting to reset ${bouncerEnabled ? 'Auth0' : 'Cognito'} password`
    );
    const response = yield call(postResetPassword, action.cognitoId);

    yield call(throwErrorIfFailure, response);

    const responseJson = yield call([response, response.json]);
    yield put(postResetPasswordSuccess(responseJson));

    toast.done(toastId);
    toast.success(
      `Successfully reset ${bouncerEnabled ? 'Auth0' : 'Cognito'} Password`
    );
  } catch (err) {
    if (toastId) {
      toast.dismiss(toastId);
    }

    let errObj;
    try {
      errObj = JSON.parse(err);
    } catch (jsonErr) {
      console.log('Error parsing err string', jsonErr, err);
    }

    yield put(postResetPasswordFailure(err));

    const errorNotif = `Error resetting ${
      bouncerEnabled ? 'Auth0' : 'Cognito'
    } password`;
    if (errObj && errObj.message) {
      toast.error(`${errorNotif}: ${errObj.message}`);
    } else {
      toast.error(errorNotif);
    }
  }
}

function* postResendInvitationRoutine(action) {
  const { bouncerEnabled } = bouncerToken.getBouncerProps();
  let toastId;
  try {
    toastId = toast.info(
      `Attempting to resend ${bouncerEnabled ? 'Auth0' : 'Cognito'} invitation`
    );
    const response = yield call(postResendInvitation, action.cognitoId);

    yield call(throwErrorIfFailure, response);

    const responseJson = yield call([response, response.json]);
    yield put(postResendInvitationSuccess(responseJson));

    toast.done(toastId);
    toast.success(
      `Successfully resent ${bouncerEnabled ? 'Auth0' : 'Cognito'} invitation`
    );
  } catch (err) {
    if (toastId) {
      toast.dismiss(toastId);
    }

    let errObj;
    try {
      errObj = JSON.parse(err);
    } catch (jsonErr) {
      console.log('Error parsing err string', jsonErr, err);
    }

    yield put(postResendInvitationFailure(err));

    const errorNotif = `Error resending ${
      bouncerEnabled ? 'Auth0' : 'Cognito'
    } invitation`;
    if (errObj && errObj.message) {
      toast.error(`${errorNotif}: ${errObj.message}`);
    } else {
      toast.error(errorNotif);
    }
  }
}

function* putUserEmailRoutine(action) {
  let toastId;
  try {
    toastId = toast.info('Attempting to update user email');
    const response = yield call(putUserEmail, action.uuid, action.payload);

    yield call(throwErrorIfFailure, response);

    yield put(putUserEmailSuccess(action.uuid, action.payload));

    toast.done(toastId);
    toast.success('Successfully updated user email');
  } catch (err) {
    if (toastId) {
      toast.dismiss(toastId);
    }

    let errObj;
    yield put(putUserEmailFailure(err));

    const errorNotif = 'Error updating user email';
    if (errObj && errObj.message) {
      toast.error(`${errorNotif}: ${errObj.message}`);
    } else {
      toast.error(errorNotif);
    }
  }
}

function* putUserEmailFromCognitoRoutine(action) {
  let toastId;
  try {
    toastId = toast.info('Attempting to sync email from Cognito');
    const responseSync = yield call(putUserEmailFromCognito, action.uuid);

    yield call(throwErrorIfFailure, responseSync);

    yield put(putUserEmailFromCognitoSuccess(action.uuid));

    const responseFetch = yield call(fetchUser, action.uuid);
    yield call(throwErrorIfFailure, responseFetch);

    const responseJson = yield call([responseFetch, responseFetch.json]);
    yield put(fetchUserSuccess(responseJson));

    toast.done(toastId);
    toast.success('Successfully synced user email');
  } catch (err) {
    if (toastId) {
      toast.dismiss(toastId);
    }

    let errObj;
    yield put(putUserEmailFromCognitoFailure(err));

    const errorNotif = 'Error syncing user email';
    if (errObj && errObj.message) {
      toast.error(`${errorNotif}: ${errObj.message}`);
    } else {
      toast.error(errorNotif);
    }
  }
}

const apiMap = {
  [fetchUsersList[REQUEST]]: fetchUsersRoutine,
  [fetchUserType[REQUEST]]: fetchUserRoutine,
  [fetchUserSsusType[REQUEST]]: fetchUserSsusRoutine,
  [fetchUserRoleTxnsType[REQUEST]]: fetchUserRoleTxnsRoutine,
  [fetchUserRoleTxnsRolledUpType[REQUEST]]: fetchUserRoleTxnsRolledUpRoutine,
  [fetchOrphanedUsersType[REQUEST]]: fetchOrphanedUsersRoutine,
  [fetchAddableUsersType[REQUEST]]: fetchAddableUsersTypeRoutine,
  [fetchAddableUserType[REQUEST]]: fetchAddableUserRoutine,
  [fetchSsusType[REQUEST]]: fetchSsusRoutine,
  [createSsuType[REQUEST]]: createSsuRoutine,
  [updateSsuType[REQUEST]]: updateSsuRoutine,
  [fetchApiKeysType[REQUEST]]: fetchApiKeysRoutine,
  [fetchCognitoUsersType[REQUEST]]: fetchCognitoUsersRoutine,
  [fetchCognitoUserType[REQUEST]]: fetchCognitoUserRoutine,
  [postCognitoUserAddGroupType[REQUEST]]: postCognitoUserAddGroupRoutine,
  [postCognitoUserRemoveGroupType[REQUEST]]: postCognitoUserRemoveGroupRoutine,
  [putUserType[REQUEST]]: putUserRoutine,
  [postExpireRoleType[REQUEST]]: postExpireRoleRoutine,
  [postExpireAllRolesType[REQUEST]]: postExpireAllRolesRoutine,
  [postAddRoleType[REQUEST]]: postAddRoleRoutine,
  [postApiKeyType[REQUEST]]: postApiKeyRoutine,
  [deleteApiKeyType[REQUEST]]: deleteApiKeyRoutine,
  [postUserType[REQUEST]]: postUserRoutine,
  [fetchSitesType[REQUEST]]: fetchSitesRoutine,
  [createSiteType[REQUEST]]: createSiteRoutine,
  [updateSiteType[REQUEST]]: updateSiteRoutine,
  [fetchStudiesType[REQUEST]]: fetchStudiesRoutine,
  [createStudyType[REQUEST]]: createStudyRoutine,
  [updateStudyType[REQUEST]]: updateStudyRoutine,
  [postCognitoUserType[REQUEST]]: postCognitoUserRoutine,
  [postResetPasswordType[REQUEST]]: postResetPasswordRoutine,
  [postResendInvitationType[REQUEST]]: postResendInvitationRoutine,
  [putUserEmailType[REQUEST]]: putUserEmailRoutine,
  [putUserEmailFromCognitoType[REQUEST]]: putUserEmailFromCognitoRoutine,
  ...usersApiMap
};

export default apiMap;
