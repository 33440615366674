import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReactTableDefaults } from 'react-table';
import withIDPUsers from '../containers/withIDPUsers';
import StandardTable from './gizmos/StandardTable';
import IDPUsersColumns from './IDPUsers/IDPUsersColumns';
import SearchBar from './IDPUsers/SearchBar';

const { PaginationComponent } = ReactTableDefaults;

const DESCEND_VALUE = -1;
const ASCEND_VALUE = 1;
const DEFAULT_SORT_COLUMN_ID = 'created_at';
const MIN_CHAR_ERROR = 'Requires a minimum of 3 characters to search.';

// Columns should already be set to sortable=false
// if we don't allow them to be sortable, but this is
// an extra check.
const allowedSortColumns = Object.freeze([
  DEFAULT_SORT_COLUMN_ID,
  'family_name',
  'given_name',
  'email'
]);
const getSortBy = id => {
  let sortBy = DEFAULT_SORT_COLUMN_ID;
  if (allowedSortColumns.includes(id)) {
    sortBy = id;
  }
  return sortBy;
};

const getDescAsc = desc => (desc ? DESCEND_VALUE : ASCEND_VALUE); // -1=desc, 1=desc

const IDPUsers = ({ users, fetchPaginatedUserListRequest }) => {
  const [pages, setPages] = useState(100);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_COLUMN_ID);
  const [descAsc, setDescAsc] = useState(DESCEND_VALUE); // -1=desc, 1=asc
  const [search, setSearch] = useState('');
  const [showMinCharError, setShowMinCharError] = useState(false);

  const onFetchData = () => {
    fetchPaginatedUserListRequest(page, pageSize, sortBy, descAsc, search);
  };

  useEffect(() => {
    onFetchData();
  }, [page, pageSize, sortBy, descAsc, search]);

  const onSearch = newSearch => {
    if (typeof newSearch === 'string') {
      if (newSearch.length > 2 || newSearch.length === 0) {
        setPage(0);
        setSearch(newSearch);
        showMinCharError && setShowMinCharError(false);
      } else {
        !showMinCharError && setShowMinCharError(true);
      }
    }
  };

  const onSortedChange = (newSorted, column) => {
    const newSortById = getSortBy(column.id);
    setSortBy(newSortById);

    // find sorted column to get ascending/descending state
    const sortedColumn = newSorted.find(col => col.id === newSortById);
    if (sortedColumn) {
      const newDescAsc = getDescAsc(sortedColumn.desc);
      setDescAsc(newDescAsc);
    }
    setPage(0);
  };

  return (
    <Fragment>
      <h1>Users</h1>
      <div style={{ textAlign: 'right', paddingBottom: '.25rem' }}>
        <SearchBar
          onSubmit={onSearch}
          placeholder="Minimum 3 Characters"
          showError={showMinCharError}
          error={MIN_CHAR_ERROR}
        />
      </div>
      <StandardTable
        manual
        showPagination
        columns={IDPUsersColumns}
        data={users}
        pages={pages}
        page={page}
        defaultPageSize={10}
        sorted={[
          {
            id: sortBy,
            desc: descAsc === DESCEND_VALUE
          }
        ]}
        PaginationComponent={props => {
          return <PaginationComponent {...props} page={page} />;
        }}
        onPageChange={pageIndex => setPage(pageIndex)}
        onPageSizeChange={size => {
          // Auth0 limits the total number of users you can retrieve to 1000
          const newPages = Math.ceil(1000 / size);
          setPageSize(size);
          setPages(newPages);
          setPage(0);
        }}
        onSortedChange={onSortedChange}
      />
    </Fragment>
  );
};

IDPUsers.propTypes = {
  fetchPaginatedUserListRequest: PropTypes.func.isRequired,
  users: PropTypes.array
};

IDPUsers.defaultProps = {
  users: []
};

export default withIDPUsers(IDPUsers);
