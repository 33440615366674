import * as yup from 'yup';
import { isEmptyArray } from 'formik';
import { isEmpty, isArray } from 'lodash/lang';

function hasDuplicates(groups) {
  const seen = new Set();
  return groups.some(group => {
    const key = group.name + group.type;
    return seen.has(key) || !seen.add(key);
  });
}

function validateAtLeastOneBlindingGroupPopulatedIfBlindedStudy(
  newBlindingGroups
) {
  const { blinded, blindingGroups } = this.parent;

  return (
    !blinded ||
    (!isEmptyArray(blindingGroups) ||
      (newBlindingGroups && !isEmpty(newBlindingGroups.trim())))
  );
}

const studyModalValidator = yup.object().shape({
  studyName: yup.string().required('You must enter a Study Name'),
  type: yup.string(),
  protocolId: yup.string(),
  protocolName: yup.string(),
  protocolNameBlinded: yup.string(),
  pcn: yup.string().required('You must enter a PCN'),
  therapeuticArea: yup.string(),
  indication: yup.string(),
  phase: yup.string(),
  status: yup.string().required('You must select a status'),
  screenFailureRate: yup.number().required('You must enter a failure rate'),
  dropOutRate: yup.number().required('You must enter a drop out rate'),
  customer: yup.string(),
  sponsor: yup.string(),
  cro: yup.string(),
  nonTraditional: yup.bool(),
  newBlindingGroups: yup
    .string()
    .test(
      'Verify at least one blinding group populated',
      'Please add at least one blinding group',
      validateAtLeastOneBlindingGroupPopulatedIfBlindedStudy
    ),
  groupAssigns: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        type: yup.string(),
        isDefault: yup.boolean()
      })
    )
    .test({
      name: 'groupAssigns',
      test: (groupAssigns, ctx) => {
        if (!ctx.parent.isGroupAssign) {
          return true;
        }
        if (!isArray(groupAssigns) || isEmpty(groupAssigns)) {
          return ctx.createError({
            message: 'Please add at least one group'
          });
        }
        if (
          groupAssigns.some(({ name, type }) => isEmpty(name) || isEmpty(type))
        ) {
          return ctx.createError({
            message: 'Please fill name and type for the group'
          });
        }
        if (hasDuplicates(groupAssigns)) {
          return ctx.createError({
            message: 'You cannot save duplicate groups'
          });
        }
        return true;
      }
    })
});

export default studyModalValidator;
