import React from 'react';
import PersonOutline from '@material-ui/icons/PersonOutline';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import { useAuth0 } from 'bouncer';
import elligo from '../../assets/elligo.svg';

const outerStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  height: '3rem'
};
const elligoImgStyle = { width: '9.875rem', verticalAlign: 'middle' };
const rightSideStyle = { justifySelf: 'end', color: 'gray' };
const textStyle = { verticalAlign: 'middle' };
const iconStyle = {
  height: '1.75rem',
  verticalAlign: 'middle',
  margin: '0 .5rem'
};
const dividerStyle = { borderRight: '.0625rem solid', verticalAlign: 'middle' };
const linkStyle = { textDecoration: 'none', color: 'gray' };

const Header = () => {
  const { logout } = useAuth0();
  return (
    <section style={outerStyle}>
      <img src={elligo} alt="Elligo" style={elligoImgStyle} />
      <div style={rightSideStyle}>
        <span style={textStyle}>Elligo-Central</span>
        <PersonOutline style={iconStyle} />
        <span style={dividerStyle} />
        <a
          style={linkStyle}
          href="/"
          onClick={async event => {
            event.preventDefault();
            await logout({
              federated: true,
              returnTo: window.location.origin
            });
          }}
        >
          <PowerSettingsNew style={iconStyle} />
        </a>
      </div>
    </section>
  );
};

export default Header;
