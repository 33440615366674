import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from '../actions/modals';

/** Modal Components */
import CreateSiteModal from '../components/modals/site/CreateSiteModal';
import EditSiteModal from '../components/modals/site/EditSiteModal';
import CreateStudyModal from '../components/modals/study/CreateStudyModal';
import EditStudyModal from '../components/modals/study/EditStudyModal';
import CreateSsuModal from '../components/modals/ssu/CreateSsuModal';
import EditSsuModal from '../components/modals/ssu/EditSsuModal';
import ChangeSsuRoleModal from '../components/modals/user/ChangeSsuRoleModal';

const MODAL_COMPONENTS = {
  CREATE_SITE_MODAL: CreateSiteModal,
  EDIT_SITE_MODAL: EditSiteModal,
  CREATE_STUDY_MODAL: CreateStudyModal,
  EDIT_STUDY_MODAL: EditStudyModal,
  CREATE_SSU_MODAL: CreateSsuModal,
  EDIT_SSU_MODAL: EditSsuModal,
  CHANGE_SSU_ROLE_MODAL: ChangeSsuRoleModal
};

const ModalContainer = props => {
  const { modalType } = props;
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...props} />;
};

const mapStateToProps = state => {
  return {
    modalType: state.modals.modalType,
    data: state.modals.data
  };
};

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal())
});

ModalContainer.defaultProps = {
  modalType: ''
};

ModalContainer.propTypes = {
  modalType: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer);
