import buildNumber from './buildNumber';
import bouncerToken from './fetchers/bouncerToken';

const { bouncerEnabled } = bouncerToken.getBouncerProps();

export { buildNumber };
export const apiHost = '';
export const apiPrefix = ``;

export const mainDark = 'rgba(105, 30, 68, 1)';
export const mainBright = 'rgba(245, 36, 51, 1)';

export const titleOptions = ['Dr.', 'Miss', 'Mr.', 'Mrs.', 'Ms.'];

export const timeZones = Object.freeze([
  {
    id: '+00:00',
    name: 'UTC+0:00 Universal Coordinated Time (UTC)',
    unique: 'UTC+0',
    abbr: 'UTC',
    googleName: 'UTC'
  },
  {
    id: '+01:00',
    name: 'UTC+1:00 European Central Time (ECT)',
    unique: 'ECT+1',
    abbr: 'ECT',
    googleName: 'Europe/Berlin'
  },
  {
    id: '+02:00',
    name: 'UTC+2:00 Eastern European Time (EET)',
    unique: 'EET+2',
    abbr: 'EET',
    googleName: 'Africa/Cairo'
  },
  {
    id: '+03:00',
    name: 'UTC+3:00 Eastern African Time (EAT)',
    unique: 'EAT+3',
    abbr: 'EAT',
    googleName: 'Africa/Djibouti'
  },
  {
    id: '+04:00',
    name: 'UTC+4:00 Near East Time (NET)',
    unique: 'NET+4',
    abbr: 'NET',
    googleName: 'Asia/Dubai'
  },
  {
    id: '+05:00',
    name: 'UTC+5:00 Pakistan Lahore Time (PLT)',
    unique: 'PLT+5',
    abbr: 'PLT',
    googleName: 'Asia/Karachi'
  },
  {
    id: '+06:00',
    name: 'UTC+6:00 Bangladesh Standard Time (BST)',
    unique: 'BST+6',
    abbr: 'BST',
    googleName: 'Asia/Dhaka'
  },
  {
    id: '+07:00',
    name: 'UTC+7:00 Vietnam Standard Time (VST)',
    unique: 'VST+7',
    abbr: 'VST',
    googleName: 'Asia/Ho_Chi_Minh'
  },
  {
    id: '+08:00',
    name: 'UTC+8:00 China Taiwan Time (CTT)',
    unique: 'CTT+8',
    abbr: 'CTT',
    googleName: 'Asia/Hong_Kong'
  },
  {
    id: '+09:00',
    name: 'UTC+9:00 Japan Standard Time (JST)',
    unique: 'JST+9',
    abbr: 'JST',
    googleName: 'Asia/Tokyo'
  },
  {
    id: '+10:00',
    name: 'UTC+10:00 Australia Eastern Time (AET)',
    unique: 'AET+10',
    abbr: 'AET',
    googleName: 'Australia/Melbourne'
  },
  {
    id: '+11:00',
    name: 'UTC+11:00 Solomon Standard Time (SST)',
    unique: 'SST+11',
    abbr: 'SST',
    googleName: 'Pacific/Guadalcanal'
  },
  {
    id: '+12:00',
    name: 'UTC+12:00 New Zealand Standart Time (NST)',
    unique: 'NST+12',
    abbr: 'NST',
    googleName: 'Pacific/Auckland'
  },
  {
    id: '-11:00',
    name: 'UTC-11:00 Midway Islands Time (MIT)',
    unique: 'MIT-11',
    abbr: 'MIT',
    googleName: 'Pacific/Midway'
  },
  {
    id: '-10:00',
    name: 'UTC-10:00 Hawaii Standard Time (HST)',
    unique: 'HST-10',
    abbr: 'HST',
    googleName: 'Pacific/Honolulu'
  },
  {
    id: '-09:00',
    name: 'UTC-9:00 Hawaii Daylight Time (HDT)',
    unique: 'HDT-9',
    abbr: 'HDT',
    googleName: 'Pacific/Honolulu'
  },
  {
    id: '-09:00',
    name: 'UTC-9:00 Alaska Standard Time (AST)',
    unique: 'AST-9',
    abbr: 'AST',
    googleName: 'America/Ancourage'
  },
  {
    id: '-08:00',
    name: 'UTC-8:00 Alaska Daylight Time (ADT)',
    unique: 'ADT-8',
    abbr: 'ADT',
    googleName: 'America/Ancourage'
  },
  {
    id: '-08:00',
    name: 'UTC-8:00 Pacific Standard Time (PST)',
    unique: 'PST-8',
    abbr: 'PST',
    googleName: 'America/Vancouver'
  },
  {
    id: '-07:00',
    name: 'UTC-7:00 Pacific Daylight Time (PDT)',
    unique: 'PDT-7',
    abbr: 'PDT',
    googleName: 'America/Vancouver'
  },
  {
    id: '-07:00',
    name: 'UTC-7:00 Mountain Standard Time (MST)',
    unique: 'MST-7',
    abbr: 'MST',
    googleName: 'America/Denver'
  },
  {
    id: '-06:00',
    name: 'UTC-6:00 Mountain Daylight Time (MDT)',
    unique: 'MDT-6',
    abbr: 'MDT',
    googleName: 'America/Denver'
  },
  {
    id: '-06:00',
    name: 'UTC-6:00 Central Standard Time (CST)',
    unique: 'CST-6',
    abbr: 'CST',
    googleName: 'America/Chicago'
  },
  {
    id: '-05:00',
    name: 'UTC-5:00 Central Daylight Time (CDT)',
    unique: 'CDT-5',
    abbr: 'CDT',
    googleName: 'America/Chicago'
  },
  {
    id: '-05:00',
    name: 'UTC-5:00 Eastern Standard Time (EST)',
    unique: 'EST-5',
    abbr: 'EST',
    googleName: 'America/New_York'
  },
  {
    id: '-04:00',
    name: 'UTC-4:00 Eastern Daylight Time (EDT)',
    unique: 'EDT-4',
    abbr: 'EDT',
    googleName: 'America/New_York'
  },
  {
    id: '-04:00',
    name: 'UTC-4:00 Atlantic Standard Time (AST)',
    unique: 'AST-4',
    abbr: 'AST',
    googleName: 'America/New_York'
  },
  {
    id: '-03:00',
    name: 'UTC-3:00 Atlantic Daylight Time (ADT)',
    unique: 'ADT-3',
    abbr: 'ADT',
    googleName: 'America/New_York'
  },
  {
    id: '-03:00',
    name: 'UTC-3:00 Argentina Standard Time (AGT)',
    unique: 'AGT-3',
    abbr: 'AGT',
    googleName: 'America/Argentina/Buenos_Aires'
  },
  {
    id: '-02:00',
    name: 'UTC-2:00 Coordinated Universal Time-02',
    unique: '-02',
    abbr: '-02',
    googleName: 'Atlantic/South_Georgia'
  },
  {
    id: '-01:00',
    name: 'UTC-1:00 Central African Time (CAT)',
    unique: 'CAT-1',
    abbr: 'CAT',
    googleName: 'Atlantic/Cape_Verde'
  }
]);

export const timeZonesMap = timeZones.reduce((acc, tz) => {
  if (!acc[tz.googleName]) {
    acc[tz.googleName] = [tz];
  } else {
    acc[tz.googleName].push(tz);
  }
  return acc;
}, {});
export const ssuRoles = [
  'cramon',
  'extaud',
  'stuman',
  'pi',
  'subi',
  'sitecoord',
  'sitefincoord',
  'rec',
  'opman',
  'patrepres'
];
export const otherRoles = [
  'sysadm',
  'ellman',
  'fin',
  'finadm',
  'stuadm',
  'stulea',
  'finanalyst',
  'opanalyst',
  'patliaison',
  'cqc'
];
export const disabledRoles = ['stulea', 'stuadm'];
export const globalRoles = [
  'sysadm',
  'ellman',
  'fin',
  'finadm',
  'stuadm',
  'stulea',
  'finanalyst',
  'opanalyst',
  'patliaison',
  'cqc'
];
export const marketplaceRoles = ['marketplace-user', 'marketplace-admin'];
export const roleNames = {
  sysadm: 'System Admin',
  ellman: `${!bouncerEnabled && 'Elligo '}Management`,
  fin: 'Finance User',
  finadm: 'Finance Admin',
  stuadm: 'Study Admin',
  cramon: 'CRA Monitor',
  extaud: 'External Auditor',
  stuman: 'Study Manager',
  pi: 'Investigator',
  subi: 'Sub Investigator',
  sitecoord: 'Site Coordinator',
  sitefincoord: 'Site Financial Coordinator',
  stulea: 'Study Lead',
  rec: 'Recruiter',
  opman: 'Operations Manager',
  finanalyst: 'Finance Analyst',
  opanalyst: 'Operations Analyst',
  patliaison: 'Patient Partnerships Liaison',
  patrepres: 'Patient Partnerships Representative',
  cqc: 'Clinical Quality Coordinator',
  directsource: 'Directsource',
  'marketplace-admin': 'System Admin',
  'marketplace-user': 'User'
};
export const states = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];
export const province = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT'
];
export const statuses = ['Active', 'Inactive'];
export const countries = ['United States', 'Canada'];
export const ssuStatuses = [
  'Startup',
  'Enrolling',
  'Treatment',
  'Closed',
  'Canceled'
];
export const phases = [
  'I',
  'IB',
  'I/II',
  'I/IIA',
  'II',
  'IIA',
  'IIB',
  'II/III',
  'III',
  'IIIB',
  'III/IV',
  'IV/Late Phase',
  'N/A'
];

export const CREATE_SITE_MODAL = 'CREATE_SITE_MODAL';
export const EDIT_SITE_MODAL = 'EDIT_SITE_MODAL';
export const CREATE_STUDY_MODAL = 'CREATE_STUDY_MODAL';
export const EDIT_STUDY_MODAL = 'EDIT_STUDY_MODAL';
export const CREATE_SSU_MODAL = 'CREATE_SSU_MODAL';
export const EDIT_SSU_MODAL = 'EDIT_SSU_MODAL';
export const CHANGE_SSU_ROLE_MODAL = 'CHANGE_SSU_ROLE_MODAL';
export const GROUP_TYPES = ['SM', 'Investigator', 'Other'];

export const DEFAULT_BLINDING_GROUPS = 'Blinded, Unblinded';
export const DEFAULT_GROUPS = [
  { name: 'Study Manager', type: 'SM', isDefault: true },
  { name: 'Investigator', type: 'Investigator', isDefault: true },
  { name: 'Other', type: 'Other', isDefault: true }
];
