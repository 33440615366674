export const leftColumnStyle = {
  gridColumn: '1 / 2',
  justifySelf: 'start',
  alignSelf: 'center'
};

export const rightColumnStyle = {
  gridColumn: '2 / 3',
  alignSelf: 'center'
};

export const readOnlyStyle = {
  ...rightColumnStyle,
  backgroundColor: 'rgba(233, 236, 239, 1)'
};
