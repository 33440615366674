import { fetchOrphanedUsers } from '../actions/types/users';
import { SUCCESS } from '../actions/types/utils';

const orphans = (state = {}, action) => {
  if (action.type === fetchOrphanedUsers[SUCCESS]) {
    return action.payload.reduce((orphMap, orph) => {
      Object.assign(orphMap, { [orph.userId]: orph });
      return orphMap;
    }, {});
  }
  return state;
};

export default orphans;
