import { createSelector } from 'reselect';

const makeApiKeysSelector = () => {
  return createSelector(
    state => state.apiKeys || [],
    apiKeys => ({ apiKeys })
  );
};

export default makeApiKeysSelector;
