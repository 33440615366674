/*
 ManageStudies (`/manage-studies`) container HOC
 */
import React from 'react';
import { connect } from 'react-redux';

import { showModal } from '../actions/modals';
import { fetchStudiesRequest } from '../actions/studies';

import makeManageStudiesSelector from '../selectors/manageStudies';

const withManageStudies = Wrapped => {
  const Wrapper = props => <Wrapped {...props} />;

  const mapDispatchToProps = dispatch => ({
    showModal: (modalType, data) => dispatch(showModal(modalType, data)),
    fetchStudiesRequest: () => dispatch(fetchStudiesRequest())
  });

  return connect(
    makeManageStudiesSelector,
    mapDispatchToProps
  )(Wrapper);
};

export default withManageStudies;
