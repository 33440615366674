import { put, call } from 'redux-saga/effects';
import { REQUEST } from '../../actions/types/utils';
import { fetchPaginatedUserList } from '../../fetchers/users';
import { fetchPaginatedUserList as fetchPaginatedUserListType } from '../../actions/types/users';
import {
  fetchPaginatedUserListFailure,
  fetchPaginatedUserListSuccess
} from '../../actions/users';
import throwErrorIfFailure from './util';

function* fetchPaginatedUserListRoutine(action) {
  try {
    const response = yield call(fetchPaginatedUserList, action);
    yield call(throwErrorIfFailure, response);
    const responseJson = yield call([response, response.json]);
    yield put(fetchPaginatedUserListSuccess(responseJson));
  } catch (err) {
    yield call(fetchPaginatedUserListFailure, err);
  }
}

const usersApiMap = {
  [fetchPaginatedUserListType[REQUEST]]: fetchPaginatedUserListRoutine
};
export default usersApiMap;
