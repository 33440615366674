import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BouncerContext } from 'bouncer';
import bouncerToken from '../fetchers/bouncerToken';
import Admin from '../components/Admin';
import ApiKeys from '../components/ApiKeys';
import User from '../components/User';
import IDPUsers from '../components/IDPUsers';
import UserPool from '../components/UserPool';
import UserAdd from '../components/UserAdd';
import ManageSites from '../components/ManageSites';
import ManageStudies from '../components/ManageStudies';
import ManageSsus from '../components/ManageSsus';
import Header from '../components/gizmos/Header';
import NavBar from '../components/gizmos/NavBar';
import {
  ADMIN,
  USER,
  USERS_PAGINATED,
  USER_POOL,
  USER_ADD,
  API_KEYS,
  SITES,
  STUDIES,
  SSUS
} from '../routes';
/**
 *  this import is needed to configure Cognito, don't delete
 */
import '../cognitoConstants';
import ModalContainer from './ModalContainer';
import 'react-toastify/dist/ReactToastify.css';

const LOGOUT_TIME = 60 * 60 * 1000;
const WARN_TIME = 2 * 60 * 1000; // minutes before logout (0 ms)
const CHECK_RATE = 5 * 1000;
const INACTIVITY_LOGOUT =
  'You will be logged out due to inactivity in 2 minutes';
const LAST_ACTION = 'lastAction';

class App extends Component {
  static contextType = BouncerContext;

  constructor(props) {
    super(props);
    this.fixedTopStyle = {
      position: 'fixed',
      top: '0',
      width: '100%',
      backgroundColor: 'white',
      zIndex:
        '15' /*  Use this to get on top of StandardTable components,
                      which uses `react-table`, which uses the default `react-table.css`
                      which was slightly customized and renamed `StandardTable.css`.  */
    };
    this.spacerStyle = { padding: '2rem', marginTop: '5rem' };
    this.fontFamilyStyle = {
      fontFamily: 'Arial, Helvetica, sans-serif',
      backgroundColor: 'white'
    };

    this.state = {
      timeOutWarned: false
    };

    this.events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.reset = this.reset.bind(this);
    this.checkTimeout = this.checkTimeout.bind(this);
    this.cleanup = this.cleanup.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem(LAST_ACTION)) {
      await this.checkTimeout();
    } else {
      localStorage.setItem(LAST_ACTION, JSON.stringify(Date.now()));
    }

    this.events.forEach(event => {
      window.addEventListener(event, this.reset);
    });

    this.interval = setInterval(async () => {
      await this.checkTimeout();
    }, CHECK_RATE);
  }

  async checkTimeout() {
    const now = Date.now();
    const lastAction = JSON.parse(localStorage.getItem(LAST_ACTION));
    const timeLeft = lastAction + LOGOUT_TIME;
    const diff = timeLeft - now;
    const isWarningPeriod = diff < WARN_TIME;
    const isTimeout = diff < 0;

    if (!isTimeout && isWarningPeriod) {
      this.warn();
    } else if (isTimeout) {
      await this.logout();
    } else if (!lastAction) {
      await this.logout();
    }
  }

  async logout() {
    this.cleanup();
    const { bouncerLogout } = this.context;
    console.log('Logging out due to inactivity');
    localStorage.removeItem(LAST_ACTION);
    sessionStorage.clear();
    await bouncerLogout();
  }

  cleanup() {
    clearInterval(this.interval);
    this.events.forEach(event => {
      window.removeEventListener(event, this.reset);
    });
    localStorage.removeItem(LAST_ACTION);
  }

  reset() {
    localStorage.setItem(LAST_ACTION, JSON.stringify(Date.now()));
    const { timeOutWarned } = this.state;
    if (timeOutWarned) {
      this.setState({
        timeOutWarned: false
      });
    }
  }

  warn() {
    const { timeOutWarned } = this.state;
    if (!timeOutWarned) {
      toast.warn(INACTIVITY_LOGOUT);
      this.setState({
        timeOutWarned: true
      });
    }
  }

  render() {
    const { bouncerEnabled } = bouncerToken.getBouncerProps();
    return (
      <Router>
        <div style={this.fontFamilyStyle}>
          <div style={this.fixedTopStyle}>
            <div style={this.fixedTopStyle}>
              <Header />
              <NavBar />
            </div>
          </div>
          <div style={this.spacerStyle}>
            <Switch>
              <Route exact path={ADMIN} component={Admin} />
              <Route exact path={USER_ADD} component={UserAdd} />
              <Route exact path={USER_POOL} component={UserPool} />
              {bouncerEnabled && (
                <Route exact path={USERS_PAGINATED} component={IDPUsers} />
              )}
              <Route exact path={USER} component={User} />
              <Route exact path={API_KEYS} component={ApiKeys} />
              <Route exact path={SITES} component={ManageSites} />
              <Route exact path={STUDIES} component={ManageStudies} />
              <Route exact path={SSUS} component={ManageSsus} />
              <Route render={() => <Redirect to={ADMIN} />} />
            </Switch>
          </div>
          <ModalContainer />
          <ToastContainer autoClose={8000} />
        </div>
      </Router>
    );
  }
}

export default App;
