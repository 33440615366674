/*
  Admin page `/admin`
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StandardTable from './gizmos/StandardTable';
import ApiKeys from './ApiKeys';
import { mainDark } from '../constants';
import withAdmin from '../containers/withAdmin';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.columnsUsers = [
      {
        Header: 'Last Name',
        accessor: 'lastName'
      },
      {
        Header: 'First Name',
        accessor: 'firstName'
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ original, value }) => (
          <Link to={`/users/${original.userId}`}>{`${value}`}</Link>
        )
      },
      {
        accessor: 'title',
        Header: 'Title'
      },
      {
        Header: 'Department',
        accessor: 'department'
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        width: 110
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => <pre>{JSON.stringify(value, null, 2)}</pre>
      },
      {
        Header: 'ID',
        accessor: 'userId',
        Cell: ({ value }) => <Link to={`/users/${value}`}>{`${value}`}</Link>
      }
    ];

    this.textStyle = { fontSize: '2.5rem', fontWeight: 500, color: mainDark };
  }

  componentDidMount() {
    const { fetchUserListRequest, fetchApiKeysRequest } = this.props;
    fetchUserListRequest();
    fetchApiKeysRequest();
  }

  render() {
    const {
      users,
      apiKeys,
      postApiKeyRequest,
      fetchApiKeysRequest,
      deleteApiKeyRequest
    } = this.props;
    return (
      <section id="users">
        <h1 style={this.textStyle}>User List</h1>
        <StandardTable
          data={users}
          columns={this.columnsUsers}
          defaultPageSize={5}
        />
        <ApiKeys
          apiKeys={apiKeys}
          postApiKeyRequest={postApiKeyRequest}
          fetchApiKeysRequest={fetchApiKeysRequest}
          deleteApiKeyRequest={deleteApiKeyRequest}
        />
      </section>
    );
  }
}

Admin.defaultProps = {
  users: [],
  apiKeys: []
};

Admin.propTypes = {
  users: PropTypes.array,
  apiKeys: PropTypes.array,
  fetchUserListRequest: PropTypes.func.isRequired,
  fetchApiKeysRequest: PropTypes.func.isRequired,
  deleteApiKeyRequest: PropTypes.func.isRequired,
  postApiKeyRequest: PropTypes.func.isRequired
};

export default withAdmin(Admin);
