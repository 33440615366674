/*
  Selector for '/users/add/:uuid' page `UserAdd` component
*/
import { createSelector } from 'reselect';
import {
  getSsuList,
  getAllSites,
  getAllStudies,
  getAllPcns,
  getPcnStudyMap,
  getSiteStudyMap
} from './dataSourcesService';

const ssusSelector = state => state.ssus;
const studiesSelector = state => state.studies;
const sitesSelector = state => state.sites;
const addablesSelector = state => state.addables;

const userAddSelector = (
  ssus = {},
  studies = [],
  sites = [],
  addables = {},
  uuid
) => {
  const addableUser = addables[uuid] || {};
  const ssuList = getSsuList(ssus);
  const pcnStudyMap = getPcnStudyMap(ssus, ssuList, studies);
  const siteStudyMap = getSiteStudyMap(ssuList, ssus);

  return {
    addableUser,
    ssus,
    ssuList,
    allStudiesDataSource: getAllStudies(ssuList, ssus),
    allSitesDataSource: getAllSites(ssuList, ssus),
    allPcnsDataSource: getAllPcns(ssuList, ssus, studies),
    pcnStudyMap,
    siteStudyMap,
    studies,
    sites
  };
};

const makeUserAddSelector = () => {
  return createSelector(
    ssusSelector,
    studiesSelector,
    sitesSelector,
    addablesSelector,
    (state, props) => props.match.params.uuid,
    userAddSelector
  );
};

export default makeUserAddSelector;
