import { apiPrefix } from '../constants';
import getAuthTokenHelper from './getAuthTokenHelper';

export const fetchApiKeys = async () => {
  const headers = new Headers();
  headers.append('Accept', 'application/json');
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'GET',
    headers
  };

  const request = new Request(`${apiPrefix}/admin/api/api-keys`, init);
  return fetch(request);
};

export const postApiKey = async payload => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  };

  const request = new Request(`${apiPrefix}/admin/api/new_api_key`, init);
  return fetch(request);
};

export const deleteApiKey = async apiKeyId => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  const token = await getAuthTokenHelper();
  headers.append('Authorization', `Bearer ${token}`);

  const init = {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ id: apiKeyId })
  };

  const request = new Request(`${apiPrefix}/admin/api/delete_api_key`, init);
  return fetch(request);
};
