import { fetchAddableUsers } from '../actions/types/users';
import fetchAddableUser from '../actions/types/addables';
import { SUCCESS } from '../actions/types/utils';

const addables = (state = {}, action) => {
  switch (action.type) {
    case fetchAddableUsers[SUCCESS]:
      return action.payload.reduce((addMap, add) => {
        Object.assign(addMap, { [add.username]: add });
        return addMap;
      }, {});
    case fetchAddableUser[SUCCESS]:
      return (
        (action.payload.length === 1 && {
          ...state,
          [action.payload[0].username]: action.payload[0]
        }) ||
        state
      );
    default:
      return state;
  }
};

export default addables;
