import React, { Fragment } from 'react';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';
import SingleSelect from '../gizmos/SingleSelect';

const redErrorStyle = { color: 'red' };
const validateRequired = value => !value && 'Required';

/*
  ExpireDateForm expects the inputs `values`, `errors`, and `touched` to each have attributes
  showDateTimeFields, timeZone, timeZoneAbbr, googleTimeZoneName, expireDate, and expireTime
*/
const ExpireDateForm = ({
  values,
  errors,
  touched,
  showDateTimeText,
  timeZoneObj,
  timeZoneDataSource,
  submitText,
  showDateTimeSubmitText
}) => (
  <Form>
    <div>
      <label>
        <Field
          name="showDateTimeFields"
          type="checkbox"
          checked={values.showDateTimeFields}
        />
        {showDateTimeText}
      </label>
    </div>
    {values.showDateTimeFields && (
      <Fragment>
        <div style={{ width: '23rem' }}>
          <Field name="timeZone" validate={validateRequired}>
            {({ form: { setFieldValue } }) => (
              <Fragment>
                <SingleSelect
                  useNewStyle
                  clearSearchOnSelection
                  label="Time Zone"
                  optionValueKey="unique"
                  initialValue={timeZoneObj}
                  disabled={false}
                  placeholder="Select a Time Zone"
                  dataSource={timeZoneDataSource}
                  searchable
                  clearable={false}
                  onChange={tz => {
                    const { id = null, abbr = null, googleName = null } =
                      tz || {};
                    if (values.timeZone !== id) {
                      setFieldValue('timeZone', id);
                      setFieldValue('timeZoneAbbr', abbr);
                      setFieldValue('googleTimeZoneName', googleName);
                    }
                  }}
                />
                {errors.timeZone && touched.timeZone && (
                  <div style={redErrorStyle}>{errors.timeZone}</div>
                )}
              </Fragment>
            )}
          </Field>
        </div>
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <span style={{ display: 'inline-block' }}>
            <Field name="expireDate" type="date" validate={validateRequired} />
            {errors.expireDate && touched.expireDate && (
              <div style={redErrorStyle}>{errors.expireDate}</div>
            )}
          </span>
          <span style={{ display: 'inline-block', marginLeft: '1rem' }}>
            <Field name="expireTime" type="time" validate={validateRequired} />
            {errors.expireTime && touched.expireTime && (
              <div style={redErrorStyle}>{errors.expireTime}</div>
            )}
          </span>
        </div>
      </Fragment>
    )}
    <span style={{ display: 'inline-block' }}>
      <button type="submit">
        {(values.showDateTimeFields && showDateTimeSubmitText) || submitText}
      </button>
    </span>
  </Form>
);

ExpireDateForm.defaultProps = {
  values: {},
  errors: {},
  touched: {},
  showDateTimeText: '',
  timeZoneObj: {},
  timeZoneDataSource: [],
  submitText: '',
  showDateTimeSubmitText: ''
};

ExpireDateForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  showDateTimeText: PropTypes.string,
  timeZoneObj: PropTypes.object,
  timeZoneDataSource: PropTypes.array,
  submitText: PropTypes.string,
  showDateTimeSubmitText: PropTypes.string
};

export default ExpireDateForm;
