import { ssuRoles } from '../../constants';

export function ssuFilter(ssus) {
  return Object.keys(ssus).filter(s => ssus[s].status !== 'Canceled');
}

export function findSsu(ssuFiltered, selectedSiteId, selectedStudyId) {
  return ssuFilter(ssuFiltered).find(
    id =>
      ssuFiltered[id].studyId === selectedStudyId &&
      ssuFiltered[id].siteId === selectedSiteId
  );
}

export function saveNotAllowed(values, ssus) {
  const { role, selectedSiteId, selectedStudyId, blindingGroup } = values;
  const isSsuRole = ssuRoles.includes(role);
  const roleSsu = findSsu(ssus, selectedSiteId, selectedStudyId);
  const ssuIsSelected = selectedSiteId && selectedStudyId;
  return (
    (isSsuRole && !ssuIsSelected) ||
    (isSsuRole &&
      ssuIsSelected &&
      ssus[roleSsu].studyBlinded &&
      !ssus[roleSsu].blindingGroups.includes(blindingGroup)) ||
    (isSsuRole &&
      ssuIsSelected &&
      ssus[roleSsu].isGroupAssign &&
      values.groupAssignId === '')
  );
}

export function getBlindingGroupValue(values, ssus) {
  const isSsuRole = ssuRoles.includes(values.role);
  const roleSsu = findSsu(ssus, values.selectedSiteId, values.selectedStudyId);
  const ssuIsSelected = roleSsu && ssus[roleSsu];
  return isSsuRole &&
    ssuIsSelected &&
    ssus[roleSsu].studyBlinded &&
    ssus[roleSsu].blindingGroups.includes(values.blindingGroup)
    ? values.blindingGroup
    : null;
}
