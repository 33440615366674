import { createSelector } from 'reselect';

const cognitoUsersSelector = (cognitoUsers = {}) => {
  return {
    cognitoUsers: Object.values(cognitoUsers)
  };
};

const makeCognitoUsersSelector = () => {
  return createSelector(
    state => state.cognitoUsers,
    cognitoUsersSelector
  );
};

export default makeCognitoUsersSelector;
