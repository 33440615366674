import { createSelector } from 'reselect';

const makeManageStudiesSelector = () => {
  return createSelector(
    state => state.studies || [],
    studies => {
      return {
        studies
      };
    }
  );
};

export default makeManageStudiesSelector;
