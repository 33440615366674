import React from 'react';
import ReactSuperSelect from 'react-super-select';
import '../../SingleSelect.scss';
import cx from 'classnames';

// setting new datasource value that does not contain current value will clear current selected value
// setting datasource to [] will clear current selected value.
// this behavior prevents incorrect state or dropdown
// this behavior is casual for appointment edit dialogs
// to keep data consistence and selected patient, study, site, encounter relationship.
/* eslint-disable react/prop-types */

function SingleSelect({
  placeholder,
  dataSource,
  optionLabelKey,
  optionValueKey,
  onChange,
  searchable,
  initialValue,
  disabled,
  customClass,
  customOptionTemplateFunction,
  customGroupHeadingClass,
  customLoaderClass,
  customTagClass,
  clearSearchOnSelection,
  clearSelectedValueOnDataSourceChange,
  groupBy,
  clearable,
  multiple,
  closeOnSelectedOptionClick,
  deselectOnSelectedOptionClick,
  customSelectedValueTemplateFunction,
  customFilterFunction,
  validationMessage,
  id,
  label,
  useNewStyle,
  hasMorePages,
  pageDataFetch,
  onSearchInputChange,
  forceDefaultBrowserScrolling,
  ...rest
}) {
  return (
    <div
      className={cx(customClass, 'single-select-container', {
        'new-style': useNewStyle
      })}
    >
      {useNewStyle && label && <label htmlFor={id}>{label}</label>}
      <ReactSuperSelect
        hasMorePages={hasMorePages}
        pageDataFetch={pageDataFetch}
        controlId={id}
        disabled={disabled}
        placeholder={placeholder}
        dataSource={dataSource}
        optionLabelKey={optionLabelKey}
        optionValueKey={optionValueKey}
        forceDefaultBrowserScrolling={forceDefaultBrowserScrolling}
        onSearchInputChange={onSearchInputChange}
        onChange={onChange}
        searchable={searchable}
        initialValue={initialValue}
        customOptionTemplateFunction={customOptionTemplateFunction}
        customGroupHeadingClass={customGroupHeadingClass}
        customLoaderClass={customLoaderClass}
        customTagClass={customTagClass}
        clearSearchOnSelection={clearSearchOnSelection}
        customFilterFunction={customFilterFunction}
        clearSelectedValueOnDataSourceChange={
          clearSelectedValueOnDataSourceChange
        }
        groupBy={groupBy}
        clearable={clearable}
        multiple={multiple}
        closeOnSelectedOptionClick={closeOnSelectedOptionClick}
        deselectOnSelectedOptionClick={deselectOnSelectedOptionClick}
        customSelectedValueTemplateFunction={
          customSelectedValueTemplateFunction
        }
        {...rest}
      />
      <span className="text-danger">{validationMessage}</span>
    </div>
  );
}

export default SingleSelect;

/* eslint-enable react/prop-types */
